import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Pagination extends React.PureComponent {
    getPageTotal = () => {
        const { total, limit } = this.props;
        return Math.ceil(total / limit);
    };

    getItems = () => {
        const { page } = this.props;
        const items = [];
        const itemsRange = this.getItemsRange();
        for (let i = itemsRange.start; i <= itemsRange.end; i++) {
            items.push(
                <li key={i} onClick={this.handlePageChange(i)} className={cx({ active: page === i })}>
                    {i}
                </li>
            );
        }
        return items;
    };

    getItemsRange = () => {
        const { page, itemsRangeDisplay } = this.props;
        const pageTotal = this.getPageTotal();
        const range = itemsRangeDisplay - 1; // minus active page
        const leftRange = Math.ceil(range / 2);
        const rightRange = range - leftRange;

        let startPage;
        let endPage;
        if (page <= leftRange) {
            startPage = 1;
            endPage = itemsRangeDisplay;
        } else if (page + rightRange > pageTotal) {
            startPage = pageTotal - range;
            endPage = pageTotal;
        } else {
            startPage = page - leftRange;
            endPage = page + rightRange;
        }
        return {
            start: startPage < 1 ? 1 : startPage,
            end: endPage > pageTotal ? pageTotal : endPage
        };
    };

    getPrevPage = () => {
        const { page } = this.props;

        return (
            <li className={cx({ disabled: page === 1 })} onClick={this.handlePageChange(page - 1)}>
                &lt;
            </li>
        );
    };

    getNextPage = () => {
        const { page } = this.props;

        return (
            <li className={cx({ disabled: page === this.getPageTotal() })} onClick={this.handlePageChange(page + 1)}>
                &gt;
            </li>
        );
    };

    getFirstPage = () => {
        const { page } = this.props;

        return (
            <li className={cx({ disabled: page === 1 })} onClick={this.handlePageChange(1)}>
                &lt;&lt;
            </li>
        );
    };

    getLastPage = () => {
        const { page } = this.props;
        const pageTotal = this.getPageTotal();

        return (
            <li className={cx({ disabled: page === pageTotal })} onClick={this.handlePageChange(pageTotal)}>
                &gt;&gt;
            </li>
        );
    };

    getPagination = () => {
        const { total } = this.props;
        if (!total || this.getPageTotal() < 2) return null;

        return (
            <div className="pagination-pages">
                <ul className="pagination-controls">
                    {this.getFirstPage()}
                    {this.getPrevPage()}
                </ul>
                <ul className="pagination-items">{this.getItems()}</ul>
                <ul className="pagination-controls">
                    {this.getNextPage()}
                    {this.getLastPage()}
                </ul>
            </div>
        );
    };

    getElementsCount = () => {
        const { page, limit, total, translatedText } = this.props;

        const from = (page - 1) * limit + 1;
        const to = page * limit > total ? total : page * limit;
        const itemsRange = from > 0 && to > 1 ? `${from} - ${to}` : `${from}`;
        const text = `${itemsRange} ( ${translatedText.of}`;

        return (
            <div className="pagination-elements-count">
                {text}&nbsp;<span className="total-count">{total}</span>&nbsp;)
            </div>
        );
    };

    handlePageChange = newPage => e => {
        const { onChange, page } = this.props;
        if (newPage === page) return;
        if (this.props.onChange) return onChange({ page: newPage });

        console.error('Nothing happened. Need to set onChange prop to Pagination');
        // defaultHandle below
        e.preventDefault();
    };

    render() {
        const { containerClassName } = this.props;

        return (
            <div className={cx('pagination-container', { [containerClassName]: Boolean(containerClassName) })}>
                <div className="pagination-pages-wrapper">{this.getPagination()}</div>
                {this.getElementsCount()}
            </div>
        );
    }
}

Pagination.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    containerClassName: PropTypes.string,
    itemsRangeDisplay: PropTypes.number,
    translatedText: PropTypes.object
};
Pagination.defaultProps = {
    page: 1,
    limit: 30,
    total: 0,
    containerClassName: '',
    itemsRangeDisplay: 5,
    translatedText: { of: 'of' }
};

export default Pagination;
