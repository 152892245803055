import { DOMAIN_REGEX, PASSWORD_REGEX } from "../../config/constants";
import { mergeStaticFormBlocks } from "../../util/form";

export const getLoginPasswordForm = config => {
    const { t, staticBlock, allBlocks } = config;

    const loginAttr = {
        key: "login",
        title: t('login'),
        widgetType: "input",
        customWidth: "full-width",
        // rule: `loginErrorWrong$/^[a-zA-Z][a-zA-Z0-9\\-\\. ]{0,61}[a-zA-Z]\\\\w[\\w\\.\\- ]+$/`,
        
        // make a curry function to intercept with provided rule from static config
        // values will be putted by dynamic for validate function
        // staticRule will be putted by static block via mergeStaticFormBlocks
        // curriedRule(values, staticRule) {
        //     let message = 'loginErrorWrong';
        //     let regex = DOMAIN_REGEX;
        //     if (staticRule) {
        //         // handle regex validation
        //         let rest;
        //         [ message, ...rest ] = staticRule.split('$');
        //         const regexp = rest.join("");
        //         regex = new RegExp(`^${regexp}$`);
        //     }
        //     if (values.login) {
        //         const slashLogin = values.login.includes("\\");
        //         const splitted = slashLogin ? values.login.split("\\") : values.login.split("@");
        //         // is extra splitter provided
        //         if (splitted.length > 2) {
        //             return message;
        //         }
        //         let domain, user;
        //         if (slashLogin) {
        //             [domain, user] = splitted;
        //         } else {
        //             [user, domain] = splitted;
        //         }
        //         if (!user || !domain) {
        //             return message;
        //         }
        //         if (!regex.test(domain)) {
        //             return message;
        //         }
        //     } else {
        //         return message;
        //     }
        //     return "";
        // },
    };

    const passwordsAttr = [
        {
            key: "password",
            title: t('password'),
            widgetType: "input",
            inputType: "password",
            // rule: `passwordErrorRegex$${PASSWORD_REGEX}`, // rule => error_message $ regex
            rule(values) {
                if (!PASSWORD_REGEX.test(values.password)) {
                    return "passwordErrorRegex";
                }
                return "";
            },
            hasPasswordViewPermission: true,
        },
    ];

    const widgets = [loginAttr, ...passwordsAttr];
    // map to static blocks
    const mergedWidgets = mergeStaticFormBlocks({ t, widgets, staticBlock, allBlocks });

    const dynData = [
        {
            view: "1column",
            collapsed: false,
            title: t("accountCredentials"),
            key: "accountCredentials",
            widgets: mergedWidgets,
        },
    ];

    const initialValues = {};

    return { dynData, initialValues };
};
