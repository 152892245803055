import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

const FormsWrapper = props => {
    const { isAppealFormOpened, checkedItems, toggleAppealForm, form } = props;
    const buttonCls = cx({
        toggleSlideForm: true,
        'open-form': isAppealFormOpened,
        'hidden-toggle-button': !checkedItems.length
    });

    return (
        <div>
            <div className={buttonCls} onClick={toggleAppealForm}>
                <i className="icon-arrow-alt-left" />
            </div>

            <CSSTransition
                in={isAppealFormOpened && !!checkedItems.length}
                classNames="pageSliderRightForm"
                appear={true}
                enter={true}
                exit={true}
                timeout={1000}
                // timeout={{ enter: 100, exit: 600 }}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <div className="appealFormWrapper">
                    <div className={buttonCls} onClick={toggleAppealForm}>
                        <i className="icon-arrow-alt-right" />
                    </div>
                    {form}
                </div>
            </CSSTransition>
        </div>
    );
};

FormsWrapper.propTypes = {
    form: PropTypes.node,
    isAppealFormOpened: PropTypes.bool,
    checkedItems: PropTypes.array,
    toggleAppealForm: PropTypes.func
};

export default FormsWrapper;
