const euSettings = {
    language: "uk",
    encoding: "utf-8",
    httpProxyServiceURL: "../dsz/ProxyHandler",
    directAccess: false,
    CAs: "data/lib/CAs.json",
    CACertificates: "data/lib/CACertificates.p7b",
    allowedKeyMediaTypes: [
        "е.ключ ІІТ Алмаз-1К",
        "е.ключ ІІТ Кристал-1",
        "ID-карта громадянина (БЕН)",
        "е.ключ ІІТ Алмаз-1К (PKCS#11)",
        "е.ключ ІІТ Кристал-1 (PKCS#11)"
    ]
};

let euSignFile, euSignKeyMedia, euSign = null;
let keyMedias = [];

//===============================================================================
// Ініціалізація бібліотеки
function initialize() {
    return new Promise(function(resolve, reject) {
        if (euSign == euSignFile) {
            euSign.IsInitialized()
                .then(function(result) {
                    if (result) {
                        console.log("EndUser: already initialized");
                        resolve();
                        return;
                    }

                    console.log("EndUser: initializing...");
                    return euSign.Initialize(euSettings)
                }).then(function() {
                console.log("EndUser: initialized");
                resolve()
            })
                .catch(function(e) {
                    reject(e);
                });
        } else {
            // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
            euSign.GetLibraryInfo()
                .then(function(result) {
                    if (!result.supported) {
                        throw "Бібліотека web-підпису не підтримується " +
                        "в вашому браузері або ОС";
                    }

                    if (!result.loaded) {
                        // Бібліотека встановлена, але потребує оновлення
                        if (result.isNativeLibraryNeedUpdate) {
                            throw "Бібліотека web-підпису потребує оновлення. " +
                            "Будь ласка, встановіть оновлення за посиланням " +
                            result.nativeLibraryInstallURL;
                        }

                        // Якщо браузер підтримує web-розширення рекомендується
                        // додатково до нативних модулів встановлювати web-розширення
                        // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
                        if (result.isWebExtensionSupported &&
                            !result.isWebExtensionInstalled) {
                            throw "Бібліотека web-підпису потребує встановлення web-розширення. " +
                            "Будь ласка, встановіть web-розширення за посиланням " +
                            result.webExtensionInstallURL + " та оновіть сторінку";
                        }

                        // Бібліотека (нативні модулі) не встановлені
                        throw "Бібліотека web-підпису потребує встановлення. " +
                        "Будь ласка, встановіть бібліотеку за посиланням " +
                        result.nativeLibraryInstallURL + " та оновіть сторінку";
                    }

                    return euSign.IsInitialized();
                })
                .then(function(result) {
                    if (result) {
                        console.log("EndUser: already initialized");
                        resolve();
                        return;
                    }

                    console.log("EndUser: initializing...");
                    return euSign.Initialize(euSettings)
                }).then(function() {
                console.log("EndUser: initialized");
                resolve()
            })
                .catch(function(e) {
                    reject(e);
                });
        }
    });
}

//===============================================================================
function setKeyMedias(_keyMedias) {
    keyMedias = _keyMedias;

    const kmSelect = document.getElementById('pkKeyMediaSelect');

    const length = kmSelect.options.length;
    for (i = length-1; i >= 0; i--) {
        kmSelect.options[i] = null;
    }

    for (const i = 0; i < keyMedias.length; i++) {
        const opt = document.createElement('option');
        opt.appendChild( document.createTextNode(keyMedias[i].visibleName) );
        opt.value = keyMedias[i].visibleName;
        kmSelect.appendChild(opt);
    }
}

//===============================================================================
function getSelectedKeyMedia() {
    const kmSelected = document.getElementById('pkKeyMediaSelect').value;

    for (const i = 0; i < keyMedias.length; i++) {
        if (keyMedias[i].visibleName == kmSelected)
            return keyMedias[i];
    }

    return null;
}

//===============================================================================
function readFile(file) {
    return new Promise(function(resolve, reject) {
        const reader = new FileReader();
        reader.onloadend  = function(evt) {
            if (evt.target.readyState != FileReader.DONE)
                return;

            resolve({
                "file": file,
                "data": new Uint8Array(evt.target.result)
            });
        };
        reader.readAsArrayBuffer(file);
    });
}

//===============================================================================
function readPrivateKey({ file, pass, cas }) {
    const useFile = euSign == euSignFile;

    const pkFileInput = useFile ? file : null;
    const passwordInput = pass;
    const selectedKM = useFile ? null : getSelectedKeyMedia();
    const kmSelect = document.getElementById('pkKeyMediaSelect');
    /*
        Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
        Якщо null бібліотека намагається визначити ЦСК автоматично за
        сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не
        підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
    */
    const caCN = cas;
    /*
        Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array).
        Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
        Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення
        пошуку сертифіката ос. ключа
    */
    const pkCertificates = null;

    return new Promise(function(resolve, reject) {
        if (useFile && (
            pkFileInput.value == null ||
            pkFileInput.value == '')) {
            pkFileInput.focus();

            reject('Не обрано файл з ос. ключем');

            return;
        }

        if (!useFile && !selectedKM) {
            kmSelect.focus();

            reject('Не обрано носій з ос. ключем');

            return;
        }

        if (passwordInput.value == null ||
            passwordInput.value == '') {
            passwordInput.focus();
            reject('Не вказано пароль до ос. ключа');

            return;
        }

        if (euSign == euSignFile) {
            readFile(pkFileInput.files[0])
                .then(function(result) {
                    console.log("Private key file readed");

                    // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
                    // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися
                    if (result.file.name.endsWith(".jks")) {
                        return euSign.GetJKSPrivateKeys(result.data)
                            .then(function(jksKeys){
                                console.log("EndUser: jks keys got");

                                // Для спрощення прикладу обирається перший ключ
                                return euSign.ReadPrivateKeyBinary(
                                    jksKeys[0].privateKey,
                                    passwordInput.value, pkCertificates, caCN);
                            });
                    }

                    return euSign.ReadPrivateKeyBinary(
                        result.data, passwordInput.value, pkCertificates, caCN);
                })
                .then(function(result) {
                    resolve(result)
                })
                .catch(function(e) {
                    reject(e);
                });
        } else {
            const keyMedia = new EndUserKeyMedia(selectedKM);
            keyMedia.password = passwordInput.value;

            euSign.ReadPrivateKey(keyMedia, pkCertificates, caCN)
                .then(function(result) {
                    console.log("Provate key here");
                    resolve(result)
                })
                .catch(function(e) {
                    reject(e);
                });
        }
    });
}

//===============================================================================
//============================  EXTERNAL FUNCTIONS  =============================
//===============================================================================

export const getEcpLib = () => {
    // Бібліотека для роботи з файловими ключами, що не потребує
    // встановлення додатково ПЗ
    euSignFile = new EndUser(
        null,
        EndUserConstants.EndUserLibraryType.JS);

    // Бібліотека для роботи з аппаратними носіями, що потребує
    // встановлення додатково ПЗ бібліотек web-підпису, web-розширення для браузера
    euSignKeyMedia = new EndUser(
        null,
        EndUserConstants.EndUserLibraryType.SW);

    euSign = euSignFile;

    initialize()
        .then(function() {
            if (euSign == euSignFile)
                return [];

            return euSign.GetKeyMedias();
        })
        .then(function(keyMedias) {
            // setKeyMedias(keyMedias);  // need to returnafter will get ability second initialize SW
            console.log("set key medias")
            // signBlock.style.display = 'block';
            // pkInfoBlock.style.display = 'block';
        })
        .catch(function(e) {
            const msg = (e.message || e);

            console.log("Initialize error: " + msg);

            alert('Виникла помилка при ініціалізації бібліотеки. ' +
                'Опис помилки: ' + msg);
        })
};

export const getPkInfo = data => {
    readPrivateKey(data)
        .then(function(result) {
            data.callBack && data.callBack(result);
        })
        .catch(function(e) {
            data.callBack && data.callBack({});
            const msg = (e.message || e);
            console.log("Sign data error: " + msg);
            alert('Виникла помилка при отриманні даних. ' +
                'Опис помилки: ' + msg);
        });
};

export const signData = data => {
    const { inputValue, inputFunk, callBack } = data;
    let value = inputValue;
    let resp = null;

    readPrivateKey(data)
        .then(function(result) {
            value = inputFunk ? inputFunk(result) : inputValue;
            resp = result;
            return euSign.SignDataInternal(true, value, true);
        })
        .then(function(sign) {
            console.log("EndUser: data signed");
            console.log("Data: " + value);
            console.log("Sign: " + sign);

            callBack && callBack({ sign, data: resp });
        })
        .catch(function(e) {
            callBack && callBack({});
            const msg = (e.message || e);
            console.log("Sign data error: " + msg);
            alert('Виникла помилка при підписі даних. ' +
                'Опис помилки: ' + msg);
        });
};
