import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumericInput from 'react-numeric-input';

import FormElementWrapper from './FormElementWrapper';

const NumberInput = props => {
    const { label, input, meta, liveSaver, tooltip, onChange, ...restProps } = props;

    const [blurAction, setBlurAction] = useState(true);

    const handleChange = val => {
        if (input && input.onChange) input.onChange(val);
        if (onChange) onChange(val);
    };

    const handleBlur = e => {};
    const handleKeyUp = e => {};

    return (
        <FormElementWrapper
            tooltip={tooltip}
            meta={meta}
            label={label}
            liveSaver={liveSaver}
            blurAction={blurAction}
            required={restProps.required}
        >
            <div className="input-field-wrap">
                <NumericInput
                    className="input-field"
                    {...input}
                    {...restProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                />
            </div>
        </FormElementWrapper>
    );
};

NumberInput.displayName = 'Input';

NumberInput.propTypes = {
    tooltip: PropTypes.string,
    required: PropTypes.bool,
    liveSaver: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func
};

NumberInput.defaultProps = {
    required: false
};

export default NumberInput;
