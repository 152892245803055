import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// import { scrollIntoViewIfNeeded } from '../helpers';
// import LocalAsync from './LocalSelect/LocalAsync';
import FormElementWrapper from '../FormElementWrapper';
import MenuRenderer from './MenuRenderer';
// import ComboBox from "../ComboBox";

import 'rc-tree/assets/index.css';

const SelectTree = props => {
    const { input } = props;
    // const [selectedOption, setSelectedOption] = useState(input.value);

    const styles = {
        clearIndicator: () => ({}),
        menu: () => ({}),
        menuList: () => ({}),
        container: () => ({}),
        control: () => ({}),
        option: () => ({}),
        singleValue: () => ({}),
        placeholder: () => ({})
    };

    const onChange = ops => {
        // Only for single value yet
        const selected = ops ? { label: ops.name, value: ops.id } : null;
        // setSelectedOption(selected);

        const { input, isMulti, hasExValue } = props;

        if (input && input.onChange) {
            let result = selected;
            if (!hasExValue) {
                const { valueKey } = props;
                // result = selected && (isMulti ? selected.map(option => option.value) : selected.value);
            }
            return input.onChange(result);
        }
    };

    const onClose = data => {
        // debugger;
    };

    const onBlur = e => e.preventDefault();

    const treeProps = {
        ...props,
        ...input,
        components: {
            MenuList: data => <MenuRenderer {...data} {...props} value={input.value} />
        }
    };

    return (
        <FormElementWrapper {...props}>
            <Select
                {...treeProps}
                styles={styles}
                value={input.value}
                onChange={onChange}
                onBlur={onBlur}
                onMenuOpen={onClose}
            />
        </FormElementWrapper>
    );
};

SelectTree.displayName = 'SelectTree';

SelectTree.propTypes = {
    hasExValue: PropTypes.bool,
    isAsync: PropTypes.bool,
    isCreatable: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    hasOnWheel: PropTypes.bool,
    options: PropTypes.array,
    getNode: PropTypes.func,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string
};

SelectTree.defaultProps = {
    hasExValue: false,
    isAsync: false,
    isCreatable: false,
    label: '',
    required: false,
    hasOnWheel: true,
    options: [],
    getNode: null,
    labelKey: 'label',
    valueKey: 'value'
};

export default SelectTree;
