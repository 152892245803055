import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import FormElementWrapper from './FormElementWrapper';

const inputIcon = {
    text: 'icon-eye-slash',
    password: 'icon-eye'
};

const Input = props => {
    const {
        hasPasswordViewPermission,
        clearable,
        type,
        value,
        defaultValue,
        label,
        input,
        meta,
        liveSaver,
        tooltip,
        onChange,
        onBlur,
        plain,
        onPressEnter,
        ...restProps
    } = props;

    const [cursorPosition, setCursorPosition] = useState(null);
    const [hasToggleIcon] = useState(type === 'password' && hasPasswordViewPermission);
    const [inputType, setInputType] = useState(type || 'text');
    const [inputValue, setInputValue] = useState(value || defaultValue || (input && input.value) || '');
    const [isEditable, setIsEditable] = useState(!plain); // if plain is not provided => !play === true, so by default isEditable
    const [blurAction, setBlurAction] = useState(true);
    const inputRef = useRef(null);

    const handleIconsEnter = () => setBlurAction(false);
    const handleIconsLeave = () => setBlurAction(true);

    const getInputIcons = () => {
        if (!inputValue) return null;
        const icons = [getResetIcon(), getPasswordToggle()].filter(item => !!item);
        if (!icons.length) return null;
        return (
            <div
                className={`input-field-icons icons-count-${icons.length}`}
                onMouseEnter={handleIconsEnter}
                onMouseLeave={handleIconsLeave}
            >
                {icons.map(item => item)}
            </div>
        );
    };

    const getPasswordToggle = () => {
        const icon = (
            <i key="icon-password" onClick={toggleInputType} className={`input-field-icon ${inputIcon[inputType]}`} />
        );

        return hasToggleIcon ? icon : null;
    };

    const getResetIcon = () => {
        const clear = <i key="icon-clear" onClick={clearValue} className="icon-close" />;
        return clearable && !plain && !restProps.disabled ? clear : null;
    };

    const toggleInputType = ev => {
        ev.preventDefault();
        setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const clearValue = () => {
        setBlurAction(true);
        setInputValue('');
        setCursorPosition(0);
        if (input && input.onBlur) input.onBlur('');
        inputRef && inputRef.current && inputRef.current.focus();
        if (onChange) onChange('');
    };

    const handleChange = ev => {
        const { value: val } = ev.target;
        setInputValue(val);
        setCursorPosition(inputRef.current.selectionStart);
        if (input && input.onChange) input.onChange(val);
        if (onChange) onChange(val);
    };

    const toggleEditing = () => plain && !restProps.disabled && setIsEditable(!isEditable);

    const handleBlur = () => {
        if (onBlur) onBlur(inputValue);
        if (input && input.onBlur) input.onBlur(inputValue);
        setCursorPosition(null);
        toggleEditing();
    };

    const handleKeyUp = e => {
        if (e.keyCode === 13) {
            onPressEnter && onPressEnter();
        }
    };

    useEffect(() => {
        isEditable && plain && inputRef.current.focus();
    }, [isEditable]);

    // used for value update from LiveSaver cancel
    useEffect(() => {
        if (input && inputValue !== input.value) {
            setInputValue(input.value);
        }
    }, [input]);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            if (cursorPosition !== null) {
                inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    });

    return (
        <FormElementWrapper
            tooltip={tooltip}
            meta={meta}
            label={label}
            liveSaver={liveSaver}
            blurAction={blurAction}
            required={restProps.required}
        >
            <div className={`input-field-wrap ${(plain && 'plain') || ''}`}>
                {isEditable && getInputIcons()}
                {isEditable ? (
                    <input
                        className="input-field"
                        {...input}
                        {...restProps}
                        value={inputValue}
                        ref={inputRef}
                        type={inputType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyUp={handleKeyUp}
                    />
                ) : (
                    <div
                        className={`input-plain-value ${restProps.disabled ? 'disabled' : ''}`}
                        title={restProps.title}
                        onClick={toggleEditing}
                    >
                        {inputValue ? (
                            <div className="value">{inputValue}</div>
                        ) : (
                            <div className="value-placeholder">{restProps.placeholder}</div>
                        )}
                        {!restProps.disabled && <i key="icon-edit" className="input-field-icon icon-edit" />}
                    </div>
                )}
            </div>
        </FormElementWrapper>
    );
};

Input.displayName = 'Input';

Input.propTypes = {
    hasPasswordViewPermission: PropTypes.bool,
    type: PropTypes.string,
    clearable: PropTypes.bool,
    required: PropTypes.bool,
    liveSaver: PropTypes.object,
    input: PropTypes.object,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    plain: PropTypes.bool
};

Input.defaultProps = {
    hasPasswordViewPermission: false,
    clearable: false,
    required: false
};

export default Input;
