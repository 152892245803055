import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import get from 'lodash/get';
import cx from 'classnames';
import ContactInput from './ContactInput';
import InputLabel from './InputLabel';
import ClickOutsideHolder from 'components/ClickOutsideHolder';

import { isEmpty } from 'lodash';

import {
    validateEmail,
    arrayUniqueValues,
    PHONE_NUMBER_UNIQUE,
    PHONE_NUMBER,
    EMAIL,
    EMAIL_UNIQU,
} from 'util/form';

import './styles.scss';

function validateArray(value, errorCondition, errorText) {
    if (!value) return undefined;
    const errors = value.reduce((acc, fieldValue, index) => {
        if (errorCondition(fieldValue)) {
            acc[index] = errorText || i18next.t('validation.contactFormat');
        }
        return acc;
    }, {});
    return isEmpty(errors) ? undefined : errors;
}

function emailValidator(value) {
    if (!Array.isArray(value)) return undefined;
    const values = value.map((contact) => contact.value);

    const unique = arrayUniqueValues(values, EMAIL_UNIQU());
    const errorCondition = (fieldValue) => fieldValue && !validateEmail(fieldValue);
    const email = validateArray(values, errorCondition, EMAIL());

    if (email || unique) {
        return { ...email, ...unique };
    }
}

export function phoneValidator(value) {
    if (!Array.isArray(value)) return undefined;
    const values = value.map((contact) => contact.value);

    const unique = arrayUniqueValues(values, PHONE_NUMBER_UNIQUE());
    const errorCondition = (fieldValue) => fieldValue && !/^[0-9]{3,12}$/.test(fieldValue);
    const number = validateArray(values, errorCondition, PHONE_NUMBER());

    if (number || unique) {
        return { ...number, ...unique };
    }
}

const fieldLevelValidation = {
    homePhone: phoneValidator,
    email: emailValidator,
};

export function isValid(value, name, rule) {
    if (rule) {
        const rules = rule.split('$');
        const message = rules[0];
        const regexp = rules[1];
        const regex = new RegExp(`^${regexp}$`);
        function validatorRule(value) {
            if (!value) return;
            if (!regex.test(value)) {
                return message;
            }
        }

        fieldLevelValidation[name] = validatorRule;
    }

    const validator = fieldLevelValidation[name];

    if (!validator) {
        return true;
    }

    return validator(value) === undefined;
}

class ContactArrayComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onRemoveField = this.onRemoveField.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.renderInputs = this.renderInputs.bind(this);
        this.onSubmitFinish = this.onSubmitFinish.bind(this);
        this.parseFieldName = this.constructor.parseFieldName;
    }

    static parseFieldName(fieldName) {
        const position = fieldName.indexOf('[');

        return {
            index: Number.parseInt(fieldName.slice(position + 1, -1)),
            name: fieldName.slice(0, position),
        };
    }

    onSubmitFinish() {
        this.setState({ isLoading: false });
    }

    onRemoveField(fieldValue, fieldName, fieldOnly) {
        const { index, name } = this.parseFieldName(fieldName);

        if (fieldOnly) {
            this.props.fields.remove(index);
            return;
        }

        const values = this.props.fields.getAll().filter((element, i) => i !== index);
        const options = { action: 'delete', index, fieldValue };
        this.setState({ isLoading: true });
        this.props.handleChangeValue({ value: values, name, options }, this.onSubmitFinish);
    }

    handleChangeValue(fieldValue, fieldName) {
        const { index, name } = this.parseFieldName(fieldName);
        let values = this.props.fields.getAll().slice();
        values[index] = fieldValue;

        const options = { action: 'save', index, fieldValue };
        this.setState({ isLoading: true });

        this.props.handleChangeValue({ value: values, name, options }, this.onSubmitFinish);
    }

    onAddClick() {
        const { single, fields } = this.props;
        if (single) return;

        // const firstInputValue = get(fields.get(fields.length - 1), 'value');
        const allFields = fields.getAll();
        const lastField = allFields[fields.length - 1];
        if (lastField.id === 0) return;

        return () => {
            // fields.unshift({ id: 0, value: '', type: fields.name, added: true });
            fields.push({ id: 0, value: '', type: fields.name, added: true });
        };
    }

    onRemoveLastField = () => {
        this.props.fields.pop();
    };

    handleFocus = (input) => {
        const inputId = get(input, 'value.id');
        if (inputId !== 0) {
            this.checkForLastFieldRemove();
        }
    };

    checkForLastFieldRemove = () => {
        const fields = this.props.fields.getAll();
        const lastField = fields[fields.length - 1];
        if (
            lastField.id === 0 &&
            [null, undefined, ''].includes(lastField.value) &&
            fields.length !== 1
        ) {
            this.onRemoveLastField();
        }
    };

    onClickOutside = () => {
        setTimeout(() => {
            this.checkForLastFieldRemove();
        }, 0);
    };

    renderInputs(name) {
        const {
            single,
            readOnly,
            fields,
            field,
            required,
            // isReq,
            showNotification,
            disabled,
            isSaveOnlyValid,
        } = this.props;
        const { isLoading } = this.state;

        const maxLengthEmail =
            field === 'contacts.email' || field === 'email' ? { maxLength: '255' } : null;

        return (
            <Field
                key={name}
                name={name}
                disabled={readOnly}
                required={required}
                component={ContactInput}
                handleChangeValue={this.handleChangeValue}
                onRemove={this.onRemoveField}
                handleFocus={this.handleFocus}
                single={single}
                fields={fields}
                showNotification={showNotification}
                disabled={isLoading || disabled}
                isSaveOnlyValid={isSaveOnlyValid}
                {...maxLengthEmail}
            />
        );
    }

    render() {
        const { label, fields, disabled, required } = this.props;
        const { isLoading } = this.state;
        const contentClassName = cx('content', {
            // errorBorder: !isInputFocused && !isValid(fields.getAll(), fields.name),
            // focused: isInputFocused,
        });

        return (
            <ClickOutsideHolder onClickOutside={this.onClickOutside}>
                <div className="fieldArrayContainer">
                    <InputLabel
                        readOnly={this.props.readOnly || disabled}
                        required={required}
                        label={label}
                        onAddClick={this.onAddClick()}
                        isLoading={isLoading}
                    />
                    {fields.length > 0 && (
                        <div className={contentClassName}>{fields.map(this.renderInputs)}</div>
                    )}
                </div>
            </ClickOutsideHolder>
        );
    }
}

ContactArrayComponent.propTypes = {
    handleChangeValue: PropTypes.func,
    single: PropTypes.bool,
};

export default ContactArrayComponent;
