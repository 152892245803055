import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ASC, UP, DOWN } from './gridConfig';
import { getWidthStyles } from './helpers';

export default class GridHeadItem extends React.PureComponent {
    handleHeadClick = () => {
        const { field, item, onSort } = this.props;
        onSort(field, item);
    };

    handleResizerClick = () => {
        const { field, onMaximaze } = this.props;
        onMaximaze(field);
    };

    render = () => {
        const {
            t,
            field,
            lastField,
            item: { name, hidden, classWidth, notDraggable, width },
            sort,
            dropHighlight,
            onDragStart,
            onDragEnter,
            onDragOver,
            onDragEnd,
            onStartResize,
            resizing,
            forceWidth,
            fixedWidth
        } = this.props;

        if (hidden) return null;

        const isSorted = sort.field === field;
        // temparary solution
        const sortIconClass = sort.order === ASC ? UP : DOWN;
        const mainClass = field !== lastField && classWidth ? classWidth : '';
        const thClassName = cx('head-cell', `grid-cell-${mainClass}`, {
            'droppable-cell-hover': dropHighlight,
            resizing
        });
        const forcedWidthStyle = getWidthStyles(forceWidth || fixedWidth || width);

        return (
            <th
                title={t(name)}
                data-name={field}
                className={thClassName}
                draggable={!resizing && !notDraggable}
                onDragStart={onDragStart}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                style={forcedWidthStyle}
            >
                <div className="head-cell-content" onClick={this.handleHeadClick}>
                    <div className="head-cell-text">{t(name)}</div>
                    {isSorted &&
                    sort.order && (
                        <div className="grid-sort-icon-wrapper">
                            <i className={`icon-${sortIconClass}`} />
                        </div>
                    )}
                </div>
                <div
                    draggable={false}
                    className="head-cell-resizer"
                    onMouseDown={onStartResize}
                    onDoubleClick={this.handleResizerClick}
                />
            </th>
        );
    };
}

GridHeadItem.propTypes = {
    t: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    lastField: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    sort: PropTypes.object,
    dropHighlight: PropTypes.bool,
    onDragStart: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragEnd: PropTypes.func,
    widthClassName: PropTypes.string,
    onStartResize: PropTypes.func.isRequired,
    forceWidth: PropTypes.number,
    fixedWidth: PropTypes.number,
    resizing: PropTypes.bool,
    onSort: PropTypes.func.isRequired,
    onMaximaze: PropTypes.func,
    hidden: PropTypes.bool
};
