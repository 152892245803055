import React, { Component } from 'react';
import { ModalWindow } from 'ui-core-dashboard';
import MessageModal from '../MessageModal';
// import MessageModal from "../";
// import MessageModal from '../MessageModal'; // hooks

import File from './File';

import './styles.scss';

/*
    FileInput logic:

    Initialize, select and upload:
    1. Get already uploaded files from props and set them in state => state.uploadedFiles
    2. On select and upload files => set selectedFiles state and wait for upload response => state.selectedFiles
    3. Once selected file was uploaded on server, move it into uploaded files array

    Delete:
    1. On delete, set loading state in format { fileId: true} to show loader until deletion finished
    2. If delete response succeeded, remove deleted file by id from state.uploadedFiles

    *****
    Responses:
    1. Each handleUpload / handleDelete function also sends callback function (ex: handleUploadResponse, handleDeleteResponse)
    Examples of handling requests:
     1.1: handleFileDelete(fileId, callBack) => after finish, callback is called with fileId to remove file from state
     1.2: handleFileUpload(file, callback) => after finich, callback is called with file and result id


    FileInput props:
    1. maxFileSize => file size validation
    2. extensions => by default accept all, could changed to file extensions (ex: ['jpg', 'mp3', 'jpeg'])
    3. multiple => multiple file upload
    4. handleUpload => function than handle upload request
    5. handleDelete => function that handle delete request
    6. loader => custom loader
    7. files => array of already uploaded (saved/default) files (ex: [{value: 1.jpg, key: 1}])
    8. path => path to file for downlooad purpose (ex: '../mw/file?fileId=')

*/

class FileInput extends Component {
    state = {
        // uploadedFiles: this.props.files || [], // array of uploaded files to a server (contains file name and id on server)
        uploadedFiles: this.props.files || [],
        selectedFiles: [], // array of selected files of acceptable size and which are being loaded to a server
        confirmModal: { open: false, fileId: null }, // modal to confirm deletion
        loading: {}, // handle loading indicators during file deletion
        errors: [], // showing errors on each file which exceed max file size
    };

    componentDidUpdate(prevProps, prevState) {
        const { errors, uploadedFiles, selectedFiles } = this.state;
        const { errorTimeout, input } = this.props;
        // setTimeout for clearing errors
        if (errors.length > 0 && prevState.errors !== errors) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setState({ errors: [] }), errorTimeout || 6000);
        }

        // update redux form field values in format ("fileId|fileId|fileId")
        // selectedFiles.length added to trach if all selected files are uploaded and run save once all loaded, 
        // also disable button once previous selected files not loaded
        if (prevState.uploadedFiles !== uploadedFiles && selectedFiles.length === 0) {
            // input.onChange(uploadedFiles.map(file => file.key).join("|")); // old logic, multiple files are concatenated via |
            input.onChange(uploadedFiles.map((file) => file.key)); // new logic, return array
        }
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    getFileSizeMb = (file) => file.size / 1024 / 1024;

    getFileExtension = (file) => file.name.split('.').pop();

    getSizeErrors = (files) => {
        const { maxFileSize, translate } = this.props;

        const size = translate.size || 'File size';
        const maxSize = translate.maxSize || 'Maximum file size';
        return files
            .filter((file) => this.getFileSizeMb(file) >= maxFileSize)
            .map((file) => {
                const fileSize = Math.floor(this.getFileSizeMb(file) * 100) / 100;
                return {
                    fileName: file.name,
                    message: `${size} ${fileSize}MB (${maxSize} ${maxFileSize}MB)`,
                };
            });
    };

    getExtensionErrors = (files) => {
        const { extensions, translate } = this.props;
        // get accept as an array from props string (ex: "jpg , jpeg, mp3" => [jpg, jpeg, mp3] etc);
        // const accept = this.getAcceptExtensions(this.props.accept);
        const ext = translate.ext || 'File extension';
        const extAllowed = translate.extAllowed || 'Allowed file extensions';
        if (extensions.length > 0) {
            return files
                .filter((file) => !extensions.includes(this.getFileExtension(file)))
                .map((file) => {
                    return {
                        fileName: file.name,
                        message: `${ext}: ${this.getFileExtension(
                            file,
                        )} (${extAllowed}: ${extensions.join(', ')})`,
                    };
                });
        }
        return [];
    };

    getValidatedFiles = (files) => {
        const { maxFileSize, extensions } = this.props;
        return files.filter((file) => {
            if (extensions.length > 0)
                return (
                    this.getFileSizeMb(file) <= maxFileSize &&
                    extensions.includes(this.getFileExtension(file))
                );
            return this.getFileSizeMb(file) <= maxFileSize;
        });
    };

    handleChange = (event) => {
        if (this.timeout) clearTimeout(this.timeout); // clear timeout
        this.setState({ errors: [] }); // remove errors on change;
        const { handleUpload } = this.props;
        const { selectedFiles, errors } = this.state;

        const files = [...event.currentTarget.files];

        // console.log({ files });

        const errorsSize = this.getSizeErrors(files);
        const errorsExtension = this.getExtensionErrors(files);
        const validatedFiles = this.getValidatedFiles(files);

        this.setState({
            selectedFiles: [...selectedFiles, ...validatedFiles],
            errors: [...errors, ...errorsSize, ...errorsExtension],
        });

        const inputKey = this.props.input && this.props.input.name;

        validatedFiles.map((file) => handleUpload(file, this.handleUploadResponse, inputKey));

        event.currentTarget.value = null; // clear input value (path) for same file upload purpose
    };

    handleDeleteConfirm = (fileId) => {
        this.setState({ confirmModal: { ...this.state.confirmModal, open: true, fileId } });
    };

    handleDelete = () => {
        const {
            confirmModal: { fileId },
            loading,
        } = this.state;
        this.setState({
            confirmModal: { open: false, fileId: null },
            loading: { ...loading, [fileId]: true },
        });
        const fieldKey = this.props.input && this.props.input.name;
        this.props.handleDelete(fileId, this.handleDeleteResponse, fieldKey);
    };

    cancelDelete = () => this.setState({ confirmModal: { open: false, fileId: null } });

    handleUploadResponse = (file, id, error) => {
        const { uploadedFiles, selectedFiles } = this.state;
        if (error) {
            // remove file from selectedFiles
            console.log(error); // handle response error in another way later
            return this.setState({
                selectedFiles: selectedFiles.filter((f) => f.lastModified !== file.lastModified),
            });
        }

        // add uploaded file to uploadedFiles state array
        // remove uploaded file from selectedFiles state array (currently by lastModified param)
        this.setState({
            uploadedFiles: [...uploadedFiles, { value: file.name, key: id }],
            selectedFiles: selectedFiles.filter((f) => f.lastModified !== file.lastModified),
        });
    };

    handleDeleteResponse = (id, error) => {
        if (error) {
            // remove loader indicator on delete fail
            console.log(error); // handle response error in another way later
            return this.setState({ loading: { ...loading, [id]: false } });
        }

        // input.onChange(uploadedFiles.filter(file => file.key !== id));
        const { uploadedFiles } = this.state;
        this.setState({ uploadedFiles: uploadedFiles.filter((file) => file.key !== id) });
    };

    renderUploadedFile = (data) => {
        const { value: name, key: id } = data;
        const { loader, path, disabled } = this.props;
        const { loading } = this.state;
        return (
            <File
                name={name}
                id={id}
                loader={loader}
                loading={loading[id]}
                path={path}
                disabled={disabled}
                handleDeleteConfirm={this.handleDeleteConfirm}
            />
        );
    };

    renderSelectedFile = (data) => {
        const { name } = data;
        const { loader } = this.props;
        return (
            <File
                name={name}
                loader={loader}
                disabled // selected file always disabled (no possible to delete it)
                loading // selected file always have loading state, until it loaded and moved into uploaded files
            />
        );
    };

    renderError = (error) => {
        const { fileName, message } = error;
        return (
            <div className="error-wrapper">
                <div className="file-name" title={fileName}>
                    {fileName}
                </div>
                <div className="error-message">{message}</div>
            </div>
        );
    };

    render() {
        const {
            multiple,
            extensions,
            label,
            input: { key, name },
            disabled,
            required,
            translate,
            meta: { error },
        } = this.props;
        const { uploadedFiles, selectedFiles, confirmModal, errors } = this.state;

        return (
            <div className="input-element file-input-wrapper">
                <div className="input-label">
                    {label}
                    {required && <span className="required-field">*</span>}
                </div>
                <div className="files">
                    {uploadedFiles.length > 0 && uploadedFiles.map(this.renderUploadedFile)}
                    {selectedFiles.length > 0 && selectedFiles.map(this.renderSelectedFile)}
                </div>
                <label>
                    {!disabled && <><input
                        key={key}
                        name={name}
                        type="file"
                        multiple={multiple}
                        accept={[...extensions] || '*'}
                        onChange={this.handleChange}
                        disabled={selectedFiles.length > 0 || disabled}
                    />
                    <div className={selectedFiles.length > 0 || disabled ? 'btn-save disabled' : 'btn-save'}>
                        {translate.button}
                    </div></>}
                </label>

                {errors.length > 0 && <div className="errors">{errors.map(this.renderError)}</div>}
                {/* {error && <div className='error'>{error}</div>} */}

                {confirmModal.open && (
                    <ModalWindow
                        onClose={this.cancelDelete}
                        className="modal-small ordering-component-ui-core-wrapper"
                    >
                        <MessageModal
                            titleModal={translate.attention || 'Attention!'}
                            contentModalText={
                                translate.confirmText ||
                                'Are you sure you want to delete this file?'
                            }
                            dangerButton
                            dangerButtonText={translate.yes || 'Yes'}
                            onClickDangerButton={this.handleDelete}
                            secondaryButton
                            secondaryButtonText={translate.no || 'No'}
                            onClickSecondaryButton={this.cancelDelete}
                        />
                    </ModalWindow>
                )}
            </div>
        );
    }
}

export default FileInput;
