import React from 'react';
import PropTypes from 'prop-types';

export default function Loader({ withContainer, loaderClass }) {
    const LoaderContent = (
        <div className="overlay">
            <svg className={`loading ${loaderClass}`} viewBox="0 0 50 50">
                <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="3.6" />
            </svg>
        </div>
    );
    return withContainer ? <div className="loaderContainer">{LoaderContent}</div> : LoaderContent;
}

Loader.defaultProps = {
    withContainer: false,
    loaderClass: ''
};

Loader.propTypes = {
    withContainer: PropTypes.bool,
    loaderClass: PropTypes.string
};
