const localKeys = {
    noItems: 'noItems',
    btnHeaderAdd: 'Add',
    btnHeaderEdit: 'Edit',
    btnHeaderDownload: 'Download',
    btnHeaderUpload: 'Upload',
    searchPlaceholder: 'Search',
    searchResult: 'searchResult',
    filterFieldsPlaceholder: 'Select',
    btnFooterCancel: 'Cancel',
    btnFooterSelect: 'Select',
    export: 'Export',
    csv: 'CSV',
    excel: 'Excel',
    totalResults: 'totalResults',
    paginationOf: 'of',
    paginationTotal: 'Total'
};

export const getTKey = (tKeys, key) => (tKeys && tKeys[key]) || localKeys[key];
