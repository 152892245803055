import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { deepFreeze, formatBytes } from '../helpers';

// hot value formatting
export const format = Object.freeze({
    // string: (_, value) => String(value),
    // number: (_, value) => parseInt(value, 10),
    // translate: (_, value) => <T>{t => t(value)}</T>,
    date: dateFormat => (_, value) => (value ? moment(value/1000, 'X').format(dateFormat) : '')
});

export default deepFreeze({
    searchAppeal: {
        grid: {
            fields: {
                regnum: {
                    name: 'Номер',
                    link: item => {
                        const { id } = item;
                        if (!id) return '/';
                        return `#/appeals/${id}`;
                    }
                },
                'status.name': {
                    name: 'status',
                    // value: data => data.status.name,
                    value: data => {
                        // console.log({data});
                        if (!data.status || !data.status.name) {
                            return "-";
                        }
                        return data.status && data.status.name;
                    }
                },
                typeFullName: {
                    name: 'type'
                },
                subject: {
                    name: 'theme'
                },
                'priority.name': {
                    name: 'priority',
                    value: data => {
                        // console.log({data});
                        if (!data.priority || !data.priority.name) {
                            return "-";
                        }
                        return data.priority && data.priority.name;
                    }
                },
                startDate: {
                    name: 'created',
                    value: format.date('DD.MM.YYYY HH:mm')
                }
            },
            version: 1
        }
    },
    myService: {
        grid: {
            fields: {
                label: {
                    name: 'serviceName',
                    // classWidth: '0'
                },
                stateName: {
                    name: 'state',
                    // classWidth: '1'
                },
                dateLabel: {
                    name: 'createDate',
                    // value: format.date('DD.MM.YYYY HH:mm'),
                    // classWidth: '0'
                },
                actions: {
                    // width: 20
                }
            },
            version: 1
        }
    },
});
