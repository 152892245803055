import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import { isObject, isArray, isEqual } from 'lodash';

// import { scrollIntoViewIfNeeded } from '../helpers';
// import LocalAsync from './LocalSelect/LocalAsync';
// import Option from './Option';
import FormElementWrapper from '../FormElementWrapper';
import MenuRenderer from './MenuRenderer';

let waitOut = null;

const ComboBox = props => {
    const { input, isTree, customLabel, dictionary_id, meta, ...rest } = props;
    const { labelKey, valueKey, isMulti, options, className, plain, asyncParams, fetchOptions, defaultOptions } = props;

    const [asyncVals, setAsyncVals] = useState(asyncParams);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defOptions, setDefOptions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [touched, setTouched] = useState(false);

    let elTree = null;

    const findTree = (arr, val, key) => {
        if (isArray(arr)) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][key] === val) {
                    elTree = arr[i];
                    break;
                } else {
                    findTree(arr[i].children, val, key);
                }
            }
        }
    };

    const getOption = val => {
        if (!val) return null;
        const { label, value } = val;
        const opt = label && value ? val : { label: val[labelKey], value: val[valueKey] };
        return opt.value && opt.label ? opt : findOption(val) || null;
    };

    const findOption = val => {
        const res = !isObject(val)
            ? isTree
                ? findTree(options, val, valueKey)
                : options.find(v => v[valueKey] === val)
            : null;
        return getOption(res || elTree);
    };

    const setOption = val => {
        const selected = !val || val === '' ? null : (isMulti ? val.map(getOption) : getOption(val));
        setSelectedOption(selected);
        return selected;
    };

    const loadOptions = query => {
        if (!defaultOptions) clearTimeout(waitOut);
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            waitOut = setTimeout(() => {
                const res = fetchOptions({ asyncParams, query, dictionary_id, labelKey, valueKey, customLabel });
                setIsLoading(false);
                resolve(res);
            }, 400);
        });
    };

    const styles = {
        clearIndicator: () => ({}),
        menu: () => ({}),
        // menuList: () => ({}),
        container: () => ({}),
        control: () => ({}),
        option: () => ({})
        // valueContainer: () => ({}),
        // singleValue: () => ({}),
        // placeholder: () => ({})
    };

    const onChange = ops => {
        const selected = setOption(ops);
        return input && input.onChange && input.onChange(selected);
    };

    const onMenuClose = () => {
        // debugger;
    };

    const onFocus = () => {
        if (!defOptions && !defaultOptions && !!asyncParams) {
            loadOptions().then(resp => setDefOptions(resp));
        }
        input.onFocus && input.onFocus();
    };

    const onBlur = e => {
        setTouched(true);
        e.preventDefault();
    };

    const treeProps = {
        MenuList: data => <MenuRenderer {...data} {...props} value={input.value} />
    };

    const Comp = asyncParams ? Async : Select;

    const comboProps = {
        ...rest,
        ...input,
        touched,
        components: isTree ? treeProps : {},
        className: `${className} ${plain ? 'plain' : ''} ${isTree ? 'tree' : ''}`,
        styles,
        value: selectedOption,
        onChange,
        onBlur,
        onMenuClose,
        onFocus,
        isLoading,
        loadOptions: asyncParams ? loadOptions : () => {},
        defaultOptions: defOptions || defaultOptions
    };

    useEffect(() => {
        setOption(input.value);
    }, [input.value]);

    useEffect(() => {
        if (!isEqual(asyncParams, asyncVals)) {
            setAsyncVals(asyncParams);
            setDefOptions(null);
        }
    }, [asyncParams]);

    return (
        <FormElementWrapper {...props}>
            <Comp {...comboProps} />
        </FormElementWrapper>
    );
};

ComboBox.displayName = 'ComboBox';

ComboBox.propTypes = {
    input: PropTypes.object,
    isAsync: PropTypes.bool,
    isCreatable: PropTypes.bool,
    hasExValue: PropTypes.bool,
    isTree: PropTypes.bool,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    hasOnWheel: PropTypes.bool,
    options: PropTypes.array,
    getNode: PropTypes.func,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    className: PropTypes.string,
    plain: PropTypes.bool,
    asyncParams: PropTypes.object,
    fetchOptions: PropTypes.func,
    defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    customLabel: PropTypes.func,
    dictionary_id: PropTypes.number
};

ComboBox.defaultProps = {
    hasExValue: false,
    isAsync: false,
    isCreatable: false,
    isTree: false,
    isMulti: false,
    label: '',
    required: false,
    hasOnWheel: true,
    options: [],
    getNode: null,
    labelKey: 'label',
    valueKey: 'value'
};

export default ComboBox;
