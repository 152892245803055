import React from 'react';
import { Portal } from 'react-overlays';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ErrorBlock extends React.Component {
    constructor() {
        super();

        this.state = {
            isErrorPopupOpen: false,
            left: null,
            top: null,
        };

        this.errorInput = React.createRef();
    }

    openErrorPopup = () => {
        this.setPosition();
        this.setState({ isErrorPopupOpen: true });
        // setTimeout(() => this.setState({ isErrorPopupOpen: true }), 300);
    };

    closeErrorPopup = () => {
        this.setState({ isErrorPopupOpen: false });
        // setTimeout(() => this.setState({ isErrorPopupOpen: false }), 300);
    };

    setPosition = () => {
        const rect = this.errorInput.current.getBoundingClientRect();
        const left = window.scrollX + rect.left + rect.width;
        const top = window.scrollY + rect.top;

        if (top !== this.state.top || left !== this.state.left) {
            this.setState({ left, top });
        }
    };

    render() {
        const { isErrorPopupOpen, left, top } = this.state;
        const { className } = this.props;

        const stylePopup = { left, top };
        const popupPortal = document.getElementById('popup-portal');

        return (
            <div className={cx('input-error', 'errorBlock', className)} ref={this.errorInput}>
                {this.props.error}
                {/* <span
                    className="icon icon-error"
                    onMouseEnter={this.openErrorPopup}
                    onMouseLeave={this.closeErrorPopup}
                />
                {isErrorPopupOpen && (
                    <Portal container={popupPortal}>
                        <div className="error-popup" style={stylePopup}>
                            {this.props.error}
                        </div>
                    </Portal>
                )} */}
            </div>
        );
    }
}

ErrorBlock.propTypes = {
    error: PropTypes.string,
};

export default ErrorBlock;
