import React from 'react';
import PropTypes from 'prop-types';

import GridHead from './GridHead';
import GridBody from './GridBody';

import { MINIMUM_RESIZE_CELL_WIDTH } from './gridConfig';
import ResizeObserver from './ResizeObserver';

export default class GridTable extends React.Component {
    state = {
        // Resize columns:
        resizedColumnName: null,
        resizeStartX: null,
        defaultCellWidth: null,
        forceWidth: null,
        // Fixed header:
        fixedWidths: {}
    };

    // Component lifecycle methods:

    componentDidMount = () => {
        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
        }

        if (!Element.prototype.closest) {
            Element.prototype.closest = function(s) {
                let el = this;
                if (!document.documentElement.contains(el)) return null;
                do {
                    if (el.matches(s)) return el;
                    el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }
        this.restartHeaderResizeObserver();
    };

    componentDidUpdate({ params: prevParams, data: prevData }) {
        const { params, data } = this.props;
        const isDataLoaded = data.length && (prevParams.loading && !params.loading);
        const isDataChanged = prevData !== data;
        const isGridFieldsChanged = prevParams.fields !== params.fields;
        if (isGridFieldsChanged || isDataChanged || isDataLoaded) {
            this.restartHeaderResizeObserver();
        }
    }

    componentWillUnmount = () => {
        this.removeHeaderResizeObserver();
        document.removeEventListener('mousemove', this.handleMoveResize);
        document.removeEventListener('mouseup', this.handleFinishResize);
        document.removeEventListener('mouseleave', this.handleFinishResize);
    };

    // grid header methods for the same columns width:

    handleHeaderResize = entries => {
        const fixedWidths = {};
        entries.forEach(entry => {
            const columnName = entry.target.dataset.name;
            const newWidth = parseInt(getComputedStyle(entry.target).width, 10);
            fixedWidths[columnName] = newWidth;
        });
        this.setState({ fixedWidths: { ...this.state.fixedWidths, ...fixedWidths } });
    };

    restartHeaderResizeObserver = () => {
        this.removeHeaderResizeObserver();
        this.addHeaderResizeObserver();
        this.resizeObserver.fireAll && this.resizeObserver.fireAll(); // if polyfill
    };

    removeHeaderResizeObserver = () => {
        this.resizeObserver.disconnect();
    };

    addHeaderResizeObserver = () => {
        if (!this.gridBody) return;
        const firstRowCells = this.gridBody.querySelectorAll('tbody tr:nth-child(1) td');
        Array.from(firstRowCells).forEach(cell => this.resizeObserver.observe(cell));
    };

    resizeObserver = new ResizeObserver(this.handleHeaderResize);

    // Save DOM refs methods:

    saveGridBodyRef = ref => (this.gridBody = ref);

    // Resize columns methods:

    handleStartResize = event => {
        const { noResize } = this.props.params.options;
        if (noResize) return;

        event.persist();
        const column = event.currentTarget.closest('th');
        const columnName = column.dataset.name;
        this.setState(
            () => ({
                resizedColumnName: columnName,
                resizeStartX: event.pageX,
                defaultCellWidth: column.offsetWidth
            }),
            () => {
                document.addEventListener('mousemove', this.handleMoveResize);
                document.addEventListener('mouseup', this.handleFinishResize);
                document.addEventListener('mouseleave', this.handleFinishResize);
            }
        );
    };

    handleMoveResize = event => {
        this.setState(prevState => {
            const { resizeStartX, defaultCellWidth } = prevState;
            if (!defaultCellWidth) return;

            const newWidth = defaultCellWidth + event.pageX - resizeStartX;
            return { forceWidth: newWidth <= MINIMUM_RESIZE_CELL_WIDTH ? MINIMUM_RESIZE_CELL_WIDTH : newWidth };
        });
    };

    handleFinishResize = event => {
        const { params: { onChangeFields, fields } } = this.props;
        const { forceWidth, resizedColumnName } = this.state;
        // if there was a drag, not a click.
        if (forceWidth) {
            onChangeFields({
                ...fields,
                [resizedColumnName]: {
                    ...fields[resizedColumnName],
                    width: forceWidth
                }
            });
        }

        this.setState(
            () => ({ resizedColumnName: null, resizeStartX: null, defaultCellWidth: null, forceWidth: null }),
            () => this.removeListeners()
        );
    };

    removeListeners = () => {
        document.removeEventListener('mousemove', this.handleMoveResize);
        document.removeEventListener('mouseup', this.handleFinishResize);
        document.removeEventListener('mouseleave', this.handleFinishResize);
    };

    // calculateSuitableWidthClass = (widths, selectedWidth) => {
    //     return widths.reduce((nearestData, widthSet, index) => {
    //         const diffSet = widthSet.map(width => Math.abs(selectedWidth - width));
    //         const diff = Math.min(...diffSet);
    //         return diff < nearestData.diff ? { diff, index } : nearestData;
    //     }, { diff: 10000, index: 0 }).index;
    // };

    // search last field
    getFirstField = fields => Object.keys(fields).find(field => !fields[field].hidden);
    getLastField = fields => Object.keys(fields).reduce((last, field) => (!fields[field].hidden ? field : last));

    // Render method:

    render = () => {
        const { resizedColumnName, forceWidth, fixedWidths } = this.state;
        const {
            params,
            data,
            groups,
            sort,
            selected,
            selectItem,
            multiSelected,
            toggleRowVisibility,
            columnsMenu,
            gridId
        } = this.props;
        const lastField = this.getLastField(params.fields);
        const firstField = this.getFirstField(params.fields);

        const commonParams = { data, params, groups, resizedColumnName, lastField, forceWidth, fixedWidths };
        return (
            <div className="table-grid-wrap">
                <GridHead
                    {...commonParams}
                    sort={sort}
                    handleStartResize={this.handleStartResize}
                    columnsMenu={columnsMenu}
                    gridId={gridId}
                />
                <GridBody
                    {...commonParams}
                    saveGridBodyRef={this.saveGridBodyRef}
                    selected={selected}
                    selectItem={selectItem}
                    multiSelected={multiSelected}
                    toggleRowVisibility={toggleRowVisibility}
                    firstField={firstField}
                />
            </div>
        );
    };
}

GridTable.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object.isRequired,
    groups: PropTypes.object,
    selectItem: PropTypes.func,
    toggleRowVisibility: PropTypes.func,
    selected: PropTypes.object,
    sort: PropTypes.object,
    multiSelected: PropTypes.array,
    columnsMenu: PropTypes.object,
    gridId: PropTypes.number
};
