import React from 'react';
import PropTypes from 'prop-types';

const ErrorField = ({ meta }) => {
    if (!meta) return null;
    const { error, touched } = meta;
    if (!error || !touched) return null;

    return <div className="error-hint">{error}</div>;
};

ErrorField.propTypes = {
    meta: PropTypes.object
};

export default ErrorField;
