import i18next from "i18next";
import storage from "./storage";
import { initReactI18next } from "react-i18next";
// import enTranslations from "../data/localization/en.json";
// import plTranslations from "../data/localization/pl.json";
// import ukTranslations from "../data/localization/uk.json";

const languageKey = storage.KEYS.LANGUAGE;
const storageLanguage = storage.get(languageKey);

i18next.use(initReactI18next).init({
    lng: storageLanguage || "uk",
    defaultNS: "translations",
    ns: ["translations", "history"],
    fallbackLng: 'uk',
    // debug: true,
    interpolation: {
        escapeValue: false,
        // formatSeparator: ',',
    },
    resources: {
        // en: { translations: {} },
        // pl: { translations: {} },
        // uk: { translations: {} },
    },
    react: {
        wait: true
    }
});

i18next.on("languageChanged", (lang) => {
    console.log("i18next::languageChanged: ", lang);
    storage.set(languageKey, lang);
});
// i18next.on('added', (lang, namespace) => console.log('Language: %s, Namespace: %s', lang, namespace));

export default i18next;
