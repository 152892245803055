import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';
import * as reducers from '../reducers';
import routingHistory from './history';

const appReducer = combineReducers({
    router: connectRouter(routingHistory),
    ...reducers
});

const rootReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_STATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    tabCachingEnabled: true,
                },
                ...action.nextState,
            };

        default:
            return appReducer(state, action);
    }
};

const configureStore = () => {
    return createStore(
        rootReducer,
        MODE === 'development' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
    );
};

const store = configureStore();

export default store;
