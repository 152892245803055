import React, { Component } from 'react';
import ReactSVG from "react-svg";

class File extends Component {
    onDelete = () => this.props.handleDeleteConfirm(this.props.id);

    render() {
        const { name, loader, loading, path, id, disabled } = this.props;

        return (
            <div className="file" key={id || name}>
                <a
                    className="file-preview"
                    target="_blank"
                    href={`${path}${id}&download=0`}
                    title={name}
                >
                    {name}
                </a>
                {!loading && <div className="file-actions-ordering">
                    <a
                        className="file-download"
                        target="_blank"
                        href={`${path}${id}&download=1`}
                        title={name}
                    >
                         <ReactSVG className="svg-download" src="./images/icons/download.svg"/>
                    </a>
                    {!disabled && <ReactSVG title={name} className="svg-delete" src="./images/icons/delete-can.svg" onClick={this.onDelete} />}
                </div>}
                {loading && loader}
            </div>
        );
    }
}

export default File;
