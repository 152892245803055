import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Authorization from "../Authorization";
import AsideTabs from "../../components/AsideTabs";
import { toggleRouteChildren } from "../../reducers/app";

import "./styles.scss";

const AppSideBar = (props) => {
    const { isAuthorized, person, roles, routes, isRoutesLoading, toggleRouteChildren, withoutAuthorization } = props;

    return (
        <aside className="page-sidebar">
            {!isAuthorized && !withoutAuthorization && <Authorization key="desktop-auth" />}
            <AsideTabs routes={routes} isLoading={isRoutesLoading} onRouteWithChildClick={toggleRouteChildren} />
        </aside>
    );
};

const mapStateToProps = ({ user, app, auth }) => ({
    isAuthorized: auth.state && auth.state === "auth",
    routes: app.routes,
    isRoutesLoading: app.isRoutesLoading,
    person: user.person,
    roles: user.roles,
});

export default connect(mapStateToProps, { toggleRouteChildren })(withRouter(AppSideBar));
