import { settings } from '../config';

export default Object.freeze({
    KEYS: {
        LANGUAGE: `${settings.appNameAbbr}_LANGUAGE`,
        GRIDS: `${settings.appNameAbbr}_GRIDS`,
        FILTERS: `${settings.appNameAbbr}_FILTERS`,
        TABS: `${settings.appNameAbbr}_TABS`,
        REQUESTS_AUTO_UPDATE: `${settings.appNameAbbr}_REQUESTS_AUTO_UPDATE`,
        ROUTES: `${settings.appNameAbbr}_ROUTES`
    },

    set: (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (error) {
            console.error(error.message);
            return false;
        }
    },

    get: key => {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (error) {
            console.error(error.message);
            return undefined;
        }
    }
});
