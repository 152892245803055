import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Portal from '../Portal';

export default class GridPopup extends React.PureComponent {
    state = {
        fields: this.props.fields
    };

    setWrapperRef = node => (this.wrapperRef = node);

    handleChange = event => {
        const { fields } = this.state;
        const { value, checked } = event.currentTarget;

        this.setState({
            fields: {
                ...fields,
                [value]: {
                    ...fields[value],
                    hidden: !checked
                }
            }
        });
    };

    handleClickOutside = event => {
        if (!this.wrapperRef) return;
        if (this.wrapperRef.contains(event.target)) return;

        const { fields: stateFields } = this.state;
        const { columnsMenu, handleSaveChanges, fields: propsFields } = this.props;
        columnsMenu.close();
        // nothing changed, so no sense to call fields changes handler
        if (stateFields === propsFields) return;

        handleSaveChanges(stateFields);
    };

    getPopup = () => {
        const { columnsMenu, t, gridId } = this.props;
        const { fields } = this.state;
        const parent = document.getElementById(gridId);

        return (
            columnsMenu.isOpen && (
                <Portal parent={parent}>
                    <div className="grid-col-popup" ref={this.setWrapperRef}>
                        <div className="check-fields">
                            {Object.entries(fields).map(([value, { name, hidden, permanent }]) => (
                                <div key={`grid-hide-${value}`}>
                                    <input
                                        id={`grid-hide-${value}`}
                                        type="checkbox"
                                        disabled={permanent}
                                        value={value}
                                        checked={!hidden}
                                        onChange={this.handleChange}
                                        hidden={!!name}
                                    />
                                    {name && (
                                        <label
                                            className={cx('grid-col-popup-column', { 'active': !hidden })}
                                            htmlFor={`grid-hide-${value}`}
                                        >
                                            {t(name)}
                                        </label>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </Portal>
            )
        );
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.fields) {
            this.setState({ fields: nextProps.fields });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render = () => {
        const { columnsMenu } = this.props;

        return (
            <div className="grid-col-popup-wrapper">
                <div className="grid-col-popup-button">
                    <i className="icon-kebab-hor" onClick={columnsMenu.open} />
                </div>
                {this.getPopup()}
            </div>
        );
    };
}

GridPopup.propTypes = {
    t: PropTypes.func.isRequired,
    columnsMenu: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    handleSaveChanges: PropTypes.func.isRequired,
    gridId: PropTypes.number
};
