import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Portal } from 'react-overlays';

import '../assets/styles/common.scss';

class InputField extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.inputWrapperRef = React.createRef();
	// }
	state = {
		errorPopupIsOpen: false,
		left: undefined,
		top: undefined
	};

	// // trim value and input element value to have no spaces at the beginning and at the end
	// componentDidUpdate() {
    //     const { input } = this.props;
	// 	const isTrimNeeded = input.value.startsWith(' ') || input.value.endsWith(' ');
    //     if (isTrimNeeded && this.inputWrapperRef.current) {
    //         const refChildren = Array.from(this.inputWrapperRef.current.childNodes);
    //         const inputElement = refChildren.find((child) => child.tagName === 'INPUT');
    //         if (inputElement) {
    //             const trimmed = input.value.trim();
    //             input.value = trimmed;
    //             inputElement.value = trimmed;
    //         }
    //     }
    // }
	
	toggleErrorPopup = () => {
		this.setPosition();
		setTimeout(() => this.setState({ errorPopupIsOpen: !this.state.errorPopupIsOpen }), 100);
	};
	
	openErrorPopup = () => {
		this.setPositionError();
		setTimeout(() => this.setState({ errorPopupIsOpen: true }), 100);
	};
	
	closeErrorPopup = () => {
		setTimeout(() => this.setState({ errorPopupIsOpen: false }), 100);
	};
	
	setPositionError = () => {
		const rect = this.inputError.getBoundingClientRect();
		const left = window.scrollX + rect.left + rect.width;
		const top = window.scrollY + rect.top;
		
		if (top !== this.state.top || left !== this.state.left) {
			this.setState({ left, top });
		}
	};
	
	render () {
		const {
			label,
			input,
			meta,
			required,
			additionalInputClassName,
			setFieldToBeFocused = () => {},
			errorPlaceholder,
			fullWidth,
			placeErrorPopup,
			onClose,
			maxLength,
			...rest
		} = this.props;
		const { errorPopupIsOpen, left, top } = this.state;
		
		const inputClassName = cx(
			'input-field',
			meta && meta.touched && (meta.error || meta.warning) && 'input-field__error',
			meta && meta.active && 'active',
			additionalInputClassName,
			onClose && 'closable'
		);
		
		const rootClassName = cx('input-element', {
			['full-width']: fullWidth
		});
		
		const restProps =
			errorPlaceholder ? {
				...rest,
				placeholder: meta && meta.touched && !meta.active && (meta.error || meta.warning) || rest.placeholder
			} : rest;
		
		const stylePopup = {
			left: left,
			top: top
		};
		const containerPopup = document.getElementById('popup-portal');
		
		return (
			<div className={rootClassName}>
				<div className='input-label'>
					{label}
					{required && <span className='required-field'>*</span>}
				</div>
				<div className='input-field-container'>
					<input
						className={inputClassName}
						{...input}
						{...restProps}
						autoComplete='off'
						type='text'
						maxlength={maxLength}
						ref={inputField => setFieldToBeFocused(inputField)}
					/>
					{
						onClose &&
						<button onClick={onClose}>
							<i className='icon-close' />
						</button>
					}
				</div>
				
				{meta && meta.touched && (meta.error || meta.warning) && input.value !== '' ?
					<div className='input-error' ref={(node) => this.inputError = node}>
                        <span
							className={cx('icon', 'icon-error')}
							onMouseEnter={this.openErrorPopup}
							onMouseLeave={this.closeErrorPopup}
						/>
						{errorPopupIsOpen && <Portal container={containerPopup}>
							<div
								className={cx('error-popup error-hint', placeErrorPopup)}
								style={stylePopup}
							>
								<span>{meta.error || meta.warning}</span>
							</div>
						</Portal>}
					</div> : null}
			</div>
		);
	}
}

InputField.propTypes = {
	label: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object,
	required: PropTypes.bool,
	additionalInputClassName: PropTypes.string,
	errorPlaceholder: PropTypes.bool,
	fullWidth: PropTypes.bool,
	setFieldToBeFocused: PropTypes.func,
	onClose: PropTypes.func
};

export default InputField;
