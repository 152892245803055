import React from 'react';

class Header extends React.Component {
    render() {
        return (
            <header className="header-block card-panel">
                <div className="header-block__priocom-logo" />

                {this.props.Search ? <this.props.Search /> : null}

                <a className="header-block__calender control-box">
                    <i className="mi mi-event" />
                </a>
                <a className="header-block__incoming incoming control-box">
                    <i className="mi mi-notifications" />

                    {this.addField({
                        val: this.props.data,
                        class: 'incoming__counter'
                    })}
                </a>

                {this.props.UserInfo ? <this.props.UserInfo /> : null}
            </header>
        );
    }
}

export default Header;
