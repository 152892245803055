import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './styles.scss';

const CollapsedBlock = props => {
    const {
        t,
        dataName,
        name,
        icon,
        description,
        children,
        isOpen,
        noCollapse,
        handleCollapse,
        headerButton,
        styles
    } = props;

    const [open, setOpen] = useState(isOpen);

    const toggleCollapse = () => {
        // if handleCollapse func provided, then it seems to work through redux store
        if (noCollapse) return;
        if (handleCollapse) handleCollapse(!open);
        setOpen(prevStatus => !prevStatus);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <div
            className={`collapsed-block ${open ? 'open' : ''} ${icon ? 'no-line' : ''}`}
            data-name={dataName}
            style={{ ...styles }}
        >
            <div className="collapsed-block-header">
                {!noCollapse && <i className="icon-arrow-right" onClick={toggleCollapse} />}
                <div className="collapsed-header-info">
                    <div className="collapsed-header-text" onClick={toggleCollapse}>
                        {icon && <i className={`icon ${icon}`} />}
                        {!icon && <div className={`name ${noCollapse ? '' : 'pointer'}`}>{t(name)}</div>}
                        {description && <div className="collapsed-header-description">{t(description)}</div>}
                    </div>
                    {icon && !description && <div className="midle-line" />}
                    {headerButton && headerButton}
                </div>
            </div>
            <div className="collapsed-block-content">{children}</div>
        </div>
    );
};

CollapsedBlock.propTypes = {
    t: PropTypes.func.isRequired,
    dataName: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    noCollapse: PropTypes.bool,
    handleCollapse: PropTypes.func,
    headerButton: PropTypes.node,
    styles: PropTypes.object
};

export default withTranslation()(CollapsedBlock);
