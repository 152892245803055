import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './services/i18n';
import store from './store';
import App from './App';

const rootEl = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootEl
);
