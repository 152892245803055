import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import cx from 'classnames';

import FormElementWrapper from '../FormElementWrapper';
import DateInput from './DateInput';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = props => {
    const {
        saveFormat,
        viewFormat,
        placeholder,
        required,
        plain,
        label,
        language,
        meta,
        input,
        clearable,
        hasOnWheel,
        liveSaver,
        tooltip,
        hasTimePicker,
        handleChangeValue,
        disabled,
        manual,
        ...rest
    } = props;
    const { value } = input;

    const [dateValue, setDateValue] = useState(null);
    const [selected, setSelected] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [blurAction, setBlurAction] = useState(true);
    const [fromCalendar, setFromCalendar] = useState(false);
    const [inputFocuc, setInputFocuc] = useState(false);
    const [touched, setTouched] = useState(false);

    const elementCls = cx('datepicker', {
        disabled,
        // 'input-element__error': metaData && metaData.touched && metaData.error,
        plain
    });

    const valueToForm = val => {
        return val && val.isValid() && parseInt(val.format(saveFormat), 10);
    };

    const getTimeCaption = () => {
        switch (language) {
            case 'ru':
                return 'Время';
            case 'uk':
                return 'Час';
            case 'en':
                return 'Time';
            default:
                return 'UNKNOWN';
        }
    };

    const handleBlur = () => {
        setTouched(true);
        setInputFocuc(false);
    };

    const checkValid = val => {
        const momentVal = moment(val, viewFormat, true);
        return momentVal.isValid() ? valueToForm(momentVal) : val;
    };

    const handleChange = val => {
        const newDate = val.isValid ? valueToForm(val) : checkValid(val);
        setFromCalendar(val === '' || !!val.isValid);
        input.onChange(val === '' ? null : newDate);
        if (handleChangeValue) handleChangeValue(newDate);
    };

    const getCustomInput = () => (
        <DateInput
            manual={manual}
            fieldKey={input.name}
            onChangeRaw={handleChange}
            setBlurAction={setBlurAction}
            calendar={calendar}
            clearable={clearable}
            value={dateValue}
            inputFocuc={inputFocuc}
        />
    );

    useEffect(() => {
        const toFormat = typeof value === 'string' ? viewFormat : saveFormat;
        const momentVal = moment(value, toFormat, true);
        const valid = value === null || value === '' ? true : momentVal.isValid();

        const isOpen = !!calendar && calendar.isCalendarOpen();
        const focusTo = fromCalendar && !isOpen;

        setBlurAction(valid);
        setInputFocuc(focusTo);

        setSelected(momentVal.isValid() ? momentVal : null);
        setDateValue(momentVal.isValid() ? momentVal.format(viewFormat) : value);
    }, [value]);

    return (
        <FormElementWrapper
            className={elementCls}
            tooltip={tooltip}
            meta={meta}
            touched={touched}
            hasOnWheel={hasOnWheel}
            label={label}
            liveSaver={liveSaver}
            blurAction={blurAction}
            required={required}
        >
            <ReactDatePicker
                {...input}
                {...rest}
                value={dateValue}
                locale={language}
                title={dateValue}
                selected={selected}
                className="input-field"
                showTimeSelect={hasTimePicker}
                // showTimeSelectOnly={showTimeSelect}
                popperClassName="datepicker-popper datepicker-portal"
                popperPlacement="bottom-end"
                calendarClassName="calendar"
                dateFormat={viewFormat}
                timeCaption={getTimeCaption()}
                timeFormat="HH.mm"
                placeholderText={placeholder}
                customInput={getCustomInput()}
                isClearable={false}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                ref={node => setCalendar(node)}
            />
        </FormElementWrapper>
    );
};

export default DatePicker;

DatePicker.defaultProps = {
    language: 'en',
    saveFormat: 'X', // Unix Timestamp
    viewFormat: 'DD/MM/YYYY HH:mm', // 31/12/2018 24:60
    hasTimePicker: true,
    input: null,
    meta: null,
    label: '',
    manual: true
};

DatePicker.propTypes = {
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    plain: PropTypes.bool,
    disabled: PropTypes.bool,
    hasOnWheel: PropTypes.bool,
    tooltip: PropTypes.string,
    language: PropTypes.string,
    saveFormat: PropTypes.string,
    viewFormat: PropTypes.string,
    hasTimePicker: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    clearable: PropTypes.bool,
    liveSaver: PropTypes.object,
    handleChangeValue: PropTypes.func,
    manual: PropTypes.bool
};
