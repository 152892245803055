import React from 'react';
import PropTypes from 'prop-types';

import { withTransition } from '../../decorators';

function NotificationHolder({ children, id, handleClose }) {
    return (
        <div className="notification-message">
            <div className="notification-message-text">{children}</div>
            <span className="notification-message-close" onClick={() => handleClose(id)}>
                <i className="icon-close" />
            </span>
        </div>
    );
}

export default withTransition('fade', null, 2000)(NotificationHolder);

NotificationHolder.propTypes = {
    id: PropTypes.number,
    handleClose: PropTypes.func
};
