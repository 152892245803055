import icons from "./icons";

export default {
    appName: "SDS Portal",
    appNameAbbr: "SDS-Portal",
    // defaultUserAvatarSrc: './images/usermenu/user-login.png',
    displayNotificationTimeout: 5000,
    routeRedirect: "main",
    requests: {
        baseURL: "../api",
        redirectURL: `/${window.location.pathname.split("/")[1]}/api`,
        socketURL: "/api-websocket",
        socketPath: `/${window.location.pathname.split("/")[1]}/api-websocket`,
        timeout: 15 * 1000, // 15 seconds
    },
    defaultLanguage: "en",
    ffmLogo: { icon: icons.ffm },
    // with menu you can override menu item #icon# or #link#
    // by menu item name key
    // name keys came from menu request
    menu: {
        requests: { icon: icons.requests },
        planning: { icon: icons.planning },
        resources: { icon: icons.resources },
        locations: { icon: icons.locations },
        map: { icon: icons.map },
        reports: { icon: icons.reports },
    },
    menuSectionsIcons: {
        public: icons.public,
        settings: icons.settings,
    },
    mobileScreenWidth: 749,
    mapSettings: {
        defaultCenter: { lat: 48.5, lng: 31 },
        distance: 0,
        defaultZoom: 6,
        options: {
            mapTypeId: "roadmap",
            // streetViewControl: false,
            // scaleControl: true,
            // fullscreenControl: false,
            // gestureHandling: 'greedy',
            // disableDoubleClickZoom: true,
            // minZoom: 11,
            maxZoom: 16,
            mapTypeControl: false,
            // mapTypeId: maps.MapTypeId.SATELLITE,
            // mapTypeControlOptions: {
            //     style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            //     position: maps.ControlPosition.BOTTOM_CENTER,
            //     mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
            // },
            // zoomControl: true,
            // clickableIcons: false
            // styles: mapStyles,
        },
    },
    statusOptions: [
        { label: "Активне", value: "active" },
        { label: "Не активне", value: "not_active" },
    ],
    authorizationWidgetHideRoutes: ["/forgotPassword", "/registration"],
};
