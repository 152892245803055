import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ErrorField from './ErrorField';
import LiveSaver from './LiveSaver';
import Portal from '../Portal';
import Popup from '../Popup';

const FormElementWrapper = props => {
    const { liveSaver, tooltip, label, required, className, meta, touched = false, hasOnWheel, blurAction, children, ...rest } = props;

    const [popupData, setPopupData] = useState(null);

    const wheel = event => event.stopPropagation();

    let timerIn = null;
    let timerOut = null;

    const openPopup = e => {
        const element = e.target;
        const elementHeight = element.getBoundingClientRect().height;
        const pageX = element.getBoundingClientRect().left;
        const pageY = element.getBoundingClientRect().top + elementHeight;

        clearTimeout(timerOut);
        timerIn = setTimeout(() => setPopupData({ pageX, pageY }), 1000);
    };

    const closePopup = () => {
        clearTimeout(timerIn);
        timerOut = setTimeout(() => setPopupData(null), 100);
    };

    const getPopup = () => (
        <Portal>
            <Popup place="info-popup" point={popupData}>
                <div className="info-text">{tooltip}</div>
            </Popup>
        </Portal>
    );

    const getLabel = () => {
        return label ? (
            <div className="input-label">
                {label}
                {required && <span className="required-field">*</span>}
                {tooltip && (
                    <div className="tool-tip" onMouseEnter={openPopup} onMouseLeave={closePopup}>
                        <i className="icon-tooltip" />
                        {popupData && getPopup()}
                    </div>
                )}
            </div>
        ) : null;
    };

    const containerClassName = cx(
        'input-element',
        { 'input-element__error': meta && (meta.touched || touched) && !meta.valid },
        className
    );

    const onWheel = hasOnWheel ? wheel : undefined;

    const wrapper = (
        <div className={containerClassName} onWheel={onWheel} {...rest}>
            {getLabel()}
            {children}
            <ErrorField meta={meta} touched={touched}/>
        </div>
    );

    return liveSaver ? (
        <LiveSaver liveSaver={liveSaver} blurAction={blurAction}>
            {wrapper}
        </LiveSaver>
    ) : (
        wrapper
    );
};

FormElementWrapper.propTypes = {
    hasOnWheel: PropTypes.bool,
    required: PropTypes.bool,
    meta: PropTypes.object,
    className: PropTypes.string,
    blurAction: PropTypes.bool
};
FormElementWrapper.defaultProps = {
    hasOnWheel: false,
    required: false,
    meta: null,
    className: ''
};

export default FormElementWrapper;
