import React from 'react';

import './styles.scss';

const Rank = props => {
    const { label, required, key, disabled, values,
        showValues, input: { name, value: inputValue, onChange } } = props;

    const handleClick = e => {
        const value = e.currentTarget.getAttribute('value');
        onChange(inputValue === value ? null : value);
    };

    const renderRank = (data, index) => {
        const { key, value } = data;
        const checkedIndex = values.findIndex(item => item.key === inputValue);
        const isChecked = checkedIndex !== -1 && +index <= +checkedIndex;
        const className = isChecked ? 'rank-value-container checked' : 'rank-value-container';
        return (
            <div key={key} className={className} onClick={!disabled && handleClick} value={key} title={value}>
                <input type='text' value={key} name={name} /> {/* input used for handling blur events, no blur event fires without form element */}
                <svg widths='19.69' height='19.98' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.93 13.72'>
                    <g><path d='M13.46,4.92l-4.2-.63L7.37.35C7.27.12,7.13,0,7,0s-.3.12-.41.35L4.67,4.29l-4.2.63C.16,5,0,5.1,0,5.32a.67.67,0,0,0,.21.41L3.26,8.79l-.72,4.32a.66.66,0,0,0,0,.17.52.52,0,0,0,.09.31.3.3,0,0,0,.26.13.74.74,0,0,0,.34-.11l3.75-2,3.76,2a.68.68,0,0,0,.34.11.3.3,0,0,0,.25-.13.52.52,0,0,0,.09-.31.81.81,0,0,0,0-.17l-.72-4.32,3-3.06a.62.62,0,0,0,.22-.41c0-.22-.16-.35-.47-.4Z' /></g>
                </svg>
                {showValues && <div className='rank-value'>({value})</div>}
            </div>
        );
    };

    return (
        <div className='input-element rank-input-wrapper'>
            <div className='input-label'>
                {label}{required && <span className='required-field'>*</span>}
            </div>
            <input key={key} name={name} type='text' disabled={disabled} hidden />
            <div className={disabled ? 'rank-values disabled' : 'rank-values'}>
                {values && values.map(renderRank)}
            </div>
        </div>
    );
};

export default Rank;