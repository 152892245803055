import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ModalWindow } from 'ui-core-dashboard';
import Button from '../Button';
import Loader from '../Loader';

const ConfirmModal = props => {
    const { t, title, message, changeVariable, isOpen, isSubmitting, onClose, onConfirm } = props;
    return (
        <ModalWindow isOpen={isOpen} onClose={onClose}>
            {isSubmitting && <Loader />}
            <header className="modal-header">{title}</header>
            <div className="modal-body">
                <div className="padding-box">
                    {message} {changeVariable && <span className="change-variable">{changeVariable}?</span>}
                </div>
            </div>
            <footer className="modal-footer">
                <Button className="btn-delete-circle" onClick={onConfirm} text={t('yes')} />
                <Button className="btn-cancel-circle" onClick={onClose} text={t('Cancel')} />
            </footer>
        </ModalWindow>
    );
};

ConfirmModal.propTypes = {
    t: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    changeVariable: PropTypes.oneOfType(['string', 'number'])
};

export default withTranslation()(ConfirmModal);
