import React, { } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { DynamicFormHook } from "../../components";
import { getFormValues } from "redux-form";
import { setLanguage } from "../../reducers/app";
import { get } from "lodash";

import "./styles.scss";

const FORM_LANGUAGE = "form-language";

// const CONTACTS = [
//     {
//         name: "Телефон",
//         contacts: [
//             {
//                 type: "tel",
//                 contact: "0 (44) 494 19 10",
//                 hrefValue: "0444941910",
//                 // additional: "факс.",
//             },
//             // {
//             //     type: "tel",
//             //     contact: "0 (44) 765 43 21",
//             //     hrefValue: "0447654321",
//             // },
//         ],
//     },
//     // {
//     //     name: "Електронна пошта",
//     //     contacts: [
//     //         {
//     //             type: "mailto",
//     //             // contact: "it-support@uz.gov.ua",
//     //             // hrefValue: "it-support@uz.gov.ua",
//     //             contact: "support-demo-crm@priocom.net",
//     //             hrefValue: "support-demo-crm@priocom.net",
//     //         },
//     //     ],
//     // },
//     // {
//     //     name: "Skype",
//     //     contacts: [
//     //         {
//     //             type: "skype",
//     //             // contact: "it-support@uz.gov.ua",
//     //             // hrefValue: "it-support@uz.gov.ua",
//     //             contact: "support",
//     //             hrefValue: "support",
//     //         },
//     //     ],
//     // },
// ];

// const CONTACTS = [
//     {
//         name: "Телефон",
//         contacts: [
//             {
//                 type: "tel",
//                 contact: "5-06-91",
//                 hrefValue: "50691",
//                 // additional: "факс.",
//             },
//             {
//                 type: "tel",
//                 contact: "0 (44) 465-06-91",
//                 hrefValue: "0444650691",
//             },
//         ],
//     },
//     {
//         name: "Електронна пошта",
//         contacts: [
//             {
//                 type: "mailto",
//                 // contact: "it-support@uz.gov.ua",
//                 // hrefValue: "it-support@uz.gov.ua",
//                 contact: "servicedesk@uz.gov.ua",
//                 hrefValue: "servicedesk@uz.gov.ua",
//             },
//         ],
//     },
//     // {
//     //     name: "Skype",
//     //     contacts: [
//     //         {
//     //             type: "skype",
//     //             // contact: "it-support@uz.gov.ua",
//     //             // hrefValue: "it-support@uz.gov.ua",
//     //             contact: "support",
//     //             hrefValue: "support",
//     //         },
//     //     ],
//     // },
// ];

const AppFooter = (props) => {
    const { t, i18n, languages, isLanguageChangePermission = true, dataForm, setLanguage, translations } = props;

    const appContacts = get(translations, 'supportContactsInfo');

    const languageValue = languages.find((language) => language.value === i18n.language);

    const getLanguageForm = () => ({
        structure: [
            {
                column_count: 1,
                attrs: [
                    {
                        name: t("interfaceLanguage"),
                        sname: "language",
                        widget_type: "ComboBox",
                        options: languages,
                    },
                ],
            },
        ],
        initialValues: {
            language: languageValue,
        },
    });

    const handleFormChange = (values, _d, _f, oldValues) => {
        const { language } = values;
        // i18n.changeLanguage(language.value);
        setLanguage(language.value);
    };

    const renderContacts = () => {
        if (!appContacts) {
            return null;
        }
        return appContacts.map(({ name, contacts }) => {
            return (
                <div className="page-footer__contacts-column" key={name}>
                    <div className="page-footer__contacts-title">{name}</div>
                    {contacts.map(({ type, contact, hrefValue, additional }) => {
                        return (
                            <a className="page-footer__contact" href={`${type}:${hrefValue}`} key={contact}>
                                {contact}
                                {additional && <span>{`, ${additional}`}</span>}
                            </a>
                        );
                    })}
                </div>
            );
        });
    };

    return (
        <footer className="page-footer">
            <div className="page-footer__container">
                <div className="page-footer__contacts">
                    <div className="page-footer__title">{t("supportContacts")}</div>
                    {renderContacts()}
                </div>
                {isLanguageChangePermission && (
                    <div className="page-footer__language">
                        <DynamicFormHook
                            formName={FORM_LANGUAGE}
                            {...getLanguageForm()}
                            withoutLiveSaver
                            withoutCollapse
                            onChange={handleFormChange}
                        />
                    </div>
                )}
            </div>
        </footer>
    );
};

const mapStateToProps = ({ form, app }) => ({
    dataForm: getFormValues(FORM_LANGUAGE)({ form }),
    translations: app.translations,
    languages: app.settings.languages,
});

export default connect(mapStateToProps, { setLanguage })(withTranslation()(AppFooter));

AppFooter.propTypes = {
    t: PropTypes.func,
};
