const leftTrim = (data) => {
    Object.keys(data).forEach((prop) => {
        if (data[prop] && typeof data[prop] === "string") {
            data[prop] = data[prop].trimLeft();
        }
    });
};

const requiredValidation = (values, config, errors) => {
    config.forEach((field) => {
        if (values[field.name] === null || values[field.name] === undefined || values[field.name] === "") {
            errors[field.name] = "requiredField";
        }
    });
};

// old
// const rulesValidation = (values, config, errors) => {
//     Object.keys(config).forEach(field => {
//         if (!config[field].rule(values[field])) {
//             errors[field] = config[field].rule_error;
//         }
//     });
// };

const rulesValidation = (values, config, errors) => {
    Object.keys(config).forEach((field) => {
        const regex = typeof config[field].rule === 'function' ? config[field].rule(values[field]) : config[field].rule;
        if (regex && !regex.test(values[field])) {
            errors[field] = config[field].rule_error;
        }
    });
};

export function validate(values, props) {
    const { structure, t } = props;

    const widgets = [];

    structure && structure.forEach((block) => widgets.push(...block.attrs));

    const errors = {};

    // all required widgets
    const required = widgets
        .filter((widget) => !widget.disabled && widget.required)
        .map((wdg) => ({ name: wdg.sname, required: wdg.required }));

    // all rules widgets
    const rules = widgets
        .filter((widget) => !widget.disabled && widget.rule)
        .reduce(
            (acc, curr) => ({
                ...acc,
                [curr.sname]: { name: curr.sname, rule: curr.rule, rule_error: curr.rule_error },
            }),
            {}
        );

    // leftTrim(values);
    requiredValidation(values, required, errors);

    // console.log({ widgets, values, rules, errors });
    rulesValidation(values, rules, errors);

    // translate errors after validation
    Object.keys(errors).map((key) => {
        errors[key] = t(errors[key]);
    });

    return errors;
}

export default validate;
