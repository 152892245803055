/* constants */
import { fetch } from "../decorators";


// staticBlocks are being initialised before app init, or on registration click (from Authorization block)
const initialState = {};

const actions = {
    SET_STATIC_BLOCKS: 'SET_STATIC_BLOCKS'
};

const DEFAULT_FORM_BLOCK = 'FORM_SDSPORTAL_DEFAULT';

/* reducer */

export default (state = initialState, { type, ...payload }) => {
    if (actions[type]) {
        // if (type === 'SET_STATIC_BLOCKS') {
        //     return {
        //         ...state,
        //         [type]: payload
        //     };
        // }
        return { ...state, ...payload };
    }
    return state;
};
/* action creators */
export const setStaticBlocks = data => ({
    type: actions.SET_STATIC_BLOCKS,
    ...data
});
export const fetchStaticBlocks = () => dispatch => {
    // console.log({objectType});
    fetch().send({
        api: { key: 'staticBlock' },
        urlVars: { objectType: 'appeal' },

        onStart: () => {
            // dispatch({ type: ON_STATIC_BLOCKS_START, objectType });
        },
        onSuccess: ({ result }) => {
            const formBlock = result.find(item => item.code === DEFAULT_FORM_BLOCK);
            // dispatch(setStaticBlocks({ [objectType]: formBlock }));
            dispatch(setStaticBlocks(formBlock));

            // setIsLoading(false);
        },
        onFailure: () => {
            // dispatch({ type: ON_STATIC_BLOCKS_FAILURE, objectType });
            // setIsLoading(false);
        },
    });
};
