import React, { useState, useEffect } from "react";
import { reduxForm, submit, getFormValues, getFormSyncErrors, isDirty, Form, Field } from "redux-form";
import { isEmpty, isEqual, isObject } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cx from "classnames";

import { Input, ComboBox, Combo, CheckBox, RadioBox, TextArea, DatePicker, NumberInput } from "ui-core-dashboard";
import CalendarContainer from "./CalendarContainer";
import CollapsedBlock from "./CollapsedBlock";
import EditorHTML from "../EditorHTML";
import Button from "../Button";
import { fetch } from "../../decorators";
import { api } from "../../services";
import validate from "./validate";

import "./styles.scss";

const mapStateToProps = (state, ownProps) => ({
    form: ownProps.formName, // set form name from ownProps
    formValues: getFormValues(ownProps.formName)(state),
    formErrors: getFormSyncErrors(ownProps.formName)(state),
    isFormDirty: isDirty(ownProps.formName)(state),
    active: state.form && state.form[ownProps.formName] && state.form[ownProps.formName].active,
});

const mapDispatchToProps = {
    submitForm: (form) => submit(form),
};

const DynamicFormHook = (props) => {
    const {
        i18n: { language },
        t,
        active,
        className,
        structure,
        handleSubmit,
        onSubmit,
        submitForm,
        formValues,
        formErrors,
        formName,
        change,
        reset,
        initialize,
        isFormDirty,
        resetParams,
        withoutLiveSaver = false,
        withoutCollapse = false,
        submitButton = false,
        style,
        headerButton,
        initialValues,
        children,
    } = props;

    if (!structure) return null;

    const [isInitialized, setIsInitialized] = useState(false);
    const [initValues, setInitValues] = useState(initialValues);
    const blocksQuantity = structure.length;

    const request = fetch();

    const fetchOptions = async ({ asyncParams, query, dictionary_id, labelKey, valueKey, customLabel }) => {
        const { urlVars, ...rest } = asyncParams;
        const requestParams = dictionary_id
            ? { api: { key: "specDictId" }, urlVars: { dictionary_id } }
            : { ...rest, query };

        const { data } = await api.request(requestParams, urlVars);

        return data.result.map((el) => ({
            label: customLabel ? customLabel(el) : el[labelKey],
            value: el[valueKey],
        }));
    };

    const filterOptions = (opt, input) => {
        if (input || input !== "") {
            return opt.label.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    };

    const handleLiveSubmit = (key, responseCallback) => {
        formErrors[key] && responseCallback(false);
        !formErrors[key] && onSubmit({ [key]: formValues[key] || null }, responseCallback);
    };

    const handleAllSubmit = () => {
        isEmpty(formErrors) && onSubmit(formValues, formErrors);
    };

    const getWidget = (widget) => {
        const {
            sname: key,
            name,
            widget_type,
            options = [],
            asyncApi,
            asyncParams,
            asyncSearchParams,
            dictionary_id,
            max_length = 250,
            disabled,
            required,
            closeOnSelect = true,
            menuShouldBlockScroll = false,
            closeMenuOnScroll = false,
            removeSelected,
            filterOption,
            multi,
            plain,
            labelKey = "name",
            valueKey = "id",
            selectNode,
            date_format,
            inline,
            showMonthDropdown,
            showYearDropdown,
            yearItemNumber,
            startDate,
            endDate,
            dropdownMode,
            clearable = false,
            label,
            title,
            tooltip,
            type,
            autoFocus = false,
            min = 0,
            max = 999999,
            maxLength = 2000,
            step = 1,
            wrapper,
            hasPasswordViewPermission,
            onPressEnter,
            onChange,
            defaultOptions,
            customLabel,
            manual,
            rule,
            rule_error,
            placeholder,
        } = widget;

        const liveSaverProps = {
            fieldKey: key,
            initValue: initialValues ? initialValues[key] : null,
            value: formValues ? formValues[key] : null,
            change,
            active,
            label: label || t(name),
            handleLiveSubmit,
        };

        const getTitile = () =>
            title || formValues[key] ? (isObject(formValues[key]) ? formValues[key].label : null) : null;

        const defaultProps = {
            key,
            name: key,
            label: liveSaverProps.label,
            title: !wrapper && type !== "password" ? getTitile() : null,
            placeholder: placeholder || !disabled ? (asyncSearchParams ? t("Search") : t("placeholder")) : "",
            liveSaver: !withoutLiveSaver && { ...liveSaverProps, enterDisabled: widget_type === "TextArea" },
            required,
            disabled,
            tooltip,
            type,
            clearable,
            plain,
            autoFocus,
            language,
            isSearchable: !plain,
            hasPasswordViewPermission,
            onPressEnter,
            rule,
            rule_error,
            onChange,
        };

        const defaultComboBoxProps = {
            dictionary_id,
            noResultsText: t("noItems"),
            noOptionsMessage: () => t('noItems'),
            escapeClearsValue: withoutLiveSaver,
            clearAllText: t("clearValue"),
            deleteRemoves: !required,
            backspaceRemoves: !required,
            closeMenuOnSelect: closeOnSelect,
            menuShouldBlockScroll,
            closeMenuOnScroll,
            removeSelected,
            asyncParams,
            labelKey,
            valueKey,
            options,
            defaultOptions,
            fetchOptions,
            filterOption: asyncParams ? null : filterOption || filterOptions,
            customLabel,
            isMulti: multi,
            isClearable: required ? false : clearable,
            isDisabled: disabled,
            onBlurResetsInput: !asyncSearchParams,
            onSelectResetsInput: !asyncSearchParams,
            className: "container-combo-box",
            classNamePrefix: "select",
        };

        const defaulDatePickerProps = {
            inline,
            showYearDropdown,
            dropdownMode,
            showMonthDropdown,
            yearItemNumber,
            startDate,
            endDate,
            manual,
        };

        const defaultNumberProps = { min, max, step };

        const onFocusHandler = () => {
            // debugger;
        };

        switch (widget_type) {
            case "ComboBox": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultComboBoxProps}
                        onFocus={onFocusHandler}
                        component={ComboBox}
                        isCreatable={!!asyncApi} // not use
                    />
                );
            }
            case "SelectTree": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultComboBoxProps}
                        className={`${defaultComboBoxProps.className} tree`}
                        options={options}
                        isSearchable={false}
                        isTree
                        labelKey={labelKey || "label"}
                        valueKey={valueKey || "value"}
                        selectNode={selectNode}
                        component={ComboBox}
                    />
                );
            }
            case "Combo": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultComboBoxProps}
                        component={Combo}
                        isAsync={!!asyncApi} // not use
                        isCreatable={!!asyncApi} // not use
                    />
                );
            }
            case "Input": {
                return (
                    <Field
                        {...defaultProps}
                        clearable={clearable !== undefined ? clearable : !disabled}
                        maxLength={max_length}
                        component={Input}
                    />
                );
            }
            case "TextArea": {
                return <Field {...defaultProps} maxLength={maxLength || max_length} component={TextArea} />;
            }
            case "DatePicker": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaulDatePickerProps}
                        viewFormat={date_format || "DD/MM/YYYY"}
                        popperContainer={CalendarContainer}
                        component={DatePicker}
                        hasTimePicker={false}
                    />
                );
            }
            case "DatePickerTime": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaulDatePickerProps}
                        viewFormat={date_format || "DD/MM/YYYY HH:mm"}
                        popperContainer={CalendarContainer}
                        component={DatePicker}
                    />
                );
            }
            case "CheckBox": {
                return <Field {...defaultProps} className="checkbox-row" component={CheckBox} />;
            }
            case "RadioBox": {
                return <Field {...defaultProps} options={options} component={RadioBox} />;
            }
            case "NumberInput": {
                return <Field {...defaultProps} {...defaultNumberProps} component={NumberInput} />;
            }
            case "EditorHTML": {
                return <Field {...defaultProps} component={EditorHTML} />;
            }
            default: {
                console.log(`Widget type *** ${widget_type} *** not found`);
            }
        }
    };

    const renderBlock = (block, index) => {
        const { id, sname, name, icon, description, collapse, attrs, column_count, class_names } = block;

        let blockClassName = column_count ? `dynamic-form-block column-count-${column_count}` : "dynamic-form-block";

        if (class_names) {
            blockClassName += ` ${class_names}`;
        }

        if (withoutCollapse || (!name && !icon)) {
            return (
                <div key={id || sname || name || index} className={`${blockClassName} without-collapse`}>
                    {attrs.map(renderWidget)}
                </div>
            );
        }

        return (
            <CollapsedBlock
                key={id || sname || index}
                name={name}
                icon={icon}
                description={description}
                isOpen={!collapse}
                headerButton={headerButton}
                styles={{ zIndex: blocksQuantity - index }}
            >
                <div key={id || sname} className={blockClassName}>
                    {attrs.map(renderWidget)}
                    {children}
                </div>
            </CollapsedBlock>
        );
    };

    const renderWidget = (widget) => {
        const { sname: key, width_count, wrapper, class_names = "", isHidden } = widget;
        const widgetClassName = width_count
            ? `dynamic-form-widget width-count-${width_count}`
            : "dynamic-form-widget without-width-count";
        const Wrapper = (wrapper && wrapper.component) || null;
        const wrapperProps = (wrapper && wrapper.props) || {};

        const hiddenClassName = isHidden ? "hidden" : "";

        return (
            <div key={key} className={`${widgetClassName} ${class_names} ${hiddenClassName}`}>
                {Wrapper ? <Wrapper {...wrapperProps}>{getWidget(widget)}</Wrapper> : getWidget(widget)}
            </div>
        );
    };

    useEffect(() => {
        if (resetParams && resetParams.isReset) {
            reset();
            resetParams.callback();
        }
    }, [resetParams]);

    useEffect(() => {
        if (!isEqual(initialValues, initValues)) {
            initialize(initialValues);
            setInitValues(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        if (initialValues) initialize(initialValues);
        setIsInitialized(true);

        return () => {
            request.cancel();
        };
    }, []);

    return (
        <Form
            key={formName}
            className={`dynamic-form-hook ${className}`}
            onSubmit={handleSubmit(() => submitForm(formName))}
            style={style}
        >
            {isInitialized && formValues && structure.map(renderBlock)}
            {submitButton && (
                <Button
                    key={formName}
                    className={cx("btn btn-save-circle submit-button", { "without-collapse": withoutCollapse })}
                    onClick={handleAllSubmit}
                    text={t("Submit")}
                    disabled={!isFormDirty || !isEmpty(formErrors)}
                />
            )}
        </Form>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(reduxForm({ validate })(DynamicFormHook)));

DynamicFormHook.propTypes = {
    i18n: PropTypes.object,
    t: PropTypes.func.isRequired,
    active: PropTypes.string,
    structure: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    resetParams: PropTypes.object,
    formErrors: PropTypes.object,
    formName: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    withoutLiveSaver: PropTypes.bool,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    isFormDirty: PropTypes.bool,
    withoutCollapse: PropTypes.bool,
    submitButton: PropTypes.bool,
    headerButton: PropTypes.node,
    children: PropTypes.any,
};
