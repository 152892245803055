import React, { useState, useEffect } from "react";
import File from "./File";
import ReactSVG from "react-svg";
import cx from "classnames";

import "./styles.scss";

const FileInput = (props) => {
    const {
        input,
        maxFileSize,
        translate,
        extensions,
        handleUpload,
        multiple,
        label,
        input: { key, name },
        disabled,
        required,
        meta: { error },
        loader,
        onChange,
        files,
        handleLiveUpload,
        handleFileDelete,
    } = props;

    // const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState({});
    const [errors, setErrors] = useState([]);

    const getFileSizeMb = (file) => file.size / 1024 / 1024;

    const getFileExtension = (file) => file.name.split(".").pop();

    const getSizeErrors = (files) => {
        const size = translate.size || "File size";
        const maxSize = translate.maxSize || "Maximum file size";
        return files
            .filter((file) => getFileSizeMb(file) >= maxFileSize)
            .map((file) => {
                const fileSize = Math.floor(getFileSizeMb(file) * 100) / 100;
                return {
                    fileName: file.name,
                    message: `${size} ${fileSize}MB (${maxSize} ${maxFileSize}MB)`,
                };
            });
    };

    const getExtensionErrors = (files) => {
        // get accept as an array from props string (ex: "jpg , jpeg, mp3" => [jpg, jpeg, mp3] etc);
        // const accept = this.getAcceptExtensions(this.props.accept);
        const ext = translate.ext || "File extension";
        const extAllowed = translate.extAllowed || "Allowed file extensions";
        if (extensions.length > 0) {
            return files
                .filter((file) => !extensions.includes(getFileExtension(file)))
                .map((file) => {
                    return {
                        fileName: file.name,
                        message: `${ext}: ${getFileExtension(file)} (${extAllowed}: ${extensions.join(", ")})`,
                    };
                });
        }
        return [];
    };

    const getValidatedFiles = (files) => {
        return files.filter((file) => {
            if (maxFileSize && extensions && extensions.length > 0) {
                return getFileSizeMb(file) <= maxFileSize && extensions.includes(getFileExtension(file));
            }
            if (maxFileSize) {
                return getFileSizeMb(file) <= maxFileSize;
            }
            return true;
        });
    };

    const handleChange = (event) => {
        setErrors([]); // remove errors on change;
        const newFiles = [...event.currentTarget.files];

        // const errorsSize = this.getSizeErrors(files);
        // const errorsExtension = this.getExtensionErrors(files);
        const validatedFiles = getValidatedFiles(newFiles);

        if (handleLiveUpload && typeof handleLiveUpload === "function") {
            validatedFiles.map((file) => handleLiveUpload(file));
        } else {
            onChange([...files, ...validatedFiles]);
        }

        // setFiles([...files, ...validatedFiles]);

        // validatedFiles.map((file) => handleUpload(file));

        event.currentTarget.value = null; // clear input value (path) for same file upload purpose
    };

    const removeFile = ({ index, id }) => {
        if (handleFileDelete && typeof handleFileDelete === "function") {
            handleFileDelete(id);
        } else {
            onChange(files.filter((file, i) => i !== index));
        }
    };

    const renderSelectedFile = (data, index) => {
        const { name, loading, id } = data;
        return (
            <File
                key={index}
                name={name}
                loader={loader}
                onRemove={() => removeFile({ index, id })}
                disabled={disabled}
                id={id}
                loading={loading}
                // disabled // selected file always disabled (no possible to delete it)
                // loading // selected file always have loading state, until it loaded and moved into uploaded files
            />
        );
    };

    const renderError = (error) => {
        const { fileName, message } = error;
        return (
            <div className="error-wrapper">
                <div className="file-name" title={fileName}>
                    {fileName}
                </div>
                <div className="error-message">{message}</div>
            </div>
        );
    };

    // useEffect(() => {
    //     onChange(files);
    // }, [files]);

    return (
        <div className={cx("input-element file-input-wrapper")}>
            <div className="input-label">
                {label}
                {/* {required && <span className="required-field">*</span>} */}
            </div>
            <label>
                <input
                    key={key}
                    name={name}
                    type="file"
                    multiple={multiple}
                    accept={extensions ? [...extensions] : "*"}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <div className={cx("btn-save", { disabled })}>
                    <ReactSVG src={`./images/icons/files.svg`} /> {translate.button} {required && <span className="required-field">*</span>}
                </div>
            </label>

            {errors.length > 0 && <div className="errors">{errors.map(renderError)}</div>}

            <div className="files">{files.length > 0 && files.map(renderSelectedFile)}</div>
        </div>
    );
};

export default FileInput;
