import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ClickOutsideHolder extends Component {
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleClickOutside = e => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            // console.log('CLICK OUTSIDE');
            this.props.onClickOutside(e);
        }
    };

    render() {
        const { className, onWheel, top, left, children } = this.props;

        return (
            <div
                className={`out-sider ${className}`}
                ref={this.setWrapperRef}
                onWheel={onWheel}
                style={
                    top &&
                    left && {
                        position: 'absolute',
                        top,
                        left
                    }
                }
            >
                {children}
            </div>
        );
    }
}

ClickOutsideHolder.propTypes = {
    onClickOutside: PropTypes.func,
    children: PropTypes.node,
    top: PropTypes.number,
    left: PropTypes.number
};
