import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { sha3_512 } from "js-sha3";
import { isEmpty } from "lodash";
import { getFormSyncErrors, getFormValues, change, updateSyncErrors} from "redux-form";
import { withRouter } from "react-router-dom";

import { fetch } from "../../decorators";
import { Button, Loader } from "../../components";
import DynamicFormOrdering from "../../components/DynamicFormOrdering/DynamicForm";
import { saveUserData } from "../../reducers/user";
import { updateAuthStatus } from "../../reducers/auth";
import { fetchStaticBlocks } from "../../reducers/staticBlocks";

import { settings } from "../../config";

import { getLoginPasswordForm } from "./formConfig";

import "./styles.scss";

const FORM_NAME = "form-authorization";

const Authorization = props => {
    const {
        t,
        history,
        dataForm,
        errorsForm,
        saveUserData,
        change,
        updateAuthStatus,
        location,
        staticBlock,
        fetchStaticBlocks,
        authState,
        allBlocks,
        activeField,
        updateSyncErrors
    } = props;
    const [isLoading, setIsLoading] = useState(false);

    const isEverythingFilled = dataForm && dataForm.login && dataForm.password;

    const loginPasswordForm = useMemo(() =>
        getLoginPasswordForm(
            {
                staticBlock,
                t,
                allBlocks,
            },
            [staticBlock, t, allBlocks]
        )
    );

    const handleRegistry = () => {
        if (authState !== "none") {
            updateAuthStatus("none");
        }
        if (location && location.pathname !== "/registration") {
            history.push("/registration");
        }

        // refetch static blocks if user was on proceeded in registration process and clicked registration once more
        // or if user was not on registration route before
        if (authState !== "none" || (location && location.pathname !== "/registration")) {
            // refetch static blocks on registration click
            fetchStaticBlocks();
        }
    };

    const handleForgotPassword = () => {
        updateAuthStatus("none");
        history.push("/forgotPassword");
    };

    const handleSubmit = () => {
        const isReadyToSubmit = isEverythingFilled && isEmpty(errorsForm);
        if (isReadyToSubmit) {
            validateDomain();
        }
    };

    const validateDomain = () => {
        const slashLogin = dataForm.login.includes("\\");
        let domain;
        if (slashLogin) {
            [domain] = dataForm.login.split("\\");
        } else {
            [, domain] = dataForm.login.split("@");
        }

        fetch().send({
            api: { key: "domainValidation" },
            urlVars: { domain },
            onStart: () => setIsLoading(true),
            onSuccess: ({ success, result }) => {
                setIsLoading(false);
                if (success) {
                    const { scheme } = result;
                    validateLogin(scheme);
                }
            },
            onFailure: () => setIsLoading(false),
        });
    };

    const validateLogin = schemeId => {
        let pass = dataForm.password;
        if (schemeId === 1) {
            pass = sha3_512(`${dataForm.password}crm-newuidev.lab`);
        }
        const dataLogin = dataForm.login;
        const login = btoa(`${dataLogin}:${pass}`);
        const authorization = `Basic ${login}`;

        fetch().send({
            api: { key: "auth", method: "POST", schemeId, login },
            authorization,
            onStart: () => setIsLoading(true),
            onSuccess: ({ success, result }) => {
                if (success) {
                    saveUserData({ roles: result });
                    updateAuthStatus("auth");
                    if (settings.authorizationWidgetHideRoutes.includes(location.pathname)) {
                        history.push(settings.routeRedirect);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    updateSyncErrors(FORM_NAME,{
                        login: t("errorCredentials"),
                        password: t("errorCredentials"),
                    });
                }
            },
            onFailure: () => {
                setIsLoading(false);
                updateSyncErrors(FORM_NAME, {
                    login: t("errorCredentials"),
                    password: t("errorCredentials"),
                });
            },
        });
    };

    const handleKeyPress = useCallback(
        event => {
            const { keyCode } = event;
            if (keyCode === 13) {
                // submit only in case of activeField
                if (activeField) {
                    handleSubmit();
                }
            }
        },
        [dataForm, errorsForm, activeField]
    );

    useEffect(() => {
        document.addEventListener("keyup", handleKeyPress);
        // console.log("*** ADD EVENT ***");
        return () => {
            // console.log("*** REMOVE EVENT **");
            document.removeEventListener("keyup", handleKeyPress);
        };
    }, [dataForm, errorsForm, activeField]);

    return (
        <>
            <div className="login-wrapper">
                {isLoading && <Loader />}
                {!staticBlock.hidden && (
                    <DynamicFormOrdering
                        t={t}
                        formName={FORM_NAME}
                        forceInitialValues={loginPasswordForm.initialValues}
                        dynData={loginPasswordForm.dynData}
                        withoutLiveSaver
                        withoutCollapse
                        destroyOnUnmount={false}
                    />
                )}
                <div className="form-controls">
                    {!staticBlock.hidden && (
                        <Button
                            disabled={!isEverythingFilled || !isEmpty(errorsForm)}
                            className="btn-save-square enter"
                            onClick={handleSubmit}
                            text={t("enter")}
                        />
                    )}
                    <div className="forget">
                        <Link onClick={handleForgotPassword}>{t("forgotPassword")}</Link>
                    </div>
                    <Button
                        className="btn-cancel-square registration"
                        onClick={handleRegistry}
                        text={t("registration")}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ form, staticBlocks, auth }) => {
    const authStaticBlocks =
        staticBlocks && staticBlocks.blocks && staticBlocks.blocks.find(block => block.form === "authorization");

    const loginPasswordStaticBlock =
        authStaticBlocks &&
        authStaticBlocks.blocks &&
        authStaticBlocks.blocks.find(block => block.block === "authorization_accountCredentials");

    return {
        dataForm: getFormValues(FORM_NAME)({ form }),
        errorsForm: getFormSyncErrors(FORM_NAME)({ form }),
        staticBlock: loginPasswordStaticBlock,
        authState: auth.state,
        allBlocks: staticBlocks,
        activeField: form && form[FORM_NAME] && form[FORM_NAME].active
    };
};

export default withRouter(
    connect(mapStateToProps, {
        saveUserData,
        change,
        updateAuthStatus,
        fetchStaticBlocks,
        updateSyncErrors,
    })(withTranslation()(Authorization))
);

Authorization.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    saveUserData: PropTypes.func,
    change: PropTypes.func,
    dataForm: PropTypes.object,
};
