import React from 'react';
// import { editorToolbar } from 'constants/index';
import CKEditor from 'ckeditor4-react';

import './styles.scss';

const editorToolbar = {
    defaultLanguage: 'uk',
    language: 'uk',
    extraPlugins: 'colorbutton,colordialog,font',
    removeButtons: 'Anchor',
    extraAllowedContent: '*(*)',
    allowedContent: true,
    enterMode: 2,
    toolbarGroups: [
        {
            name: 'basicstyles',
            groups: ['basicstyles']
        },
        {
            name: 'links',
            groups: ['links']
        },
        {
            name: 'paragraph',
            groups: ['list', 'blocks']
        },
        {
            name: 'document',
            groups: ['mode']
        },
        {
            name: 'insert',
            groups: ['insert']
        },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' },
        {
            name: 'styles',
            groups: ['styles']
        }
    ]
};

// const uri = window.location.href.split(/\?|#/)[0];
// console.log({uri});
CKEditor.editorUrl = `./data/ckeditor/ckeditor.js`;
// CKEditor.editorUrl = `/ckeditor/ckeditor.js`;

function EditorHTML(props) {
    const { input, label, required, disabled } = props;
    
    function onChange(e) {
        input.onChange && input.onChange(e.editor.getData());
    }

    return (
        <div>
            <div className="draftEditorBox input-element">
                {label && (
                    <div className="input-label">
                        {label}
                        {required && <span className="required-field">*</span>}
                    </div>
                )}
                <CKEditor
                    onChange={onChange}
                    config={{ ...editorToolbar, height: 400, disabled }}
                    data={input.value}
                    name={input.name}
					readOnly={disabled}
                />
            </div>
        </div>
    );
}

export default EditorHTML;
