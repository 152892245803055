import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { closeMessageModal } from 'actions/ui';
// import styles from '..//modules/modal.module.scss';
import styles from '../assets/styles/modules/modal.m.scss'
// import styles from './modal.module.scss';
import InputField from '../InputField';
import cx from 'classnames';


const mapDispatchToProps = {
    closeMessageModal: () => {}
};

const MessageModal = props => {

    const {
        titleModal,
        contentModalText,
        primaryButton,
        primaryButtonText,
        secondaryButton,
        secondaryButtonText,
        onClickSecondaryButton,
        dangerButton,
        dangerButtonText,
        onClickDangerButton,
        contentModal,
        contentModalDesc,
        danger
    } = props;

    const [name, setName] = useState('');

    const onClickPrimary = () => {
        const { onClickPrimaryButton, contentModal } = props;

        if (contentModal) {
            onClickPrimaryButton(name);
            setName('');
        } else {
            onClickPrimaryButton();
        }
    };

    const onFilterChange = event => setName(event.target.value);

    return (
        <div className={styles.modalWrapper}>
            <div className={cx(styles.modalHeader, { [styles.modalHeaderDanger]: danger })}>
                <div className={styles.modalTitle}>
                    {danger && <i className='icon-error' />} {titleModal}
                </div>
            </div>
            <div className={styles.modalContent}>
                {contentModalText && <strong className='heading'>{contentModalText}</strong>}
                {contentModalDesc && <span className='desc'>{contentModalDesc}</span>}
                {contentModal ?
                    <InputField label='Назва фільтр' onChange={onFilterChange} />
                    : null
                }
                {
                    primaryButton &&
                    <button type='button' className='btn btn-save-square' onClick={onClickPrimary}>
                        {primaryButtonText}
                    </button>
                }
                {
                    dangerButton &&
                    <button type='button' className='btn btn-save-square' onClick={onClickDangerButton}>
                        {dangerButtonText}
                    </button>
                }
                {
                    secondaryButton &&
                    <button type='button' className='btn btn-cancel-square' onClick={onClickSecondaryButton}>
                        {secondaryButtonText}
                    </button>
                }
            </div>
        </div>
    );
};

MessageModal.protTypes = {
    closeMessageModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(MessageModal);
