import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { fetch, HashRouter } from "./decorators";
import { fetchPerson } from "./reducers/user";
import { fetchAuthStatus } from "./reducers/auth";
import { fetchStaticBlocks } from "./reducers/staticBlocks";
import app, { fetchRoutes, setTranslations } from "./reducers/app";
import { isEmpty } from "lodash";
import i18n from "./services/i18n";

import Notifications from "./containers/Notifications";
import AppHeader from "./containers/AppHeader";
import AppFooter from "./containers/AppFooter";
import AppContent from "./containers/AppContent";
import { Loader } from "./components";
// import { getEcpLib } from "./helpers";

import "normalize.css";

import "./styles/main.scss";
import "./styles/theme.scss";
import "./styles/loader.scss";
import "./styles/bootstrap-grid/bootstrap-grid.scss";

const TRIGGER_PERSON_FETCH_AUTH = ["auth", "reg"];
const TRIGGER_ROUTE_FETCH_AUTH = ["auth", "none"];

const REFETCH_TRANSLATIONS_TIMEOUT = 10000;
// const TRIGGER_ROUTES_FETCH_AUTH = ["auth"];

const App = props => {
    const {
        access,
        fetchAuthStatus,
        fetchPerson,
        fetchRoutes,
        authState,
        routes,
        activeLanguage,
        setTranslations,
        staticBlocks,
        fetchStaticBlocks,
        staticColorBlocks,
    } = props;
    const [isTranslationsLoading, setIsTranslationsLoading] = useState(false);
    // const [translationsReady, setTranslationsReady] = useState(false);

    const [languageChangeStamp, setLanguageChangeStamp] = useState(null); // key which is used to force-rerender App on language change to reinit translations

    // const isAppInitialised = authState && languageChangeStamp;
    const isAppInitialised = !isEmpty(staticBlocks) && authState && languageChangeStamp;

    const getApp = () => {
        return isAppInitialised ? (
            <Fragment key={languageChangeStamp}>
                <div className="page">
                    {isTranslationsLoading && <Loader />}
                    <AppHeader hasPermission={access} />
                    <AppContent />
                    <AppFooter hasPermission={access} />
                </div>
                <Notifications />
            </Fragment>
        ) : (
            <Loader />
        );
    };

    // fetch translations on lang change and afterwards add resource bundle and change a lang
    const fetchTranslations = lang => {
        setIsTranslationsLoading(true);
        fetch().send({
            api: { key: "translations", lang },
            onSuccess: ({ result, success }) => {
                if (success) {
                    setIsTranslationsLoading(false);
                    if (result && result && result.translation) {
                        i18n.addResourceBundle(lang, "translations", result.translation);
                        i18n.changeLanguage(lang);
                        setLanguageChangeStamp(new Date().getTime());
                        setTranslations(result.translation);
                        // update app title
                        const appTitle = `${i18n.t("appTitle")} - ${i18n.t("appDescription")}. ${i18n.t(
                            "appSubDescription"
                        )}`;
                        document.title = appTitle;
                    }
                }
            },
            onFailure: error => {
                setTimeout(() => fetchTranslations(lang), REFETCH_TRANSLATIONS_TIMEOUT);
                console.log(
                    `App::fetchTranslations: Error, trying to refetch in ${REFETCH_TRANSLATIONS_TIMEOUT} ms.`,
                    error
                );
            },
        });
    };

    useEffect(() => {
        fetchAuthStatus();
        if (isEmpty(staticBlocks)) {
            fetchStaticBlocks();
        }
    }, []);

    useEffect(() => {
        if (activeLanguage) {
            fetchTranslations(activeLanguage);
        }
    }, [activeLanguage]);

    useEffect(() => {
        if (TRIGGER_PERSON_FETCH_AUTH.includes(authState)) {
            fetchPerson();
        }
        if (TRIGGER_ROUTE_FETCH_AUTH.includes(authState)) {
            fetchRoutes(1);
        }
    }, [authState]);

    useEffect(() => {
        if (staticColorBlocks && staticColorBlocks.blocks) {
            const block = staticColorBlocks.blocks.find(block => block.block === "colors");
            if (block && block.attrs) {
                // convert into colors array [{}]
                block.attrs.forEach(color => {
                    Object.keys(color).forEach(key => {
                        document.documentElement.style.setProperty(key, color[key]);
                    });
                });
            }
        }
    }, [staticColorBlocks]);

    return (
        <HashRouter>
            <div className="page-wrapper">{getApp()}</div>
        </HashRouter>
    );
};

const mapStateToProps = ({ user, auth, staticBlocks, app: { language } }) => {
    const staticColorBlocks =
        staticBlocks && staticBlocks.blocks && staticBlocks.blocks.find(block => block.form === "global_colors");
    return {
        user,
        access: user.roles,
        authState: auth.state,
        routes: app.routes,
        activeLanguage: language,
        staticBlocks,
        staticColorBlocks,
    };
};

const mapDispatchToProps = { fetchAuthStatus, fetchRoutes, fetchPerson, setTranslations, fetchStaticBlocks };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));

App.propTypes = {
    access: PropTypes.string,
    saveUserData: PropTypes.func,
};
