import React from 'react';
import PropTypes from 'prop-types';

import ErrorField from './ErrorField';
import LiveSaver from './LiveSaver';

const CheckBoxToggle = props => {
    const { label, input, meta, liveSaver, ...rest } = props;
    const id = props.id || `checkbox_${input ? input.name : props.name}`;

    const toogle = (
        <div className='input-element'>
            <div className='container-checkbox-toggle'>
                <input
                    id={id}
                    checked={Boolean(input ? input.value : props.value)}
                    {...input}
                    {...rest}
                    type='checkbox'
                />
                <label className='checkbox-toggle' htmlFor={id}>
                    <span className='checkbox-toggle-slider round' />
                </label>
                <div className='checkbox-toggle-title'>{label}</div>
            </div>
            <ErrorField meta={meta} />
        </div>
    );

    return liveSaver ?
        <LiveSaver liveSaver={liveSaver}>
            {toogle}
        </LiveSaver> :
        toogle;
};

CheckBoxToggle.displayName = 'CheckBoxToggle';

CheckBoxToggle.propTypes = {
    label: PropTypes.string
};

CheckBoxToggle.defaultProps = {
    label: ''
};

export default CheckBoxToggle;
