import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isObject, getIdKey } from './helpers';

const DataManager = OriginalComponent => {
    class DataManagerComponent extends Component {
        state = {
            data: [],
            closedNodes: [],
            isToggledVisibility: false
        };

        componentDidMount() {
            const { params } = this.props;
            if (params.treeGrid) {
                const { data: gridStateData, ...restGridState } = this.getTreeGridState(this.props, this.state);
                this.setState({
                    data: gridStateData,
                    ...restGridState
                });
            }
        }

        componentDidUpdate(prevProps) {
            const { data, params } = this.props;
            const { isToggledVisibility } = this.state;
            if (params.treeGrid && (prevProps.data !== data || isToggledVisibility)) {
                const { data: gridStateData, ...restGridState } = this.getTreeGridState(this.props, this.state);
                this.setState({
                    data: gridStateData,
                    ...restGridState
                });
            }
        }

        getTreeGridState = (props, state) => {
            const { data: treeData, params } = props;
            const { closedNodes: stateClosedNodes } = state;
            if (!treeData) {
                return { data: [], isToggledVisibility: false };
            }
            const idKey = getIdKey(params);
            const closedLevel = params.treeGrid && params.treeGrid.closedLevel;
            const prevClosedNodes = stateClosedNodes && stateClosedNodes.length ? [...stateClosedNodes] : [];
            const { arData, closedNodes } = this.convertDataTreeToArray({ treeData, state, idKey, closedLevel, closedNodes: prevClosedNodes });
            return {
                data: arData,
                isToggledVisibility: false,
                closedNodes
            };
        };

        // bad naming
        convertDataTreeToArray = props => {
            const { treeData, arData = [], closedNodes = [], ...restProps } = props;
            if (treeData.map) {
                // if treeData is array
                treeData.forEach((item, itemIndex, arr) =>
                    this.addItemArrayFromTreeData({
                        data: item, arData, closedNodes,
                        itemIndex, arLength: arr.length,
                        ...restProps
                    })
                );
            } else if (isObject(treeData)) {
                // if data is object ( first level of data by default for now )
                this.addItemArrayFromTreeData({ data: treeData, arData, closedNodes, ...restProps });
            }

            return { arData, closedNodes };
        };

        // bad naming
        addItemArrayFromTreeData = props => {
            const {
                data, state, idKey, closedLevel,
                arData, closedNodes,
                level = 0, delimiter = {},
                itemIndex = 0, arLength = 1
            } = props;
            const { children, ...restData } = data;
            const isExistInClosedNodes = closedNodes.some(id => id === data[idKey]);
            const isClosedByParams = closedLevel === level && !state.isToggledVisibility;
            const isClosed = isExistInClosedNodes || isClosedByParams;
            const hasChildren = Boolean(children && Array.isArray(children) && children.length);
            const item = {
                ...restData,
                level,
                opened: !isClosed,
                tree: true, // mb dont need the props
                firstChild: itemIndex === 0,
                lastChild: itemIndex === arLength - 1,
                children: hasChildren
            };
            if (level > 0) {
                item.delimiter = delimiter;
            }

            arData.push(item);
            if (hasChildren && isClosed && !isExistInClosedNodes) {
                closedNodes.push(item[idKey]);
            }
            if (hasChildren && !isClosed) {
                const newDelimiter = { ...delimiter, [level]: !(itemIndex === arLength - 1) };
                this.convertDataTreeToArray({
                    treeData: children, state, idKey, closedLevel,
                    arData, closedNodes,
                    level: level + 1, delimiter: newDelimiter
                });
            }
        };

        toggleRowVisibility = (e, node) => {
            e.stopPropagation();
            this.setState({
                closedNodes: node.opened ? this.addClosedNode(node) : this.removeClosedNode(node),
                isToggledVisibility: true
            });
        };

        removeClosedNode = node => this.state.closedNodes.filter(item => item !== node[getIdKey(this.props.params)]);

        addClosedNode = node => [...this.state.closedNodes, node[getIdKey(this.props.params)]];

        render() {
            const { data: stateData } = this.state;
            const { data: propsData, ...restProps } = this.props;
            if (restProps.params.treeGrid) {
                return (
                    <OriginalComponent {...restProps} data={stateData} toggleRowVisibility={this.toggleRowVisibility} />
                );
            }
            return <OriginalComponent {...this.props} />;
        }
    }

    DataManagerComponent.propTypes = {
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    };
    DataManagerComponent.defaultProps = {
        data: []
    };

    return DataManagerComponent;
};

DataManager.propTypes = {};
DataManager.defaultProps = {};

export default DataManager;
