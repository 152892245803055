import React from 'react';
import PropTypes from 'prop-types';

import GridHeadItem from './GridHeadItem';
import GridPopup from './GridPopup';

import { DESC, ASC, CLASS_WIDTHS_SET } from './gridConfig';

const initialDnD = {
    dragged: {},
    draggedOver: {},
    dragCanDrop: true
};
class GridHead extends React.PureComponent {
    state = {
        ...initialDnD
    };

    handleSaveChangeHiddenColumn = fields => {
        this.props.params.onChangeFields(fields);
        // this.restartHeaderResizeObserver();
    };

    // Drag&Drop columns methods:

    handleDragStart = event => {
        const { params } = this.props;
        const columnName = this.getColumnName(event, 'onDragStart');
        if (!params.fields[columnName].noDnD) {
            this.setState({ dragged: this.getByColumnName(columnName) });
        }
    };

    handleDragEnter = event => {
        const { dragged, draggedOver: currentDraggedOver } = this.state;
        // dragging NOT a th, so do nothing
        if (!dragged.columnName) return;
        const columnName = this.getColumnName(event, 'onDragEnter');
        if (currentDraggedOver.columnName && columnName === currentDraggedOver.columnName) {
            return;
        }

        const draggedOver = this.getByColumnName(columnName);
        const dragCanDrop = this.handleDragCanDrop(draggedOver);
        this.setState({ draggedOver, dragCanDrop });
    };

    getColumnName = (event, handler) => {
        const target = this.getClosestTh(event);
        const columnName = target.dataset.name;
        if (!columnName) {
            console.warn('current target is', target);
            throw new Error(`target must have data-name in ${handler} handler, data-name is ${columnName}`);
        }
        return columnName;
    };

    handleDragCanDrop = draggedOver => {
        const { dragged } = this.state;
        const { thDnD } = this.props.params;
        if (thDnD && thDnD.canDrop) {
            return thDnD.canDrop(dragged, draggedOver);
        }
        return dragged.columnName !== draggedOver.columnName;
    };

    handleDragOver = event => {
        event.persist();
        if (event.preventDefault) {
            event.preventDefault(); // Necessary. Allows us to drop.
        }
        const { dragCanDrop } = this.state;
        event.dataTransfer.dropEffect = dragCanDrop ? 'move' : 'none';
    };

    handleDragEnd = () => {
        const { dragged, draggedOver, dragCanDrop } = this.state;
        if (dragCanDrop) {
            const { fields, onChangeFields } = this.props.params;
            const fieldsKeys = Object.keys(fields);
            const fromIndex = fieldsKeys.findIndex(key => key === dragged.columnName);
            const toIndex = fieldsKeys.findIndex(key => key === draggedOver.columnName);
            if (fromIndex !== toIndex) {
                const movedItem = fieldsKeys.splice(fromIndex, 1)[0];
                fieldsKeys.splice(toIndex, 0, movedItem);

                const newFields = fieldsKeys.reduce((acc, key) => {
                    acc[key] = fields[key];
                    return acc;
                }, {});

                onChangeFields(newFields);
            }
        }
        this.setState(initialDnD);
    };

    getClosestTh = event => (event.target.tagName === 'TH' ? event.target : event.target.closest('th'));

    getByColumnName = name => ({ ...this.props.params.fields[name], columnName: name });

    // Sort columns methods.

    handleSort = (field, item) => {
        const { sort: { handleSort, order, field: sortedField }, data } = this.props;
        if (item.noSorted) return;
        if (!data || !data.length) return;
        if (field !== sortedField) return handleSort({ field, order: ASC });

        return handleSort({
            field,
            order: !order ? ASC : order === ASC ? DESC : ''
        });
    };

    // Resize columns methods:
    handleMaximaze = columnName => {
        const { params: { onChangeFields, fields } } = this.props;
        const newFields = { ...fields };

        // TODO FIX MUTATION
        newFields[columnName].classWidth = String(CLASS_WIDTHS_SET.length - 1);
        onChangeFields(newFields);
    };

    render() {
        const {
            params,
            groups,
            sort,
            lastField,
            resizedColumnName,
            handleStartResize,
            forceWidth,
            fixedWidths,
            columnsMenu,
            gridId
        } = this.props;
        const { draggedOver } = this.state;
        const { withoutColumnsMenu } = params.options;

        return (
            <table className="table-grid grid-fixed-header" cellSpacing="0">
                <thead>
                    <tr>
                        {groups && (
                            <th className="grid-cell-checkbox" data-name="_checkbox">
                                <input type="checkbox" disabled />
                            </th>
                        )}
                        {Object.keys(params.fields).map((field, key) => (
                            <GridHeadItem
                                key={field || key}
                                field={field}
                                lastField={lastField}
                                item={params.fields[field]}
                                t={params.t}
                                sort={sort}
                                onDragStart={this.handleDragStart}
                                onDragEnter={this.handleDragEnter}
                                onDragOver={this.handleDragOver}
                                onDragEnd={this.handleDragEnd}
                                dropHighlight={field === draggedOver.columnName}
                                onStartResize={handleStartResize}
                                forceWidth={field === resizedColumnName ? forceWidth : null}
                                fixedWidth={fixedWidths ? fixedWidths[field] : null}
                                resizing={!!resizedColumnName}
                                onSort={this.handleSort}
                                onMaximaze={this.handleMaximaze}
                            />
                        ))}
                        {!withoutColumnsMenu && (
                            <th className="grid-cell-hidemenu" data-name="columns-hide-menu">
                                <GridPopup
                                    t={params.t}
                                    fields={params.fields}
                                    columnsMenu={columnsMenu}
                                    handleSaveChanges={this.handleSaveChangeHiddenColumn}
                                    gridId={gridId}
                                />
                            </th>
                        )}
                    </tr>
                </thead>
            </table>
        );
    }
}

GridHead.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object,
    groups: PropTypes.object,
    sort: PropTypes.object,
    lastField: PropTypes.string,
    fixedWidths: PropTypes.object,
    resizedColumnName: PropTypes.string,
    forceWidth: PropTypes.number,
    handleStartResize: PropTypes.func,
    columnsMenu: PropTypes.object,
    gridId: PropTypes.number
};
GridHead.defaultProps = {
    data: [],
    params: {},
    sort: {}
};

export default GridHead;
