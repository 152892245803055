import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';

import { KEY_CODES } from './helpers';

class CustomInput extends React.Component {
    handleChange = (ev) => {
        const { value } = ev.target;
        this.props.onChangeRaw(value);
    };

    getIcons = () => {
        // const { disabled } = this.props;
        // if (disabled) {
        //     return null;
        // }
        return (
            <ReactSVG
                tabIndex="0"
                className="calendar-icon"
                src={`./images/icons/calendar.svg`}
                onClick={this.handleOpenCalendar}
                // onKeyUp={this.handleIconsEnterKeyPush(this.handleOpenCalendar)}
            />
        );
    };

    handleOpenCalendar = () => this.props.onClick();

    // getClearBtn = () => {
    //     if (!this.props.isClearable) {
    //         return null;
    //     }
    //     return (
    //         <i
    //             className="icon-close"
    //             tabIndex="0"
    //             onClick={this.handleResetValue}
    //             onKeyUp={this.handleIconsEnterKeyPush(this.handleResetValue)}
    //         />
    //     );
    // };

    // handleResetValue = () => this.props.onChangeRaw(null);

    // handleIconsEnterKeyPush = (fn) => ({ keyCode }) => {
    //     if (keyCode !== KEY_CODES.ENTER || typeof fn !== 'function') {
    //         return;
    //     }
    //     fn();
    // };

    render() {
        const { onChange, onChangeRaw, isClearable, ...restProps } = this.props;
        return (
            <div className="datepicker-field-wrap">
                <input type="text" {...restProps} onChange={this.handleChange} />
                {this.getIcons()}
            </div>
        );
    }
}

CustomInput.propTypes = {};
CustomInput.defaultProps = {};

export default CustomInput;
