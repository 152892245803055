import settings from './settings';

export default {
    responseType: 'json',
    responseEncoding: 'utf8',
    baseURL: settings.requests.baseURL,
    timeout: settings.requests.timeout,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
};
