import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWindow from '../ModalWindow';

class Confirm extends Component {
    constructor(props) {
        super();
        // todo need to do something with modalStyles
        const modalStyles = { ...props.modalStyles };
        if (props.modalCors) {
            modalStyles.position = 'fixed';
            modalStyles.left = this.checkWindowWidth(props.modalCors[0]);
            modalStyles.top = this.checkWindowHeight(props.modalCors[1]);
        }
        this.state = { modalStyles };
    }

    checkWindowHeight = y => {
        const intViewportHeight = window.innerHeight;
        if (intViewportHeight - y < 75) {
            return y - 150;
        }
        return y - 75;
    };

    checkWindowWidth = x => {
        const difX = document.body.clientWidth - x;
        let resX;

        if (x < 140) {
            resX = 0;
        } else if (difX > 280) {
            resX = 140;
        } else if (difX > 0) {
            resX = 280 - difX;
        }

        return x - resX;
    };

    handleAction = () => {
        this.props.action();
        this.props.cancel();
    };

    render() {
        const { cancel, message, confirmText, cancelText } = this.props;
        const { modalStyles } = this.state;

        return (
            <ModalWindow safetyMode={false} onClose={cancel} modalStyles={modalStyles}>
                <div className="confirm">
                    <div className="logo">{message}</div>
                    <div className="control-buttons">
                        <button className="button delete" onClick={this.handleAction}>
                            <i className="icon-check" />
                            {confirmText}
                        </button>
                        <button className="button cancel noMargin" onClick={cancel}>
                            <i className="icon-close" />
                            {cancelText}
                        </button>
                    </div>
                </div>
            </ModalWindow>
        );
    }
}

Confirm.propTypes = {
    modalStyles: PropTypes.object,
    modalCors: PropTypes.array,
    action: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    message: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string
};

Confirm.defaultProps = {
    modalStyles: { width: 280, padding: 0 },
    modalCors: null,
    message: 'Are you sure?',
    confirmText: 'yes',
    cancelText: 'cancel'
};

export default Confirm;
