import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactSVG from "react-svg";

import "./styles.scss";

const Breadcrumbs = (props) => {
    const { location, t, storeTranslation, customBreadcrumbs } = props;

    const breadcrumbs = useMemo(() => {
        if (customBreadcrumbs) {
            return customBreadcrumbs;
        }
        if (!location || !location.pathname) {
            return [];
        }
        let { pathname } = location;
        // knowledgeBase handle case with leaf/folder in path /1/ /0/
        const routesKeys = pathname.split("/").filter((route) => route);
        // if (routesKeys[0] === "knowledgeBase") {
        //     routesKeys.splice(1, 1);
        //     pathname = "/" + routesKeys.join("/");
        // }
        const routes = routesKeys.map((route, index) => {
            const path = `/${routesKeys.filter((_, i) => index >= i).join("/")}`;
            let translation = t(`routes.${route}`);
            if (translation === `routes.${route}`) {
                translation = storeTranslation && storeTranslation[route] ? storeTranslation[route] : route;
            }
            return {
                path,
                name: translation,
                active: path === pathname,
            };
        });
        return routes;
    }, [location, storeTranslation, customBreadcrumbs]);

    const lastRouteIndex = useMemo(() => {
        return breadcrumbs.length - 1;
    }, [breadcrumbs]);

    // todo: add support for custom breadcrumbs from portal
    const renderBreadcrumb = (crumb, index) => {
        const { name, path, active } = crumb;
        return (
            <Fragment key={path}>
                {active ? (
                    <div className="breadcrumb active" title={name}>
                        {name}
                    </div>
                ) : (
                    <Link className="breadcrumb" to={path} title={name}>
                        {name}
                    </Link>
                )}
                {index !== lastRouteIndex && (
                    <ReactSVG className="breadcrumb-arrow" src={"./images/icons/breadcrumb-arrow.svg"} />
                )}
            </Fragment>
        );
    };

    return <div className="breadcrumbs">{breadcrumbs.map(renderBreadcrumb)}</div>;
};

const mapStateToProps = ({ breadcrumbs }) => ({
    storeTranslation: breadcrumbs.translation,
    customBreadcrumbs: breadcrumbs.customBreadcrumbs
});

export default withRouter(withTranslation()(connect(mapStateToProps, null)(Breadcrumbs)));
