import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Pagination from '../Pagination';
import { getTKey } from './tKeys';

export default class Footer extends Component {
    getPagination = () => {
        const { pagination, t, tKeys, classNames } = this.props.params;

        if (!pagination) return null;

        if (pagination.component) return pagination.component;

        if (pagination.data && pagination.data.total) {
            if (classNames && classNames.includes('elastic') && pagination.data.total <= pagination.data.limit) {
                return null;
            }
            const props = {
                ...pagination.data,
                onChange: pagination.onChange,
                translatedText: { of: t(getTKey(tKeys, 'paginationOf')) }
            };
            return <Pagination {...props} />;
        }
    };

    getControls = () => {
        const { footer } = this.props.params;
        if (!footer || (!footer.submit && !footer.cancel)) {
            return null;
        }

        return (
            <div className="grid-footer-controls">
                {this.getCancel()}
                {this.getSubmit()}
            </div>
        );
    };

    getCancel = () => {
        const { t, tKeys, footer, cancelL10n = 'btnFooterCancel' } = this.props.params;
        if (!footer.cancel) return null;
        return (
            <button type="button" className="button cancel" onClick={footer.cancel}>
                <i className="icon-close" />
                {t(getTKey(tKeys, cancelL10n))}
            </button>
        );
    };

    getSubmit = () => {
        const { selected, params: { t, tKeys, footer } } = this.props;
        if (!footer.submit) return null;
        if (selected) {
            return (
                <button type="button" className="button" onClick={footer.submit(selected)}>
                    <i className="icon-check" />
                    {t(getTKey(tKeys, 'btnFooterSelect'))}
                </button>
            );
        }
        return (
            <button type="button" className="button disabled">
                <i className="icon-check" />
                {t(getTKey(tKeys, 'btnFooterSelect'))}
            </button>
        );
    };

    render() {
        const pagination = this.getPagination();
        const controls = this.getControls();
        if (!pagination && !controls) {
            return null;
        }

        return (
            <div className="grid-footer">
                {pagination}
                {controls}
            </div>
        );
    }
}

Footer.propTypes = {
    params: PropTypes.object,
    selected: PropTypes.object
};
