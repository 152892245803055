import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { removeNotification } from '../../reducers/app';
import Notification from './Notification';

import './styles.scss';

const iconsConfig = {
    success: 'icon-notify-ok',
    error: 'icon-notify-err'
};

class NotificationManager extends React.PureComponent {
    render() {
        const { notifications } = this.props;
        if (!notifications.length) {
            return null;
        }
        return (
            <div className="notification-manager">
                {notifications.map(({ id, text, detail, className }) => (
                    <Notification key={id} id={id} handleClose={this.closeNotification} className={className}>
                        <div className="notification-message-header">
                            {iconsConfig[className] && <i className={iconsConfig[className]} />}
                            {this.getTranslatedText(text)}
                        </div>
                        {this.getDetail(detail)}
                    </Notification>
                ))}
            </div>
        );
    }

    getTranslatedText = text => {
        return Array.isArray(text) ? text.map(str => this.props.t(str)).join(' ') : this.props.t(String(text));
    };

    getDetail = detail => {
        if (typeof detail !== 'string' || !detail) return null;
        return <span className="detail-message">{detail}</span>;
    };

    closeNotification = notificationId => this.props.removeNotification(notificationId);
}

NotificationManager.defaultProps = {
    notifications: []
};

NotificationManager.propTypes = {
    notifications: PropTypes.array,
    removeNotification: PropTypes.func
};

export default withTranslation()(
    connect(
        state => ({
            notifications: state.app.notifications
        }),
        { removeNotification }
    )(NotificationManager)
);
