import React, { Component } from 'react';

let appData = {
    localization: {}
};

class Base extends Component {
    tr(key) {
        return appData['localization'][key] || key;
    }

    addField(field) {
        if (field.val) {
            return(
                <div className={field.class || 'text'}
                     key={field.key || ''} title={field.title || ''}
                >
                    {`${field.title || ''}${field.val}`}
                </div>
            );
        }
    }

    setAppData(key, data) {
        appData[key] = data;
    }

    getAppData(key) {
        return appData[key];
    }

    setDateFormat(val) {
        const date = new Date(val);

        return `${this.twin(date.getDate())}.${this.twin(date.getMonth())}.
                ${date.getFullYear()} ${this.twin(date.getHours())}:
                ${this.twin(date.getMinutes())}`;
    }

    twin(str) {
        str = str.toString();
        return str.length > 1 ? str : '0' + str;
    }
}

export default Base;