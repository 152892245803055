import i18n from 'i18next';
// import ukTranslations from '../locales/uk/translations.json';
// import enTranslations from '../locales/en/translations.json';
// import ruTranslations from '../locales/ru/translations.json';
// import { initReactI18next } from 'react-i18next';

// i18n.use(initReactI18next)
// 	.init({
// 	lng: 'uk',
// 	defaultNS: 'translations',
// 	ns: ['translations', 'dictionary'],
// 	fallbackLng: 'uk',
// 	interpolation: {
// 		escapeValue: false,
// 		formatSeparator: ',',
// 	},
// 	resources: {
// 		// uk: { translations: ukTranslations },
// 		// ru: { translations: ruTranslations },
// 		// en: { translations: enTranslations },
// 	},
// 	react: {
// 		wait: true
// 	}
// });

// i18n.on('languageChanged', lang => console.log('New Language: %s', lang));
// i18n.on('added', (lang, namespace) => console.log('Language: %s, Namespace: %s', lang, namespace));

// export function getLanguage () {
// 	if (i18n.language === 'uk') return 'ua';
// 	return i18n.language
// }

export default i18n;
