import React, { useState, useEffect, useRef } from 'react';
import { isObject, isEqual } from 'lodash';

import Loader from './loader';

/*
   liveSaver props:
        * value => current value of field
        * change => function for changing field value by key (used for resetField action), currently from redux-form
        * handleLiveSubmit => function for handling save request, incoming args:
            - key => field key
            - responseCallback => callBack for request response (handleResponse function)
        * loader => field loader component
        * autoSave => boolean for saving field without confirming action on isDirty flag (blur, enter, button click)
        * enterDisabled => boolean for removing submit on enter key (textarea for example)
*/

const LiveSaver = props => {
    const {
        liveSaver: {
            fieldKey,
            initValue,
            value,
            change,
            handleLiveSubmit,
            autoSave,
            enterDisabled,
            active,
            label
        },
        children,
        blurAction = true
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [initialValue, setInitialValue] = useState(initValue);

    const liveRef = useRef(null);

    const classButtons = `live-saver-buttons ${!label ? 'top' : ''} ${isDirty || isAlert ? 'active' : ''}`;

    const handleResponse = success => {
        // update initial value if response succeeded
        if (success) {
            setInitialValue(value);
            setIsDirty(false);
        }
        setIsLoading(false);
    };

    const submitField = forceSubmit => {
        if (checkExactly(value, initialValue)) return;

        if (isDirty || forceSubmit) {
            setIsLoading(true);
            handleLiveSubmit(fieldKey, handleResponse);
        }
    };

    const resetField = () => {
        setIsDirty(false);
        setIsAlert(false);
        change([fieldKey], initialValue);
    };

    const handleKeyUp = e => {
        if (e.keyCode === 13 && !enterDisabled) {
            submitField(); // ENTER
        }
        if (e.keyCode === 27) resetField(); // ESCAPE
    };

    const handleBlur = e => {
        if (!blurAction) return;

        if (e.relatedTarget && e.relatedTarget.className.indexOf(fieldKey) !== -1) return;

        if (
            e.relatedTarget &&
            e.relatedTarget.parentElement &&
            e.relatedTarget.parentElement.classList[0] === 'live-saver-buttons'
        )
            return;
        if (
            e.target &&
            e.target.parentElement &&
            e.target.previousElementSibling &&
            e.target.parentElement.classList[0] === 'live-saver-buttons'
        )
            return;

        submitField(true);
    };

    const checkExactly = (val1, val2) => {
        return isObject(val1) && isObject(val2) ? val1.value === val2.value : isEqual(val1, val2);
    };

    useEffect(() => {
        if (value === '' && initialValue === null) return setIsDirty(false);
        return setIsDirty(!checkExactly(initialValue, value));
    }, [value]);

    useEffect(() => {
        // handle FileInput, Rank and Radio autosave without buttons
        if (isDirty && autoSave) {
            setIsDirty(false);
            submitField(true);
        }
    }, [isDirty, autoSave]);

    useEffect(() => {
        if (!checkExactly(initialValue, initValue)) {
            if (!checkExactly(initialValue, value)) {
                if (active === fieldKey) {
                    setIsDirty(false);
                    // setIsAlert(true);
                } else {
                    setIsAlert(false);
                    setIsLoading(true);
                    change([fieldKey], initValue);
                    setTimeout(() => setIsLoading(false), 400);
                }
            } else {
                setIsDirty(false);
                setIsAlert(false);
            }
            setInitialValue(initValue);
        }
    }, [initValue]);

    return (
        <div className="live-saver" onKeyUp={handleKeyUp} onBlur={handleBlur} key={`${fieldKey}_saver`} ref={liveRef}>
            {isLoading && <Loader />}
            <div className={classButtons}>
                {isDirty && (
                    <button type="button" className={`btn-save ${fieldKey}`} onClick={submitField}>
                        <i className="icon icon-check" />
                    </button>
                )}
                {isAlert && (
                    <button type="button" className={`btn-save red ${fieldKey}`} title="New value have come">
                        <i className="icon icon-info" />
                    </button>
                )}
                <button type="button" className={`btn-save ${fieldKey} reset`} onClick={resetField}>
                    <i className="icon icon-times" />
                </button>
            </div>
            {children}
        </div>
    );
};

export default LiveSaver;
