import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetch } from '../../decorators';

const IpList = ({ t, data, fieldIp = 'value', getIPs }) => {
    const inputRef = useRef(null);
    const [isIpFetching, setIsIpFetching] = useState(false);

    const renderBlock = val => {
        if (val.stateCode === 'deleted') {
            return null;
        }

        const handleClick = () => {
            if (['active', 'new'].includes(val.stateCode)) {
                const list = [...data];
                getIPs(list.filter(v => v.id !== val.id));
            }
        };

        return (
            <div className="row-buttom" key={val.id}>
                <div className="form-wrapper input-element">
                    <input
                        title={t(val.stateName)}
                        className="input-field-wrap input-field select__control"
                        value={val[fieldIp]}
                        disabled
                    />
                </div>
                <button type="button" className={`btn btn-delete-square ${val.stateCode}`} onClick={handleClick}>
                    {t(['active', 'new'].includes(val.stateCode) ? 'Видалити' : 'В обробці...')}
                </button>
            </div>
        );
    };

    const handleAdd = () => {
        fetch().send({
            api: { key: 'validateIpAddress', host: inputRef.current.value },
            onStart: () => setIsIpFetching(true),
            onSuccess: ({ success }) => {
                setIsIpFetching(false);
                if (success) {
                    const list = [...data];
                    list.unshift({
                        id: null,
                        name: 'IP адреса',
                        value: inputRef.current.value,
                        typeCode: 'ipaddress',
                        stateCode: 'new',
                        stateName: 'Новий'
                    });
                    getIPs(list);
                    inputRef.current.value = '';
                }
            },
            onFailure: () => setIsIpFetching(false)
        });
    };

    return (
        <div className="list-block">
            {t('IPList')}:
            <div className="row-buttom add">
                <div className="form-wrapper input-element">
                    <input
                        className="input-field-wrap input-field select__control"
                        ref={inputRef}
                        placeholder={t('typeNewIP')}
                    />
                </div>
                <button type="button" className="btn btn-save-square" disabled={isIpFetching} onClick={handleAdd}>
                    {t('add')}
                </button>
            </div>
            {data.map(renderBlock)}
        </div>
    );
};

IpList.propTypes = {
    t: PropTypes.func,
    data: PropTypes.array,
    getIPs: PropTypes.func
};

export default IpList;
