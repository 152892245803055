export const MINIMUM_RESIZE_CELL_WIDTH = 40;
export const RESIZE_TIMEOUT = 200;
export const DRAG_TIMEOUT = 150;
export const CLASS_WIDTHS_SET = [[80, 80], [160, 160], [240, 240], [320, 320], [400, 400]];
export const DESC = 'desc';
export const ASC = 'asc';
export const UP = 'up';
export const DOWN = 'down';
export const ADD_FILTER_DATA_TIMEOUT = 400;
export const FILTER_INPUT_ID = 'grid-header-filter';
