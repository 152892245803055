import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const Loader = ({ withContainer, loaderClass }) => {
    const LoaderContent = (
        <div className="custom-loader-overlay">
            <svg className={cx('custom-loader', loaderClass)} viewBox="0 0 50 50">
                <circle className="custom-circle" cx="25" cy="25" r="20" fill="none" strokeWidth="3.6" />
            </svg>
        </div>
    );
    return withContainer ? <div className="custom-loader-container">{LoaderContent}</div> : LoaderContent;
};

Loader.defaultProps = {
    withContainer: false,
    loaderClass: ''
};

Loader.propTypes = {
    withContainer: PropTypes.bool,
    loaderClass: PropTypes.string
};

export default Loader;
