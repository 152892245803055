import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Loading from '../Loader';
import GridCell from './GridCell';

import { getTKey } from './tKeys';
import { getIdKey } from './helpers';

const initialDnD = {
    dragged: {},
    draggedOver: {},
    dragCanDrop: false
};

class GridBody extends React.PureComponent {
    state = {
        ...initialDnD
    };

    handleSelectItem = item => e => this.props.selectItem(item, e);

    preventClickToCheckbox = event => {
        // If you do not stop the ascent of the event, it will call the function SelectItem().
        event.stopPropagation();
    };

    // Drag&Drop methods:

    getRowId = row => {
        if (!row) return;
        const idKey = getIdKey(this.props.params);
        return row[idKey];
    };

    getClosestTr = event => event.target.closest('tr');

    findRow = id => this.props.data.find(item => this.getRowId(item) === id);

    handleRowDragStart = event => {
        const tr = this.getClosestTr(event);
        // _id for internal condition
        const _id = Number(tr.dataset.id);
        const dragged = this.findRow(_id);
        this.setState({ dragged: { ...dragged, _id } });
    };

    handleRowDragEnter = event => {
        const { dragged, draggedOver: stateOver } = this.state;
        // dragging NOT a row, so do nothing
        if (!dragged._id) return;
        const overTr = this.getClosestTr(event);
        const overTrId = Number(overTr.dataset.id);
        if (this.getRowId(stateOver) === overTrId) return;

        const draggedOver = this.findRow(overTrId);
        const dragCanDrop = this.handleDragCanDrop(dragged, draggedOver);
        this.setState({ draggedOver, dragCanDrop });
    };

    handleRowDragOver = event => {
        event.persist();
        if (event.preventDefault) {
            event.preventDefault(); // Necessary. Allows us to drop.
        }
        const { dragCanDrop } = this.state;
        event.dataTransfer.dropEffect = dragCanDrop ? 'move' : 'none';
    };

    handleRowDragEnd = () => {
        const { dragged, draggedOver, dragCanDrop } = this.state;
        const { rowDnD } = this.props.params;
        if (dragCanDrop && rowDnD && rowDnD.onDragEnd) {
            rowDnD.onDragEnd(dragged, draggedOver);
        }
        this.setState(initialDnD);
    };

    handleDragCanDrop = (dragged, draggedOver) => {
        const { rowDnD } = this.props.params;
        return rowDnD && rowDnD.canDrop ? rowDnD.canDrop(dragged, draggedOver) : false;
    };

    render() {
        const {
            data,
            params,
            groups,
            saveGridBodyRef,
            selected,
            multiSelected,
            firstField,
            lastField,
            resizedColumnName,
            forceWidth,
            toggleRowVisibility,
            fixedWidths
        } = this.props;

        if (params.loading) {
            return <Loading />;
        }
        if (!data || !data.length) {
            return (
                <div className="no-items">
                    <span>{params.t(getTKey(params.tKeys, 'noItems'))}</span>
                </div>
            );
        }

        const { draggedOver } = this.state;
        const { handleCheck, checkedItems } = groups || {};
        const fieldsValues = Object.keys(params.fields);
        const selectedId = this.getRowId(selected);
        const isDraggable = Boolean(params.rowDnD);
        const { withoutColumnsMenu } = params.options;

        return (
            <table className="table-grid grid-static-body" cellSpacing="0" ref={saveGridBodyRef}>
                <tbody>
                    {data.map(item => {
                        const id = this.getRowId(item);

                        return (
                            <tr
                                key={id}
                                data={id}
                                className={cx({
                                    selected:
                                        (id || (id === 0 && item.children)) &&
                                        (multiSelected.includes(id) ||
                                            selectedId === id ||
                                            id === this.getRowId(draggedOver))
                                })}
                                onClick={this.handleSelectItem(item)}
                                onDoubleClick={params.tr && params.tr.dblClick && params.tr.dblClick(item)}
                                draggable={isDraggable}
                                onDragStart={this.handleRowDragStart}
                                onDragEnter={this.handleRowDragEnter}
                                onDragOver={this.handleRowDragOver}
                                onDragEnd={this.handleRowDragEnd}
                                data-id={id}
                            >
                                {groups && (
                                    <td
                                        onClick={this.preventClickToCheckbox}
                                        className="grid-cell-checkbox"
                                        data-name="checkbox"
                                    >
                                        <input
                                            type="checkbox"
                                            value={id}
                                            onChange={handleCheck}
                                            checked={checkedItems.includes(id)}
                                        />
                                    </td>
                                )}
                                {fieldsValues.map((field, k) => (
                                    <GridCell
                                        key={field}
                                        lastField={lastField}
                                        firstField={firstField}
                                        toggleRowVisibility={toggleRowVisibility}
                                        value={item[field] === 0 ? 0 : item[field] || ''}
                                        item={params.fields[field]}
                                        id={id}
                                        name={field}
                                        data={item}
                                        t={params.t}
                                        resizing={!!resizedColumnName}
                                        forceWidth={
                                            field === resizedColumnName && forceWidth ? forceWidth : null
                                        }
                                        fixedWidth={fixedWidths ? fixedWidths[field] : null}
                                        elementIconClass={params.elementIconClass}
                                        folderIconClass={params.folderIconClass}
                                    />
                                ))}
                                {params.lastColumn
                                    ? params.lastColumn(item)
                                    : !withoutColumnsMenu && (
                                          <td className="grid-cell-hidemenu" data-name="_columns-hide-menu" />
                                      )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}

GridBody.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object,
    groups: PropTypes.object,
    saveGridBodyRef: PropTypes.func,
    selected: PropTypes.object,
    multiSelected: PropTypes.array,
    selectItem: PropTypes.func,
    toggleRowVisibility: PropTypes.func,
    firstField: PropTypes.string,
    lastField: PropTypes.string,
    fixedWidths: PropTypes.object,
    resizedColumnName: PropTypes.string
};
GridBody.defaultProps = {
    data: [],
    params: {},
    selected: {}
};

export default GridBody;
