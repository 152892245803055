import React, { useState, useEffect, useRef } from "react";
import ReactSVG from "react-svg";
import { Form, Field, FieldArray, submit, getFormValues, getFormSyncErrors, reduxForm } from "redux-form";
// import { reduxFormWrapper } from 'helpers';
import { debounce, isEmpty, get, isEqual } from "lodash";
import moment from "moment";
// import { Input, ComboBox, DatePicker, CheckBox } from 'ui-core-dashboard';
import { Input, ComboBox, CheckBox } from "ui-core-dashboard";
import cx from "classnames";
import { connect } from "react-redux";
import Loader from "../../Loader";
// import baseService from 'services/BaseService';
// import { showNotification } from 'actions/ui';
// import CalendarContainer from '../../../../components/Common/DatePicker/CalendarContainer';
import CalendarContainer from "../CustomDatePicker/CalendarContainer";
import CustomDatePicker from "../CustomDatePicker";
import FileInput from "../FileInput";
// import LiveSaver from '../LiveSaver';
import FieldController from "../FieldController";
import Rank from "../Rank";
import Radio from "../Radio";
import ComboBoxMultipleCheckbox from "../ComboBoxMultipleCheckbox";
import FormFieldArray from "../FormFieldArray";
import ContactArrayComponent from "../ContactArrayComponent";
import AddressField from "../AddressField";
import TextArea from "../TextArea";
import validate from "./validate";

import { fetch } from "../../../decorators";

import { DATE_VIEW_FORMAT, DATE_TIME_VIEW_FORMAT } from "./constants";

import { getValueFromStr, getInitialValuesFromWidgets } from "./helpers";

import "../assets/styles/styles_ordering.scss";
import "./styles.scss";

const mapStateToProps = (state, ownProps) => {
    return {
        form: ownProps.formName, // set form name from ownProps
        destroyOnUnmount: "destroyOnUnmount" in ownProps ? ownProps.destroyOnUnmount : true,
        formValues: getFormValues(ownProps.formName)(state),
        formErrors: getFormSyncErrors(ownProps.formName)(state),
        active: state.form && state.form[ownProps.formName] && state.form[ownProps.formName].active,
        initialValues: state.form[ownProps.formName] && state.form[ownProps.formName].initial,
        regFields: state.form[ownProps.formName] && state.form[ownProps.formName].registeredFields,
    };
};

const mapDispatchToProps = {
    submitForm: form => submit(form),
    showNotification: () => {},
};

const AUTOSAVE_TYPES = ["radio", "fileMultiple", "rank"];

const WIDGETS_WITHOUT_CONTROLLER = ["checkbox", "address", "contact"];

// TODO: maybe add support of LiveSaver to this widgets later in ui-core, date and datetime need to have updated onBluer behavior
// const WIDGETS_WITH_LOCAL_LIVE_SAVER = [
//     'date',
//     'datetime',
//     'radio',
//     'rank',
//     'fileMultiple',
//     'multiselect',
//     'address',
// ];

/*
    DynamicForm main props:
    1. formName => give DynamicForm custom form name to check it later in store
    2. formValues => used to ref fields work properly (get value of ref field and return options)
    3. liveSave => turns on saving every field on blur/tab/enter/buttons click etc.
    4. mainId => id of main data (used for form collapse manipulation and update useEffect)
    5. currentId => id of current data (used for form collapse manipulation and liveSubmit)
    6. blockOffsetKey => fieldKey to calculate offset and scroll to error if any
    7. setBlockOffset => return offset height to error

*/

// function renderFieldArray

const FieldArrayComponent = props => {
    const {
        t,
        name,
        fields,
        widgets,
        arrayKey,
        renderWidgets,
        formValues,
        disabled,
        onFieldsRemove,
        // meta: { error, name, submitFailed },
    } = props;

    const initValues = widgets.reduce((acc, curr) => {
        let widget = curr;
        if (typeof curr === "function") {
            widget = widget(formValues);
        }
        if (!widget) {
            return acc;
        }
        return {
            ...acc,
            [widget.key]: null,
        };
    }, {});

    function removeFields(index) {
        if (onFieldsRemove && typeof onFieldsRemove === "function") {
            onFieldsRemove(arrayKey, index, initValues);
        }
        fields.remove(index);
    }
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <>
                        {renderWidgets(widgets, arrayKey, index, field)}
                        {!disabled && (
                            <button
                                type="button"
                                className="remove-button add-button"
                                title={t("remove")}
                                onClick={() => removeFields(index)}
                            >
                                <i className="icon-delete" />
                            </button>
                        )}
                    </>
                );
            })}
            {!disabled && (
                <button type="button" className="add-button" title={t("add")} onClick={() => fields.push(initValues)}>
                    + {t("add")}
                </button>
            )}
        </>
    );
};

const DynamicForm = props => {
    const {
        t,
        initialize,
        dynData,
        handleSubmit,
        submitForm,
        initialValues,
        forceInitialValues,
        formName,
        formValues,
        formCollapse,
        className,
        change,
        refreshDynParams,
        setFormCollapse,
        blockOffsetKey,
        setBlockOffset,
        mainId,
        currentId,
        objectTypeSpec,
        liveSave,
        active,
        liveSaveIdKey = "taskId",
        liveSaveUrlKey = "save_order_param",
        liveSaveJSON = false,
        liveSaveWithoutChangeNum = false,
        formErrors,
        isLiveSaveOnlyValid,
        maxInputLength = 256,
        maxTextAreaLength = 20000,
        setIsLiveSubmitting,
        withoutCollapse = false,
        onLiveSaveSuccess,
        isOnceInitialized,
        readonly,
        portalPlacement,
        // portalTopLimit,
        // portalBottomLimit,
        // showNotification = () => {},
        showNotification,
        regFields,
        onActions,
        // portalRootId,
    } = props;

    const [collapseState, setCollapseState] = useState({});
    const [ajaxOptions, setAjaxOptions] = useState({});
    const [ajaxInitOptions, setAjaxInitOptions] = useState({});
    const [extOptions, setExtOptions] = useState({});
    const [heights, setHeights] = useState(null);
    const [extOptionsLoading, setExtOptionsLoading] = useState({});
    const [forceDirtyLiveSubmit, setForceDirtyLiveSubmit] = useState({});

    const [resetKeyFields, setResetKeyFields] = useState({});
    const [resetKeySavedValues, setResetKeySavedValues] = useState({});

    // const autoSaveTypes = ['fileMultiple'];
    const enterDisabledTypes = ["multiline"];
    const dynDataLength = dynData.length;

    const refs = useRef(dynData.map(React.createRef));

    const convertOptions = options => options.map(option => ({ label: option.value, value: option.key }));

    const getRefOptions = (options, refValue) => {
        if (!refValue) return convertOptions(options); // return default if not refValue selected;
        const refOptions = convertOptions(options.filter(option => option.refVal && option.refVal.includes(refValue)));
        return refOptions;
    };

    const toggleCollapse = key => {
        const widgetsCollapsed = { ...collapseState }; // spread to clone collapseObj
        widgetsCollapsed[key] = !widgetsCollapsed[key]; // toggle value by key
        setCollapseState(widgetsCollapsed);
    };

    const setOptionsQuery = debounce((searchStr, attributeKey, blockKey, widget) => {
        // if (!searchStr || searchStr.length < 2) return;
        if (!searchStr || searchStr.length < 2) {
            // check if no value is changed (!isSavedValueInOptions) - return back init options
            // const savedFormValue = formValues[attributeKey];
            // const isSavedValueInOptions = ajaxOptions[attributeKey] && ajaxOptions[attributeKey].find(item => item.value === savedFormValue);
            // console.log({isSavedValueInOptions, options: ajaxOptions[attributeKey], savedFormValue});
            // if (!isSavedValueInOptions) {
            //     setAjaxOptions(ajaxInitOptions[attributeKey]);
            // }
            // console.log({isSavedValueInOptions});
            // if (ajaxInitOptions[attributeKey]) {
            //     setAjaxOptions(ajaxInitOptions[attributeKey]);
            // }
            return;
        }
        const optionsCallback = newOptions => {
            const initOptions = ajaxInitOptions[attributeKey] || [];
            const mergedWithInitOptions = [...newOptions, ...initOptions];
            setAjaxOptions(ajaxOptions => ({
                ...ajaxOptions,
                [attributeKey]: mergedWithInitOptions,
            }));
        };
        // handle customAjaxOptions
        if (widget.customAjaxOptions && typeof widget.customAjaxOptions === "function") {
            // const optionsCallback = (newOptions) => {
            //     const initOptions = ajaxInitOptions[attributeKey] || [];
            //     const mergedWithInitOptions = [...newOptions, ...initOptions];
            //     setAjaxOptions((ajaxOptions) => ({
            //         ...ajaxOptions,
            //         [attributeKey]: mergedWithInitOptions,
            //     }));
            // };
            widget.customAjaxOptions(searchStr, optionsCallback, attributeKey, formValues);
            return;
        }
        // handle default ajax
        const params = {
            data: { attributeKey, blockKey, searchStr, page: 1, start: 0, limit: 25 },
        };
        fetch().send({
            api: { key: "dyn_form_ajax", ...params.data, method: "post", isFormData: false },
            onSuccess: ({ success, result }) => {
                if (success) {
                    const newOptions = convertOptions(result);
                    optionsCallback(newOptions);
                } else {
                    console.error("Ajax options request failed");
                }
                // dispatch(saveUserData({ person: result }));
            },
            onFailure: error => {
                console.error("Ajax options request failed", error);
            },
        });
    }, 300);

    const getBlockVersionByField = key => {
        let version = null;
        dynData.forEach(block => {
            block.widgets.forEach(widget => {
                if (widget.key === key) {
                    version = block.version;
                }
            });
        });
        return version;
    };

    const checkDynDataForExt = fieldKey => {
        let isExt = false;
        dynData.forEach(block =>
            block.widgets.forEach(widget => {
                if (widget.key === fieldKey && widget.rule === "#[callMethod]") isExt = true;
            })
        );
        return isExt;
    };

    const getExtValue = fieldKey => {
        let extValue = "";
        const { options } = extOptions[fieldKey];
        const selectedOption = options.filter(option => +option.value === +formValues[fieldKey])[0];
        extValue = selectedOption && selectedOption.value ? `${selectedOption.label}:${selectedOption.value}` : "";
        return extValue;
    };

    const handleLiveSubmit = customLiveSubmit => (key, responseCallback) => {
        if (isLiveSaveOnlyValid && formErrors && formErrors[key]) {
            responseCallback(false);
            return;
        }
        if (forceDirtyLiveSubmit[key]) {
            setForceDirtyLiveSubmit(keys => ({ ...keys, [key]: false }));
        }
        const isExtField = checkDynDataForExt(key);
        const blockVersion = getBlockVersionByField(key);
        let urlKey = liveSaveUrlKey;
        let data = {
            data: {
                [liveSaveIdKey]: currentId,
                attrCode: key,
                attrValue: isExtField ? getExtValue(key) : formValues[key] || "",
            },
            jsonType: liveSaveJSON,
        };

        if (customLiveSubmit && typeof customLiveSubmit === "function") {
            const customData = customLiveSubmit(data);
            if (customData && customData.data) {
                data = customData.data;
            }
            if (customData && customData.liveSaveUrlKey) {
                urlKey = customData.liveSaveUrlKey;
            }
        }

        if (!liveSaveWithoutChangeNum) {
            data.data.changeNum = blockVersion;
        }

        if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
            setIsLiveSubmitting(true);
        }
        fetch().send({
            api: { key: urlKey, data, method: "post" },
            onSuccess: ({ success, result }) => {
                responseCallback(success);
                if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
                    setIsLiveSubmitting(false);
                }
                if (success) {
                    // check for dynParams refresh
                    if (result && result.actions && onActions && typeof onActions === "function") {
                        onActions(result.actions);
                    }
                    if (result && result.actions && result.actions.includes("formReload") && refreshDynParams) {
                        refreshDynParams();
                    }
                    if (onLiveSaveSuccess && typeof onLiveSaveSuccess === "function") {
                        // console.log({result, data});
                        onLiveSaveSuccess({
                            result,
                            data,
                            extOptions: extOptions[key],
                            ajaxOptions: ajaxOptions[key],
                            urlKey,
                        });
                    }
                } else {
                    console.log("Save param request failed");
                }
            },
            onFailure: error => {
                if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
                    setIsLiveSubmitting(false);
                }
                console.error("DynamicForm::handleLiveSubmit: Error:", e);
            },
        });
    };

    const handleFileUpload = (file, handleUploadResponse, inputKey) => {
        // const data = {
        //     data: {
        //         data: {
        //             objectId: +currentId,
        //             objectType: "TASK",
        //             fileName: file.name,
        //             attrCode: inputKey,
        //         },
        //         file,
        //     },
        //     file: true,
        // };
        const data = {
            fileName: file.name,
            attrCode: inputKey,
        }
        fetch().send({
            api: { key: "save_file", ...data, method: "post", isQueryStringParameters: true },
            file,
            onSuccess: ({ success, result }) => {
                if (success) {
                    handleUploadResponse(file, result.id || result);
                } else {
                    console.log("Uploading file request failed");
                    handleUploadResponse(file, null, "Error during file upload");
                }
                // dispatch(saveUserData({ person: result }));
            },
            onFailure: error => {
                console.error("Uploading file request failed", error);
                handleUploadResponse(file, null, "Error during file upload");
            },
        });
    };

    const handleFileDelete = (fileId, handleDeleteResponse, fieldKey) => {
        // const data = { data: { fileId } };
        fetch().send({
            api: { key: "delete_file", id: fileId, attrCode: fieldKey, method: "delete", isQueryStringParameters: true },
            onSuccess: ({ success }) => {
                if (success) {
                    handleDeleteResponse(fileId);
                } else {
                    console.log("Delete file request failed");
                    handleDeleteResponse(fileId, "Error during file delete");
                }
            },
            onFailure: error => {
                console.error("Delete file request failed", error);
            },
        });
    };

    const getViewClass = view => {
        switch (view) {
            case "2column": {
                return "two-column";
            }
            case "3column": {
                return "three-column";
            }
            default: {
                return "form";
            }
        }
    };

    // values in format [{key, value}]
    // savedValues in format: "key|key"
    const getUploadedFiles = (values, savedValues) => {
        if (!savedValues || savedValues.length === 0) return [];
        // const savedArray = savedValues.split("|");
        // console.log({savedValues});
        // console.log({files: values.filter(value => savedArray.includes(value.key))})
        return values.filter(value => savedValues.includes(value.key));
    };

    const updateExtOptions = (fieldKey, newOptions) => {
        const field = extOptions[fieldKey];
        const { initialOptions, refKey } = field;
        const refValue = formValues[refKey] && typeof formValues[refKey] === 'object' ? formValues[refKey].value : formValues[refKey];
        setExtOptions(options => ({
            ...options,
            [fieldKey]: {
                ...options[fieldKey],
                savedRefValue: refValue,
                options: newOptions || initialOptions,
            },
        }));
    };

    const handleExtParamsRequest = (fieldKey, params) => {
        fetch().send({
            api: { key: "get_external_params", params, method: "post" },
            onSuccess: ({ success, result }) => {
                setExtOptionsLoading(extOptionsLoading => ({
                    ...extOptionsLoading,
                    [fieldKey]: false,
                }));
                if (success) {
                    if (!isEmpty(result[fieldKey][0])) {
                        const resultOptions = result[fieldKey].map(option => ({
                            label: option.id,
                            value: option.value,
                        }));
                        updateExtOptions(fieldKey, resultOptions);
                    }
                } else {
                    console.log("External / REST options request failed");
                }
                // dispatch(saveUserData({ person: result }));
            },
            onFailure: error => {
                console.error("External / REST options request failed", error);
            },
        });
    };

    const getSelectedExtOption = fieldKey => {
        const extFieldOptions = extOptions[fieldKey].options;
        const extFieldValue = formValues[fieldKey];
        return extFieldOptions.filter(option => option.value === extFieldValue)[0];
    };

    const getDirtyExtVals = () => {
        let obj = {};
        Object.keys(formValues).map(key => {
            if (extOptions[key]) {
                const selectedOption = getSelectedExtOption(key);
                obj = {
                    ...obj,
                    [key]:
                        selectedOption && selectedOption.value
                            ? `${selectedOption.label}:${selectedOption.value}`
                            : null,
                };
            }
        });
        return obj;
    };

    const getExtFilterRefVal = fieldKey => {
        if (extOptions[fieldKey] && extOptions[fieldKey].refKey) {
            const { refKey } = extOptions[fieldKey];
            if (extOptions[refKey]) {
                const selectedOption = getSelectedExtOption(refKey);
                return selectedOption && selectedOption.label;
            }
            return formValues[refKey];
        }
    };

    const handleExtSelectOpen = fieldKey => {
        setExtOptionsLoading(extOptionsLoading => ({ ...extOptionsLoading, [fieldKey]: true }));
        const params = {
            data: {
                dirtyVals: { ...formValues, ...getDirtyExtVals() },
                filterVal: getExtFilterRefVal(fieldKey) || formValues[fieldKey],
                objectId: currentId,
                objectType: "task",
                objectTypeSpec,
                objectVal: null,
                paramId: fieldKey,
            },
            jsonType: true,
        };
        handleExtParamsRequest(fieldKey, params);
    };

    // reset ref/extRef fields if parent field changed
    const handleExtRefChange = (fieldKey, refKey, rule) => {
        const currentValue = formValues[fieldKey] && typeof formValues[fieldKey] === 'object' ? formValues[fieldKey].value : formValues[fieldKey];
        const currentRefValue = formValues[refKey] && typeof formValues[refKey] === 'object' ? formValues[refKey].value : formValues[refKey];
        const { savedRefValue, initialOptions } = extOptions[fieldKey];
        const isExt = rule === "#[callMethod]";

        // console.log({savedRefValue, initialOptions, currentValue, currentRefValue, extOptions, fieldKey, refKey, rule});

        if (savedRefValue !== currentRefValue) {
            if (isExt) {
                change(fieldKey, "");
                if (savedRefValue) {
                    setForceDirtyLiveSubmit(keys => ({ ...keys, [fieldKey]: true }));
                }
                updateExtOptions(fieldKey, []);
            } else {
                // additional check for ref options
                const refOptions = convertOptions(
                    initialOptions.filter(option => option.refVal && option.refVal.includes(currentRefValue))
                );
                const refValues = refOptions.map(option => option.value);
                // case: curValue is 10, refValue changed so curValue 10 is not matching any options ('1', '2', '3')
                if (refOptions.length !== 0 && !refValues.includes(currentValue)) {
                    change(fieldKey, "");
                    setForceDirtyLiveSubmit(keys => ({ ...keys, [fieldKey]: true }));
                }
                // case: refValue is empty and currentValue is filled - cleared up
                if (
                    refValues.length === 0 &&
                    ![null, undefined, ""].includes(currentValue) &&
                    [null, undefined, ""].includes(currentRefValue)
                ) {
                    change(fieldKey, "");
                    setForceDirtyLiveSubmit(keys => ({ ...keys, [fieldKey]: true }));
                }
                updateExtOptions(fieldKey, getRefOptions(initialOptions, currentRefValue));
            }
        }
    };

    // 'label:label:label:value' => 'label:label:label'
    const getLabelFromStr = stringValue => {
        if (!stringValue) return null;
        const arrayValue = stringValue.split(":");
        return arrayValue.slice(0, arrayValue.length - 1).join(":"); // get all accept last value and join it into string
    };

    const convertExtValues = values =>
        values.map(val => ({
            value: getValueFromStr(val.key),
            label: getLabelFromStr(val.value),
        }));

    // handle convert back string value into timestamp
    const handleDatePickerChange = ({ type, value, key }) => {
        // console.log({ type, value, key });
        const format = type === "datetime" ? DATE_TIME_VIEW_FORMAT : DATE_VIEW_FORMAT;

        const isValid = moment(value, format).format(format) === value;
        if (isValid) {
            const unixTS = moment(value, format).unix();
            // console.log({unixTS});
            setTimeout(() => change(key, `${unixTS * 1000}`), 0);
        }
    };

    const processResetWidgetKey = (resetKey, widgetKey) => {
        const resetFields = get(resetKeyFields, resetKey, []);
        if (!resetFields.includes(widgetKey)) {
            setResetKeyFields(prev => {
                const prevFields = get(prev, resetKey, []);
                return {
                    ...prev,
                    [resetKey]: [...prevFields, widgetKey],
                };
            });
        }
    };

    const renderField = data => {
        const {
            widgetType,
            key: widgetKey,
            blockKey,
            title,
            // disabled,
            isReq,
            // values: initValues,
            values,
            refKey,
            savedValue,
            rule,
            clearable: isClearable,
            maxLength,
            handleContactChange,
            // inputType,
            inputType = "text",
            hasExValue = false,
            resetKey,
            labelKey,
            valueKey,
            selectNode,
            placeholder,
        } = data;

        const isExt = rule === "#[callMethod]";

        const disabled = readonly || data.disabled;

        const key = widgetKey;

        if (resetKey) {
            if (Array.isArray(resetKey)) {
                resetKey.forEach(resKey => processResetWidgetKey(resKey, widgetKey));
            } else {
                processResetWidgetKey(resetKey, widgetKey);
            }
        }

        const defaultProps = {
            key,
            name: key,
            label: title,
            disabled,
            required: isReq,
            rule,
            clearable: isClearable,
            isClearable,
            hasExValue,
            isDisabled: disabled,
            // disabled: true,
        };

        // additional disabled props to make input scrollable in disabled state
        const disabledInputProps = disabled ? { input: { value: (formValues && formValues[key]) || "" } } : {};

        const defaultPropsDate = {
            saveFormat: "x",
            locale: "uk",
            popperContainer: props => <CalendarContainer {...props} fieldKey={key} />,
            showMonthDropdown: true,
            showYearDropdown: true,
        };

        const defaultPropsAjax = {
            placeholder: !disabled && (placeholder || t("searchPhrase")),
            openOnClick: false,
            options: ajaxOptions[key] && ajaxOptions[key].length ? ajaxOptions[key] : ajaxInitOptions[key],
            onInputChange: query => setOptionsQuery(query, key, blockKey, data),
            filterOption: () => true, // options are filtered already by string on backend side, return all options by default
            noResultsText: t("noItems"),
            noOptionsMessage: () => t("noItems"),
            // clearable: false,
            isClearable,
            searchable: true,
            isDisabled: disabled,
        };

        switch (widgetType) {
            case "input": {
                return (
                    <Field
                        {...defaultProps}
                        {...disabledInputProps}
                        className={cx("input-field", { disabled: disabled })}
                        // disabled={false} // always false, disable typing by class and disabledInpusProps
                        spellCheck={false} // remove red hightlight
                        maxLength={maxLength || maxInputLength}
                        type={inputType}
                        component={Input}
                    />
                );
            }
            case "select": {
                return (
                    <Field
                        {...defaultProps}
                        placeholder={!disabled && t("dontSelect")}
                        noResultsText={t("noItems")}
                        noOptionsMessage={() => t("noItems")}
                        clearValueText={t("clearValue")}
                        options={
                            refKey || rule === "#[callMethod]"
                                ? (extOptions[key] && extOptions[key].options) || []
                                : convertOptions(values)
                        }
                        onOpen={isExt ? () => handleExtSelectOpen(key) : null}
                        isDisabled={disabled || (isExt && refKey && formValues && !formValues[refKey])} // disabled check for ext ref selects
                        isLoading={extOptionsLoading[key]}
                        escapeClearsValue={!liveSave}
                        className="container-combo-box"
                        classNamePrefix="select"
                        component={ComboBox}
                    />
                );
            }
            case "searchbox":
            case "ajaxCombo": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsAjax}
                        escapeClearsValue={!liveSave}
                        className="container-combo-box"
                        classNamePrefix="select"
                        component={ComboBox}
                    />
                );
            }
            case "multiline": {
                return <Field {...defaultProps} maxLength={maxLength || maxTextAreaLength} component={TextArea} />;
            }
            case "date": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsDate}
                        viewFormat={DATE_VIEW_FORMAT}
                        yearDropdownItemNumber={108}
                        hasTimePicker={false}
                        onChange={value => handleDatePickerChange({ type: widgetType, value, key })}
                        component={CustomDatePicker}
                    />
                );
            }
            case "datetime": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsDate}
                        viewFormat={DATE_TIME_VIEW_FORMAT}
                        yearDropdownItemNumber={108}
                        onChange={value => handleDatePickerChange({ type: widgetType, value, key })}
                        component={CustomDatePicker}
                    />
                );
            }
            case "radio": {
                return <Field {...defaultProps} values={values} component={Radio} />;
            }
            case "rank": {
                return <Field {...defaultProps} values={values} component={Rank} />;
            }
            case "multiselect": {
                return (
                    <Field
                        {...defaultProps}
                        placeholder={t("dontSelect")}
                        noResultsText={t("noItems")}
                        noOptionsMessage={() => t("noItems")}
                        clearValueText={t("clearValue")}
                        options={
                            refKey || rule === "#[callMethod]"
                                ? (extOptions[key] && extOptions[key].options) || []
                                : convertOptions(values)
                        }
                        onOpen={isExt ? () => handleExtSelectOpen(key) : null}
                        disabled={disabled || (isExt && refKey && formValues && !formValues[refKey])} // disabled check for ext ref selects
                        isLoading={extOptionsLoading[key]}
                        escapeClearsValue={!liveSave}
                        className="container-combo-box"
                        classNamePrefix="select"
                        component={ComboBoxMultipleCheckbox}
                        multi
                    />
                );
            }
            // fileMultiple
            case "fileMultiple": {
                return (
                    <Field
                        {...defaultProps}
                        translate={{
                            size: t("files.size"),
                            maxSize: t("files.maxSize"),
                            ext: t("files.ext"),
                            extAllowed: t("files.extAllowed"),
                            button: t("add"),
                            attention: t("files.attention"),
                            confirmText: t("files.confirmText"),
                            yes: t("files.yes"),
                            no: t("files.no"),
                        }}
                        maxFileSize={25}
                        extensions={[]}
                        handleUpload={handleFileUpload}
                        handleDelete={handleFileDelete}
                        loader={<Loader loaderContainerClass="container-loader" loaderClass="input-loader" />}
                        files={getUploadedFiles(values, savedValue)}
                        path="../sdsapi/portal/api/file?fileId="
                        multiple
                        component={FileInput}
                    />
                );
            }

            case "checkbox": {
                return <Field {...defaultProps} component={CheckBox} />;
            }
            case "address": {
                return <Field {...defaultProps} value={savedValue} savedValue={savedValue} component={AddressField} />;
            }

            case "contact": {
                return (
                    <FormFieldArray
                        {...defaultProps}
                        readOnly={disabled}
                        isSaveOnlyValid={isLiveSaveOnlyValid}
                        showNotification={showNotification}
                        handleChangeValue={handleContactChange}
                        component={ContactArrayComponent}
                    />
                );
            }

            case "selectTree": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsAjax}
                        className="container-combo-box tree"
                        classNamePrefix="select"
                        options={values}
                        isSearchable={false}
                        isTree
                        labelKey={labelKey || "label"}
                        valueKey={valueKey || "value"}
                        selectNode={selectNode}
                        component={ComboBox}
                    />
                );
            }

            default: {
                console.log("Widget type is not founded/supported:", widgetType);
                return null;
            }
        }
    };

    const getFieldControllerProps = data => {
        const {
            widgetType,
            // key: widgetKey,
            key,
            refKey,
            values,
            rule,
            clearable,
            customLiveSubmit,
            // disabled,
        } = data;

        const disabled = readonly || data.disabled;

        let options = null;
        if (["select", "multiselect"].includes(widgetType)) {
            options =
                refKey || rule === "#[callMethod]"
                    ? (extOptions[key] && extOptions[key].options) || []
                    : convertOptions(values);
        }
        if (["searchbox", "ajaxCombo"].includes(widgetType)) {
            options = ajaxOptions[key] && ajaxOptions[key].length ? ajaxOptions[key] : ajaxInitOptions[key];
        }

        const controllerProps = {
            widgetType,
            fieldKey: key,
            // initValue: initialValues ? initialValues[key] : null,
            initValue: forceInitialValues ? get(forceInitialValues, key) : get(initialValues, key),
            // value: formValues ? geetformValues[key] : null,
            value: get(formValues, key),
            // error: formErrors ? formErrors[key] : '',
            error: get(formErrors, key, ""),
            change,
            loader: <Loader loaderContainerClass="container-loader" loaderClass="input-loader" />,
            autoSave: AUTOSAVE_TYPES.includes(widgetType) || get(forceDirtyLiveSubmit, key) || false,
            enterDisabled: enterDisabledTypes.includes(widgetType),
            disabled,
            clearable,
            isClearable: clearable,
            portalPlacement,

            showNotification,
            selectOptions: options,
            isSaveOnlyValid: isLiveSaveOnlyValid,
        };

        if (liveSave) {
            controllerProps.handleLiveSubmit = customLiveSubmit
                ? handleLiveSubmit(customLiveSubmit)
                : handleLiveSubmit();
        }
        return controllerProps;
    };

    const renderWidgets = (widgets, key, arrayIndex, field) => {
        return widgets.map(item => {
            let widget = item;
            if (typeof widget === "function") {
                widget = widget(formValues, arrayIndex);
            }
            if (!widget) {
                return;
            }
            // field => FieldArray logic
            const widgetKey = field ? `${field}.${widget.key}` : widget.key;
            return (
                <div
                    className={cx("widget-wrapper", {
                        hidden: widget.isHidden,
                        [widget.customWidth]: widget.customWidth,
                    })}
                >
                    {/* {widget.disabled || WIDGETS_WITHOUT_CONTROLLER.includes(widget.widgetType) ? ( */}
                    {WIDGETS_WITHOUT_CONTROLLER.includes(widget.widgetType) ? (
                        renderField({ ...widget, field, blockKey: key, key: widgetKey })
                    ) : (
                        <FieldController {...getFieldControllerProps({ ...widget, key: widgetKey })}>
                            {renderField({ ...widget, field, blockKey: key, key: widgetKey })}
                        </FieldController>
                    )}
                </div>
            );
        });
    };

    const renderDynParams = (data, index) => {
        if (!collapseState) return;
        const { key, title, widgets, view, description, filter, isHiddenBlock, disabled, isFieldArray } = data;
        const blockClass = collapseState[key] ? "block-wrapper" : "block-wrapper open";
        const headerClass = title ? "field-header" : "field-header no-title";
        const fieldsClass = title ? `fields-wrapper ${getViewClass(view)}` : `fields-wrapper open no-title ${getViewClass(view)}`;

        if (withoutCollapse) {
            return (
                <div
                    className={isHiddenBlock ? "block-wrapper removed" : "block-wrapper displayed"}
                    style={{ zIndex: (dynDataLength - index)}}
                    key={key}
                >
                    <div
                        className={cx({ [fieldsClass]: fieldsClass, isFieldArray })}
                        ref={refs.current[index]}
                        key={`${key}_content`}
                    >
                        {/* adding blockKey value to widget params (for ajaxCombo reference request) */}
                        {isFieldArray ? (
                            <FieldArray
                                t={t}
                                name={key}
                                widgets={widgets}
                                arrayKey={key}
                                renderWidgets={renderWidgets}
                                formValues={formValues}
                                disabled={disabled}
                                onFieldsRemove={handleFieldsRemove}
                                component={FieldArrayComponent}
                            />
                        ) : (
                            renderWidgets(widgets, key)
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div key={key} data-key={key} className={blockClass} style={{ zIndex: dynDataLength - index }}>
                <div key={`${key}_header`} className={headerClass} onClick={() => title && toggleCollapse(key)}>
                    <div className="title">
                        <i className="icon icon-down" />
                        {filter === "IN" && <ReactSVG src="./images/icons/params-in.svg" />}
                        {filter === "OUT" && <ReactSVG src="./images/icons/params-out.svg" />}
                        {title}
                    </div>
                    <div className="description">{description}</div>
                </div>
                <div
                    className={cx({ [fieldsClass]: fieldsClass, isFieldArray })}
                    ref={refs.current[index]}
                    key={`${key}_content`}
                >
                    {/* adding blockKey value to widget params (for ajaxCombo reference request) */}
                    {isFieldArray ? (
                        <FieldArray
                            t={t}
                            name={key}
                            widgets={widgets}
                            renderWidgets={renderWidgets}
                            arrayKey={key}
                            formValues={formValues}
                            disabled={disabled}
                            onFieldsRemove={handleFieldsRemove}
                            component={FieldArrayComponent}
                        />
                    ) : (
                        renderWidgets(widgets, key)
                    )}
                </div>
                <div className="field-border" />
            </div>
        );
    };

    const [lastDeletedArrayFieldName, setLastDeletedArrayFieldName] = useState(null);

    // simplified logic - simply set last deletedArrayFieldName and prevent from clearing not equal values of such reset field
    const handleFieldsRemove = (name, index, initValues) => {
        // setLastDeletedArrayFieldName(`${name}[${index}]`);
        setLastDeletedArrayFieldName(`${name}[`);

        // sync reset keys / values based on one of array value removal
        // to find all keys which are after current index and replace theirs indexes with - 1
        // update reset values and keys indexs
        // const resetName = `${name}[`;
        // const resetKeys = Object.keys(resetKeyFields).filter((key) => key.startsWith(resetName));
        // const resetValuesKeys = Object.keys(resetKeySavedValues).filter((key) =>
        //     key.startsWith(resetName),
        // );

        // const resetKeysToSync = resetKeys.filter((key) => {
        //     const [, indexPart] = key.split('[');
        //     const [currentIndex] = indexPart.split(']');
        //     // console.log({ parsedIndex: +parsedIndex, index: +index, indexPart });
        //     return +currentIndex > +index;
        // });
        // const resetValuesKeysToSync = resetValuesKeys.filter((key) => {
        //     const [, indexPart] = key.split('[');
        //     const [currentIndex] = indexPart.split(']');
        //     return +currentIndex > +index;
        // });
        // // update indexes of reset fields
        // if (resetKeysToSync.length) {
        //     setResetKeyFields((prev) => {
        //         resetKeysToSync.forEach((key) => {
        //             const [, indexPart] = key.split('[');
        //             const [currentIndex] = indexPart.split(']');
        //             const newIndex = +currentIndex - 1;
        //             const currentKeyPart = `${name}[${currentIndex}]`;
        //             const newKeyPart = `${name}[${newIndex}]`;
        //             let value = prev[key];
        //             if (value && Array.isArray(value)) {
        //                 value = value.map((subKey) => subKey.replace(currentKeyPart, newKeyPart));
        //             }
        //             delete prev[key];
        //             const newKey = key.replace(currentKeyPart, newKeyPart);
        //             // console.log({ currentKeyPart, newKeyPart, currentKey: key, newKey, value });
        //             prev[newKey] = value;
        //         });
        //         return { ...prev };
        //     });
        // }
        // // update indexes of saved values
        // if (resetValuesKeysToSync.length) {
        //     setResetKeySavedValues((prev) => {
        //         resetValuesKeysToSync.forEach((key) => {
        //             const [, indexPart] = key.split('[');
        //             const [currentIndex] = indexPart.split(']');
        //             const newIndex = +currentIndex - 1;
        //             const currentKeyPart = `${name}[${currentIndex}]`;
        //             const newKeyPart = `${name}[${newIndex}]`;
        //             const value = prev[key];
        //             delete prev[key];
        //             const newKey = key.replace(currentKeyPart, newKeyPart);
        //             // console.log({ currentKeyPart, newKeyPart, currentKey: key, newKey, value });
        //             prev[newKey] = value;
        //             // change(newKey, value);
        //         });
        //         return { ...prev };
        //     });
        // }
    };

    // useEffect(() => {
    //     console.log("FIELDS", JSON.stringify(resetKeyFields));
    // }, [resetKeyFields]);

    // useEffect(() => {
    //     console.log("VALUES", JSON.stringify(resetKeySavedValues));
    // }, [resetKeySavedValues]);

    useEffect(() => {
        const widgets = dynData.reduce((prev, curr) => [...prev, ...curr.widgets], []);
        if (forceInitialValues) {
            initialize(forceInitialValues);
        } else {
            const initializeValues = getInitialValuesFromWidgets(widgets, isOnceInitialized);
            initialize(initializeValues);
        }

        // const extWidgets = widgets.filter(widget => widget.rule === '#[callMethod]');

        const refWidgets = widgets.filter(widget => widget.refKey);

        const refWidgetsOptions = refWidgets.reduce((prev, curr) => {
            const savedRefValue = widgets.filter(widget => widget.key === curr.refKey)[0].savedValue;
            const { rule, refKey, key, values } = curr;
            const isExt = rule === "#[callMethod]";
            return {
                ...prev, // set initialValues in redux form in format { key: value }
                [key]: {
                    rule,
                    refKey,
                    initialOptions: isExt ? convertExtValues(values) : values,
                    options: isExt ? convertExtValues(values) : getRefOptions(values, savedRefValue),
                    savedRefValue: isExt ? getValueFromStr(savedRefValue) : savedRefValue,
                },
            };
        }, {});

        const extWidgets = widgets.filter(widget => widget.rule === "#[callMethod]" && !widget.refKey);

        const extWidgetsOptions = extWidgets.reduce((prev, curr) => {
            const { rule, key, values } = curr;
            return {
                ...prev, // set initialValues in redux form in format { key: value }
                [key]: {
                    rule,
                    initialOptions: convertExtValues(values),
                    options: convertExtValues(values),
                },
            };
        }, {});

        const ajaxWidgets = widgets.filter(widget => widget.widgetType === "ajaxCombo");
        // const ajaxWidgetsOptions = ajaxWidgets.reduce((prev, curr) => {
        //     const { key, values, savedValue } = curr;
        //     const value = savedValue ? values.filter((item) => item.key === savedValue) : [];
        //     return {
        //         ...prev,
        //         [key]: convertOptions(value),
        //     };
        // }, {});

        const ajaxWidgetsOptions = ajaxWidgets.reduce((prev, curr) => {
            const { key, values } = curr;
            return {
                ...prev,
                [key]: convertOptions(values),
            };
        }, {});

        setAjaxInitOptions(ajaxWidgetsOptions);

        setExtOptions({ ...refWidgetsOptions, ...extWidgetsOptions });

        // set collapsed from store or init local initial state (either pass it to store or leave local)
        if (
            setFormCollapse &&
            formCollapse &&
            !isEmpty(formCollapse[mainId]) &&
            !isEmpty(formCollapse[mainId][currentId])
        ) {
            setCollapseState(formCollapse[mainId][currentId]);
        } else {
            const initialCollapse = dynData.reduce((prev, curr) => ({ ...prev, [curr.key]: !!+curr.collapsed }), {});
            setCollapseState(initialCollapse);
        }

        // init hidden values widgets
        const hiddenValuesWidgets = widgets.filter(widget => widget.isHiddenByConfig && widget.hiddenValues);
        // console.log({hiddenValuesWidgets});
        hiddenValuesWidgets.forEach(widget => {
            change(widget.key, widget.hiddenValues);
        });
    }, []);

    useEffect(() => {
        // listen to refs ready state (if ref.current === null then return)
        if (!heights && refs.current.some(ref => ref.current)) {
            const { current } = refs;
            setHeights(
                current.reduce((prev, curr) => {
                    return {
                        ...prev,
                        [curr.current.parentElement.getAttribute("data-key")]: curr.current.offsetHeight + 12,
                    };
                }, {})
            );
        }
    });

    useEffect(() => {
        if (!isEmpty(collapseState) && setFormCollapse) {
            setFormCollapse(
                mainId,
                formCollapse ? { ...formCollapse[mainId], [currentId]: collapseState } : { [currentId]: collapseState }
            );
        }
    }, [collapseState]);

    useEffect(() => {
        if (blockOffsetKey) {
            const filteredBlock = refs.current.filter(ref => {
                return ref.current.parentElement.getAttribute("data-key") === blockOffsetKey;
            });
            const {
                current: { parentElement },
            } = filteredBlock[0];
            setBlockOffset(parentElement.offsetTop + parentElement.clientHeight);
        }
    }, [blockOffsetKey]);

    // useEffect for handling ref / ext options (updating ref ext options if parent option changed or cleared)
    useEffect(() => {
        if (formValues && !isEmpty(extOptions)) {
            Object.keys(extOptions).map(fieldKey => {
                const extOption = extOptions[fieldKey];
                if (extOption.refKey) {
                    const { refKey, savedValue, rule } = extOption;
                    const currValue = formValues[refKey];
                    if (savedValue !== currValue) {
                        return handleExtRefChange(fieldKey, refKey, rule);
                    }
                }
            });
        }
    }, [formValues]);

    // handle resetValues useEffects below:
    // used to clear some fields value once their "binded" field values are changed
    useEffect(() => {
        // update resetKeySavedValues
        if (formValues && !isEmpty(resetKeyFields)) {
            const newValues = {};
            Object.keys(resetKeyFields).forEach(key => {
                const val = get(formValues, key);
                newValues[key] = val;
            });
            // console.log({ newValues });
            setResetKeySavedValues(prev => ({ ...prev, ...newValues }));
        }
    }, [formValues, resetKeyFields]);

    useEffect(() => {
        // clear fields once resetKeyValue is changed
        if (formValues && !isEmpty(resetKeySavedValues)) {
            // console.log(JSON.stringify(formValues));
            // console.log(JSON.stringify(regFields));
            // console.log({ resetKeyFields, resetKeySavedValues });
            setTimeout(() => {
                Object.keys(resetKeyFields).forEach(resetKey => {
                    const fields = resetKeyFields[resetKey];
                    fields.forEach(widgetKey => {
                        // console.log({ resetKey, widgetKey, regFields });
                        // check if fields are still exist
                        if (!regFields[widgetKey] || !regFields[resetKey]) {
                            // console.log({regFields, resetKey, widgetKey});
                            if (!regFields[resetKey]) {
                                // completely remove resetKey
                                setResetKeyFields(prev => {
                                    delete prev[resetKey];
                                    return { ...prev };
                                });
                                setResetKeySavedValues(prev => {
                                    delete prev[resetKey];
                                    return { ...prev };
                                });
                            } else if (!regFields[widgetKey]) {
                                // remove single widgetKey inside of resetKey array
                                setResetKeyFields(prev => {
                                    return {
                                        ...prev,
                                        [resetKey]: prev[resetKey].filter(item => item !== widgetKey),
                                    };
                                });
                            }
                            // console.log({resetKeyFields, resetKeySavedValues});
                        } else {
                            // field is still exist - make reset
                            const fieldValue = get(formValues, widgetKey);
                            if (fieldValue) {
                                const savedResetVal = get(resetKeySavedValues, resetKey);
                                const currentResetVal = get(formValues, resetKey);
                                // console.log({savedResetVal, currentResetVal});
                                const isValuesEqual = isEqual(savedResetVal, currentResetVal);
                                if (!isValuesEqual) {
                                    // previously was deleted a field array fields => wait until next cycle to finally compare sync values
                                    if (lastDeletedArrayFieldName && widgetKey.startsWith(lastDeletedArrayFieldName)) {
                                        setLastDeletedArrayFieldName(null);
                                    } else {
                                        change(widgetKey, null);
                                    }
                                }
                            }
                        }
                    });
                });
            }, 0);
        }
        // }, [resetKeyFields, formValues, resetKeySavedValues, regFields]);
    }, [formValues]);

    return (
        <Form
            onSubmit={handleSubmit(() => submitForm(formName))}
            className={
                !className
                    ? "ordering-component-ui-core-wrapper order-dynamic-form "
                    : `ordering-component-ui-core-wrapper order-dynamic-form ${className}`
            }
        >
            {dynData &&
                !isEmpty(collapseState) &&
                // (forceInitialValues || initialValues) &&
                initialValues &&
                dynData.map(renderDynParams)}
        </Form>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ validate })(DynamicForm));
