export const DOMAIN_REGEX = /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_\wа-яА-Я\wіІїЇєЄґҐ]{1,63}(?<!-)(?:\.|$)){2,}$/;

// This answer is for domain names (including service RRs), not host names (like an email hostname).
// ^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$
// It is basically mkyong's answer and additionally:

// Max length of 255 octets including length prefixes and null root.
// Allow trailing '.' for explicit dns root.
// Allow leading '_' for service domain RRs, (bugs: doesn't enforce 15 char max for _ labels, nor does it require at least one domain above service RRs)
// Matches all possible TLDs.
// Doesn't capture subdomain labels.
// By Parts
// Lookahead, limit max length between ^$ to 253 characters with optional trailing literal '.'

// (?=.{1,253}\.?$)
// Lookahead, next character is not a '-' and no '_' follows any characters before the next '.'. That is to say, enforce that the first character of a label isn't a '-' and only the first character may be a '_'.

// (?!-|[^.]+_)
// Between 1 and 63 of the allowed characters per label.

// [A-Za-z0-9-_]{1,63}
// Lookbehind, previous character not '-'. That is to say, enforce that the last character of a label isn't a '-'.

// (?<!-)
// Force a '.' at the end of every label except the last, where it is optional.

// (?:\.|$)
// Mostly combined from above, this requires at least two domain levels, which is not quite correct, but usually a reasonable assumption. Change from {2,} to + if you want to allow TLDs or unqualified relative subdomains through (eg, localhost, myrouter, to.)

// (?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}

// export const PASSWORD_REGEX = /^[a-zA-Z0-9]{8,}$/;
// export const PASSWORD_REGEX = "[a-zA-Z0-9]{8,}"; // to be used with RULE
export const PASSWORD_REGEX = /^[a-zA-Z0-9]{8,}$/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_\wа-яА-Я\wіІїЇєЄґҐ]{1,63}(?<!-)(?:\.|$)){2,}$/;
export const PHONE_REGEX = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
