import React from 'react';

import './styles.scss';

const Radio = props => {
    const { key, values, label, required, disabled, input: { name, value: inputValue, onChange } } = props;

    const handleClick = e => {
        const value = e.currentTarget.getAttribute('value');
        onChange(inputValue === value ? null : value);
    };

    const renderRadio = data => {
        const { key, value } = data;
        const isSelected = inputValue === key;
        const className = isSelected ? 'radio-value-container selected' : 'radio-value-container';
        return (
            <div key={key} onClick={!disabled && handleClick} className={className} value={key} title={value}>
                <input type='text' value={key} name={name} /> {/* input used for handling blur events, no blur event fires without form element */}
                <div className='radio-value-checkmark' />
                <div className='radio-value-text'>{value}</div>
            </div>
        );
    };
    
    return (
        <div className='input-element radio-input-wrapper'>
            <div className='input-label'>
                {label}{required && <span className='required-field'>*</span>}
            </div>
            <input key={key} name={name} type='text' disabled={disabled} hidden />
            <div className={disabled ? 'radio-values disabled' : 'radio-values'}>
                {values && values.map(renderRadio)}
            </div>
        </div>
    );
};

export default Radio;