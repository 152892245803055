// FIXME refactor fn body via reduce
const initFilters = {
    requests: {
        init: {
            name: null,
            owner_entity_name: null,
            title: null,
            subtype_id: [],
            order_status_id: [],
            order_priority_id: [],
            operations_id: [],
            resp_party_id: [],
            resp_org_id: [],
            creator_party_id: [],
            organization_id: [],
            individuals_id: [],
            geo_location_id: [],
            address: null,
            flat_number: null,
            date_new: {
                from: null,
                to: null
            },
            date_plan_start: {
                from: null,
                to: null
            },
            dc: {
                from: null,
                to: null
            },
            date_required_finish: {
                from: null,
                to: null
            },
            date_work_done: {
                from: null,
                to: null
            },
            date_closed: {
                from: null,
                to: null
            },
            date_canceled: {
                from: null,
                to: null
            }
        },
        options: {
            resp_party_id: [],
            individuals_id: [],
            creator_party_id: [],
            geo_location_id: []
        }
    },
    locations: {
        init: {
            name: null,
            code: null,
            subtype_id: null,
            status_id: [],
            service_type_id: [],
            organization_id: [],
            geo_location_id: [],
            address: null,
            dc: {
                from: null,
                to: null
            }
        },
        options: {
            responsible_id: [],
            geo_location_id: []
        }
    },
    active_requests: false,
    active_locations: false
};

const checkKeys = (data, init) => {
    let resp = true;

    Object.keys(init).forEach(v => {
        resp = resp && Object.keys(data).includes(v);
    });

    return resp;
};

export const prepareGrids = (config, storage) => {
    const grids = {};

    Object.keys(config).forEach(configKey => {
        const { grid } = config[configKey];

        if (grid.version && storage[configKey]) {
            if (grid.version > storage[configKey].version) {
                grids[configKey] = grid;
            } else {
                grids[configKey] = storage[configKey] || grid;
            }
        } else {
            grids[configKey] = grid;
        }
    });

    return grids;
};

export const prepareFilters = filters => {
    if (filters) {
        const isInit = !!filters.requests.init || !!filters.locations.init;
        const isOptions =
            checkKeys(filters.requests.options, initFilters.requests.options) &&
            checkKeys(filters.locations.options, initFilters.locations.options);

        return isInit && isOptions ? filters : initFilters;
    }

    return initFilters;
};
