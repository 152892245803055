import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactSVG from "react-svg";
import ClickOutsideHolder from "../../components/ClickOutsideHolder";
import AppSidebar from "../AppSidebar";

// import { logoutUser } from '../../reducers/user';

import "./styles.scss";

const AppHeader = ({ t, isAuthorized, person, location: { pathname } }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleClickOutside = (e) => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    return (
        <header className="page-header">
            <div className="page-header__logo">
                <ReactSVG className="logo-svg" src="./images/portal-logo.svg" />
                {/* <ReactSVG className="logo-svg" src="./images/priocom-logo.svg" /> */}
                {/* <ReactSVG className="logo-svg" src="./images/IOC_logo.svg" /> */}
                <ReactSVG className="menu-svg" src="./images/icons/menu.svg" onClick={() => setIsMenuOpen(true)} />

                <div className="page-header__text">
                    <h2>{t("appTitle")}</h2>
                    <h3>{t('appDescription')}<br></br>{t('appSubDescription')}</h3>
                    {/* <h3>{t('appSubDescription')}</h3> */}
                    {/* <h3>{t('Демонстраційна версія')}</h3> */}
                    {/* <h3>{t('ДП "ІОЦ МІНСОЦПОЛІТИКИ УКРАЇНИ"')}</h3> */}
                </div>
            </div>
            {isAuthorized && (
                <div className="page-header__cabinet">
                    {/* <ReactSVG className="logo-svg" src="./images/icons/user.svg" /> */}
                    <Link className="page-header__tab" to="/account" key={name}>
                        {t(name)}
                        {person && person.fullName}
                    </Link>
                </div>
            )}
            {/* MOBILE MENU */}
            {isMenuOpen && (
                <ClickOutsideHolder onClickOutside={handleClickOutside}>
                    <div className="page-header__mobile-menu">
                        <div className="page-header__mobile-menu-header">
                            {/* <ReactSVG className="logo-svg" src="./images/portal-logo.svg" /> */}
                            {/* <ReactSVG className="menu-logo-svg" src="./images/IOC_logo.svg" /> */}
                            <ReactSVG
                                className="close-svg"
                                src="./images/icons/close.svg"
                                onClick={() => setIsMenuOpen(false)}
                            />
                        </div>
                        <AppSidebar withoutAuthorization />
                        {isAuthorized && (
                            <div className="page-header__mobile-cabinet">
                                <div className="logged-in-as">{t("loggedInAs")}:</div>
                                <Link className="page-header__tab" to="/account" key={name}>
                                    {t(name)}
                                    {person && person.fullName}
                                </Link>
                            </div>
                        )}
                    </div>
                </ClickOutsideHolder>
            )}
        </header>
    );
};

const mapStateToProps = ({ user, auth }) => ({
    isAuthorized: auth.state && auth.state === "auth",
    person: user.person,
});

export default withRouter(
    connect(mapStateToProps, {
        // logoutUser
    })(withTranslation()(AppHeader))
);

AppHeader.propTypes = {
    t: PropTypes.func,
    person: PropTypes.object,
    history: PropTypes.object,
    isAuthorized: PropTypes.bool,
    // logoutUser: PropTypes.func
};
