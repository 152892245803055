import React from 'react';
import PropTypes from 'prop-types';

const ErrorField = ({ meta, touched }) => {
    if (!meta) return null;
    if (!meta.error || (!meta.touched && !touched)) return null;

    return <div className="error-hint">{meta.error}</div>;
};

ErrorField.propTypes = {
    meta: PropTypes.object
};

export default ErrorField;
