import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = props => {
    const { icon, iconStyles, reverse, text, title, className = '', onClick, returnValue, disabled } = props;
    const handleClick = e => onClick(returnValue, e);

    return (
        <button
            type="button"
            title={title}
            className={cx(`btn ${className}`, { reverse })}
            onClick={handleClick}
            disabled={disabled}
        >
            {text} {icon && <i className={icon} style={iconStyles} />}
        </button>
    );
};

Button.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    returnValue: PropTypes.any,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    iconStyles: PropTypes.object,
    reverse: PropTypes.bool
};

export default Button;
