export { default as throttle } from 'lodash/throttle';
export const throttleDefaultWait = 400;
export { default as deBounce } from 'lodash/debounce';
export const deBounceDefaultWait = 400;

// Object manipulators.
export { default as deepFreeze } from './deepFreeze';
export { default as deepMerge } from 'lodash/merge';
export { default as deepClone } from 'lodash/cloneDeep';

// Predicates
export { isPlainObject, isEqual, isInteger, pickBy } from 'lodash';

// Object functions
export { default as keysToCamelCase } from './keysToCamelCase';

// URL manipulators
export { default as stringifyQueryString } from './stringifyQueryString';
export { default as parseQueryString } from './parseQueryString';
export { default as buildUrl } from './buildUrl';

// App Left Menu functions
export * from './general';
export * from './storeFuncs';
export * from './forTree';
// export { default as getMenuActiveSection } from './getMenuActiveSection';

// Time converters
export { default as getUserTime } from './getUserTime';
export { default as getUTCTime } from './getUTCTime';

// Other
export { default as getBrowserLanguage } from './getBrowserLanguage';
export { default as getCookie } from './getCookie';
export { default as downloadFile } from './downloadFile';
export { default as isEmptyOrSpaces } from './isEmptyOrSpaces';
export { default as latitudeValidation } from './latitudeValidation';
export { default as longitudeValidation } from './longitudeValidation';
export { default as formatBytes } from './formatBytes';
export { default as reactDebounce } from './reactDebounce';
// export xor from './xor';
export * from './FormsUtilis';
export * from './templates';
export * from './ecpFuncks';
