import { createSelector } from "reselect";

import { keysToCamelCase } from "../helpers";

import { fetch } from "../decorators";

/* constants */

const initialState = {
    roles: null,
    person: {},
};

const actions = {
    SAVE_USER_INFO: "SAVE_USER_INFO",
    LOGOUT_USER: "LOGOUT_USER",
};

/* reducer */

export default (state = initialState, { type, ...payload }) => {
    if (type === actions.LOGOUT_USER) return { ...initialState };
    if (actions[type]) return { ...state, ...payload };
    return state;
};

/* selectors */

export const isUserAuthorizedSelector = createSelector(
    (state) => state.user.userId,
    (id) => Boolean(id)
);

/* action creators */

export const saveUserData = (userInfo) => ({
    type: actions.SAVE_USER_INFO,
    ...keysToCamelCase(userInfo),
});

export const logoutUser = () => ({
    type: actions.LOGOUT_USER,
    ...initialState,
});

/* thunks */

export const fetchPerson = () => (dispatch) => {
    fetch().send({
        api: { key: "person" },
        onSuccess: ({ result }) => {
            dispatch(saveUserData({ person: result }));
        },
        onFailure: (error) => {
            console.log("Reducers::user:fetchPerson: Error", error);
        },
    });
};
