import React from 'react';
import PropTypes from 'prop-types';

import FormElementWrapper from './FormElementWrapper';

const RadioBox = props => {
    const { input, label, meta, liveSaver, tooltip, options, ...rest } = props;

    const getRadio = ({ id, name }) => {
        const handleChange = () => {
            input.onChange(id);
        };

        return (
            <div className="radio">
                <input
                    className="custom-radio"
                    type="radio"
                    id={`radio-${id}`}
                    name={input.name}
                    value={id}
                    onChange={handleChange}
                />
                <label htmlFor={`radio-${id}`}>{name}</label>
            </div>
        );
    };

    return (
        <FormElementWrapper tooltip={tooltip} meta={meta} label={label} liveSaver={liveSaver}>
            <div className="group-radio">{options && options.map(getRadio)}</div>
        </FormElementWrapper>
    );
};

RadioBox.displayName = 'Radio';

RadioBox.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    liveSaver: PropTypes.object,
    tooltip: PropTypes.string,
    options: PropTypes.array
};

export default RadioBox;
