import { leftTrimProps, fieldLevelValidation } from 'util/form';
import { isEmpty, get } from 'lodash';
import i18next from 'util/i18n';
import {
    validateEmail,
    arrayUniqueValues,
    PHONE_NUMBER_UNIQUE,
    PHONE_NUMBER,
    EMAIL,
    EMAIL_UNIQU,
} from 'util/form';

import moment from 'moment';

import { DATE_VIEW_FORMAT, DATE_TIME_VIEW_FORMAT } from './constants';

// LOGIC FOR CONTACTS ARRAY VALIDATION START
function validateArray(value, errorCondition, errorText, isReq) {
    if (!value) return undefined;
    const errors = value.reduce((acc, fieldValue, index) => {
        if (errorCondition(fieldValue)) {
            acc[index] = errorText || i18next.t('validation.contactFormat');
        } else if (isReq && !fieldValue) {
            acc[index] = i18next.t('validation.required');
        }
        return acc;
    }, {});
    return isEmpty(errors) ? undefined : errors;
}

function emailValidator(value, isReq) {
    if (!Array.isArray(value)) return undefined;
    const values = value.map((contact) => contact.value);

    const unique = arrayUniqueValues(values, EMAIL_UNIQU());
    const errorCondition = (fieldValue) => fieldValue && !validateEmail(fieldValue);
    const email = validateArray(values, errorCondition, EMAIL(), isReq);

    if (email || unique) {
        return { ...email, ...unique };
    }
}

export function phoneValidator(value, isReq) {
    if (!Array.isArray(value)) return undefined;
    const values = value.map((contact) => contact.value);

    const unique = arrayUniqueValues(values, PHONE_NUMBER_UNIQUE());
    const errorCondition = (fieldValue) => fieldValue && !/^[0-9]{3,12}$/.test(fieldValue);
    const number = validateArray(values, errorCondition, PHONE_NUMBER(), isReq);
    if (number || unique) {
        return { ...number, ...unique };
    }
}

const CONTACT_ARRAY_VALIDATORS = (isReq) => ({
    homePhone: (value) => phoneValidator(value, isReq),
    workPhone: (value) => phoneValidator(value, isReq),
    email: (value) => emailValidator(value, isReq),
});

// LOGIC FOR CONTACTS ARRAY VALIDATION ENDS

const isEmptyValue = (value) => {
    if (typeof value === 'number') {
        return false;
    }
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    return isEmpty(value);
};

// added check isEmptyValue for number values (if value is number it's ok);
const requiredFieldsValidation = (values, config, errors) => {
    config.forEach((field) => {
        // if (field.name === 'attribute') {
        //     console.log({field});
        // }
        if (
            !field.fieldArray &&
            field.required &&
            // (isEmptyValue(values[field.name]) || values[field.name][0] === 'undefined')
            isEmptyValue(values[field.name])
        ) {
            errors[field.name] = 'validation.required';
        }
        // FieldArray validation
        if (field.fieldArray && field.required && !field.disabled) {
            const arrayValues = get(values, field.fieldArray);
            // console.log({ arrayValues, field });
            if (arrayValues && !isEmpty(arrayValues[field.fieldArrayIndex])) {
                // console.log({ arrayValues, field });
                // arrayValues.
                const arrayErrors = arrayValues.reduce((acc, curr, index) => {
                    // console.log({index, curr, fieldArrayIndex: field.fieldArrayIndex});
                    if (index === field.fieldArrayIndex && [null, undefined, ''].includes(curr[field.name])) {
                        // console.log({[index]: { [field.name]: i18next.t('validation.required') }});
                        return {
                            ...acc,
                            [index]: { ...acc[index], [field.name]: i18next.t('validation.required') },
                        };
                    }
                    // console.log({acc});
                    return acc;
                }, {});
                // console.log({arrayErrors});
                const prevErrors = errors[field.fieldArray] || {};
                errors[field.fieldArray] = {
                    ...prevErrors,
                    ...arrayErrors
                };
                // return errors;
                // console.log({field, errors, arrayErrors});
            }
            // console.log({ rule, widgetType, widgetKey, widget, values, props });
        }
    });
};

const fieldValidation = (rule, widgetType, widgetKey, widget, values) => {
    // additional date check
    if (widgetType === 'date' || widgetType === 'datetime') {
        return (date) => {
            // console.log({ firstCheck: date });
            // console.log({date, widget});
            if (!date) return;
            // console.log({ typeofDate: typeof date });
            // if (!/^\d+$/.test(date)) {
            //     console.log({ regexCheck: date, rule, widgetType, widgetKey, widget });
            //     // date value not contains only numbers (must be timestamp)
            //     // drops sometimes on manual input ( 01 / 01 / 1970) timestamp = -10800000
            //     return widgetType === 'datetime' ? 'fillCorrectDate' : 'fillCorrectDateNoTime';
            // }
            // check for valid date
            const dateForCheck = new Date(+date);
            // console.log({ dateForCheck });
            if (Object.prototype.toString.call(dateForCheck === '[object Date]')) {
                // it is a date
                if (isNaN(dateForCheck)) {
                    // d.valueOf() could also work
                    // date is not valid
                    // check for string
                    if (typeof date === 'string') {
                        // console.log({ date });
                        const format =
                            widgetType === 'datetime' ? DATE_TIME_VIEW_FORMAT : DATE_VIEW_FORMAT;

                        const isValid = moment(date, format).format(format) === date;
                        if (!isValid) {
                            return widgetType === 'datetime'
                                ? 'fillCorrectDate'
                                : 'fillCorrectDateNoTime';
                        }
                        // console.log({isValid});
                    } else {
                        return widgetType === 'datetime'
                            ? 'fillCorrectDate'
                            : 'fillCorrectDateNoTime';
                    }
                }
            } else {
                // console.log('HERE');
                // not a date
                return widgetType === 'datetime' ? 'fillCorrectDate' : 'fillCorrectDateNoTime';
            }

            if (![null, undefined].includes(date) && widget.maxDateKey) {
                const maxDate = values[widget.maxDateKey];
                if (![null, undefined].includes(maxDate) && date > maxDate) {
                    // console.log("MORE THAN!");
                    return 'startDateInValid';
                }
                // console.log({date, widget, maxDate, values});
            }
            if (![null, undefined].includes(date) && widget.minDateKey) {
                const minDate = values[widget.minDateKey];
                if (![null, undefined].includes(minDate) && date < minDate) {
                    // console.log("LESS THAN!");
                    return 'endDateInValid';
                }
                // console.log({date, widget, minDate, values});
            }
        };
    }
    if (widgetType === 'contact') {
        // console.log({ rule, widgetType, widgetKey, widget });
        const validator =
            CONTACT_ARRAY_VALIDATORS(widget.isReq) &&
            CONTACT_ARRAY_VALIDATORS(widget.isReq)[widgetKey];
        return validator;
    }

    if (rule === '#[callMethod]' || !rule) return;
    // handle function validation
    if (typeof rule === 'function') {
        return () => rule(values);
    }
    // handle regex validation
    const [ message, ...rest ] = rule.split('$');
    const regexp = rest.join("");
    const regex = new RegExp(`^${regexp}$`);
    // const regex = new RegExp(regexp);

    return (value) => {
        if (!value) return;
        if (!regex.test(value)) {
            return message;
        }
    };

};

function validate(values, props) {
    // console.log({ props });
    const { dynData } = props;
    const widgets = [];
    dynData.forEach((param) =>
        widgets.push(
            ...param.widgets.flatMap((initWidget) => {
                let widget = initWidget;

                // handle FieldArray widgets (split into multiple)
                if (param.isFieldArray) {
                    const value = values[param.key];
                    if (Array.isArray(value)) {
                        widget = value.map((val, index) => {
                            if (typeof initWidget === 'function') {
                                return {
                                    ...initWidget(values, index),
                                };
                            }
                            return {
                                ...initWidget,
                                fieldArray: param.isFieldArray && param.key,
                                fieldArrayIndex: index,
                            };
                        });
                        // console.log({widget});
                        return widget.map((wid, index) => ({
                            ...wid,
                            fieldArray: param.isFieldArray && param.key,
                            fieldArrayIndex: index,
                        }));
                    }
                    // if (typeof widget === 'function') {
                    //     if (Array.isArray(value)) {
                    //         return widget.map((wid, index) => ({
                    //             ...wid,
                    //             fieldArray: param.isFieldArray && param.key,
                    //             fieldArrayIndex: index,
                    //         }));
                    //     }
                    // }
                    // return {
                    //     ...widget,
                    //     fieldArray: param.isFieldArray && param.key,
                    // };
                }
                if (!param.isFieldArray && typeof widget === 'function') {
                    widget = initWidget(values);
                }
                return {
                    ...widget,
                    fieldArray: param.isFieldArray && param.key,
                };
            }),
        ),
    );
    // console.log({ widgets });
    const errors = {};
    const required = widgets.map(
        (widget) =>
            !widget.disabled && {
                name: widget.key,
                required: widget.isReq,
                fieldArray: widget.fieldArray,
                fieldArrayIndex: widget.fieldArrayIndex,
                disabled: widget.disabled,
            },
    );
    const rules = widgets.reduce((prev, curr) => {
        return {
            ...prev,
            [`${curr.key}`]:
                !curr.disabled &&
                fieldValidation(curr.rule, curr.widgetType, curr.key, curr, values, props),
        };
    }, {});

    leftTrimProps(values);
    // console.log({ required });
    requiredFieldsValidation(values, required, errors);
    fieldLevelValidation(values, rules, errors);

    // translate errors after validation
    Object.keys(errors).map((key) => {
        const error = errors[key];
        // do not translate errors which are not strings format
        if (typeof error !== 'string') {
            errors[key] = error;
        } else {
            errors[key] = i18next.t(errors[key]);
        }
    });
    // console.log({ errors, values });
    return errors;
}

export default validate;
