import React from 'react';
import PropTypes from 'prop-types';

import FormElementWrapper from './FormElementWrapper';

const TextArea = ({ label, input, meta, liveSaver, tooltip, ...restProps }) => {
    return (
        <FormElementWrapper
            tooltip={tooltip}
            meta={meta}
            label={label}
            liveSaver={liveSaver}
            required={restProps.required}
        >
            <textarea className='input-field' {...input} {...restProps} />
        </FormElementWrapper>
    );
};

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
    label: PropTypes.string
};

TextArea.defaultProps = {
    label: ''
};

export default TextArea;
