export function reactDebounce(f, ms) {
    let timer = null;

    return function() {
        const ev = arguments[0];
        const savedArgs = arguments;

        if (ev.persist) ev.persist();

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            f.apply(this, savedArgs);
            timer = null;
        }, ms);
    };
}

const DEFAULT_ID_KEY = 'id';
export const getIdKey = params => params.defaultId || DEFAULT_ID_KEY;

export const isObject = obj => typeof obj === 'object' && obj !== null && !obj.map;

export const getWidthStyles = width => {
    if (!width) return;
    return { minWidth: `${width}px`, maxWidth: `${width}px`, width: `${width}px` }
};
