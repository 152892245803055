import React from 'react';
import PropTypes from 'prop-types';

import { Button, DynamicFormHook } from '../index';
import { isValidDate, trimValues } from '../../helpers';

import './styles.scss';

const Notification = props => {
    const { t, hasPermission, statusOptions = [], errors, dirty, initialValues, handleAction } = props;

    const getForm = () => ({
        structure: [
            {
                column_count: 1,
                class_names: 'notify-form',
                attrs: [
                    {
                        sname: 'state_code',
                        name: t('status'),
                        disabled: !hasPermission || !initialValues.service,
                        required: true,
                        options: statusOptions,
                        widget_type: 'ComboBox',
                        class_names: 'mid-width'
                    },
                    {
                        sname: 'problem_type',
                        name: t('type'),
                        disabled: !hasPermission,
                        required: true,
                        asyncParams: { key: 'problemType' },
                        labelKey: 'label',
                        valueKey: 'value',
                        widget_type: 'ComboBox',
                        class_names: 'mid-width'
                    },
                    {
                        sname: 'organization',
                        name: t('organization'),
                        disabled: true,
                        widget_type: 'Input',
                        class_names: 'mid-width'
                    },
                    {
                        sname: 'service',
                        name: t('taskInfo'),
                        disabled: !hasPermission,
                        asyncParams: { key: 'specProvider', isActual: 1, limit: 500 },
                        required: true,
                        widget_type: 'ComboBox',
                        class_names: 'mid-width'
                    },
                    {
                        sname: 'problem_name',
                        name: t('theme'),
                        disabled: !hasPermission,
                        required: true,
                        widget_type: 'Input'
                    },
                    {
                        sname: 'problem_description',
                        name: t('description'),
                        disabled: !hasPermission,
                        required: true,
                        widget_type: 'TextArea'
                    },
                    {
                        sname: 'plan_start_date',
                        name: t('activeFrom'),
                        disabled: !hasPermission,
                        required: true,
                        widget_type: 'DatePickerTime',
                        class_names: 'mid-width'
                    },
                    {
                        sname: 'plan_end_date',
                        name: t('activeTo'),
                        disabled: !hasPermission,
                        required: true,
                        widget_type: 'DatePickerTime',
                        class_names: 'mid-width'
                    }
                ]
            }
        ],
        initialValues,
        validate: values => {
            const err = {};
            const vals = trimValues(values);

            if (!vals.state_code) err.state_code = t('requiredField');
            if (!vals.problem_type) err.problem_type = t('requiredField');
            if (!vals.service) err.service = t('requiredField');
            if (!vals.problem_name) err.problem_name = t('requiredField');
            if (!vals.problem_description) err.problem_description = t('requiredField');

            if (!vals.plan_start_date) err.plan_start_date = t('requiredField');
            else if (!isValidDate(vals.plan_start_date)) err.plan_start_date = t('incorrectDate');
            if (!vals.plan_end_date) err.plan_end_date = t('requiredField');
            else if (!isValidDate(vals.plan_end_date)) err.plan_end_date = t('incorrectDate');

            if (vals.plan_start_date && vals.plan_end_date && vals.plan_end_date <= vals.plan_start_date) {
                err.plan_start_date = t('dateNotifyCheck');
            }

            return err;
        }
    });

    return (
        <>
            <DynamicFormHook formName="notify" {...getForm()} withoutLiveSaver withoutCollapse />
            {hasPermission ? (
                <div className="form-controls">
                    <Button className="btn-cancel-square" text={t('cancel')} onClick={() => handleAction('cancel')} />
                    <Button
                        disabled={!!Object.keys(errors).length || !dirty}
                        className="btn-save-square"
                        text={t('save')}
                        onClick={() => handleAction('save')}
                    />
                </div>
            ) : (
                <div className="form-controls">
                    <Button className="btn-save-square" text={t('close')} onClick={() => handleAction('close')} />
                </div>
            )}
        </>
    );
};

export default Notification;

Notification.propTypes = {
    t: PropTypes.func,
    hasPermission: PropTypes.bool,
    statusOptions: PropTypes.array,
    errors: PropTypes.object,
    dirty: PropTypes.bool,
    initialValues: PropTypes.object,
    handleAction: PropTypes.func
};
