import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './styles.scss';

export default (animation = 'fade', visible) => WrappedComponent =>
    class WithTransition extends React.PureComponent {
        state = {
            visible: false,
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            if (typeof visible === 'function') {
                const nextVisible = visible(nextProps);
                if (prevState.visible !== nextVisible) {
                    return { visible: nextVisible };
                }
            }
            return null;
        }

        componentDidMount() {
            if (typeof visible !== 'function') {
                this.setState({ visible: true });
            }
        }

        render() {
            return (
                <CSSTransition
                    in={this.state.visible}
                    timeout={500}
                    classNames={animation}
                    appear
                    enter
                    exit
                    mountOnEnter
                    unmountOnExit
                >
                    <WrappedComponent {...this.props} />
                </CSSTransition>
            );
        }
    };
