import { fetch } from "../decorators";

/* constants */

const initialState = {
    state: null, //  none | auth | reg | passwd (forgot password)
};

const actions = {
    UPDATE_AUTH_STATUS: "UPDATE_AUTH_STATUS",
};

/* reducer */

export default (state = initialState, { type, ...payload }) => {
    if (actions[type]) {
        return { ...state, ...payload };
    }
    return state;
};

/* selectors */

/* action creators */

export const updateAuthStatus = (state) => ({
    type: actions.UPDATE_AUTH_STATUS,
    state,
});

/* thunks */

export const fetchAuthStatus = () => (dispatch) => {
    fetch().send({
        api: { key: "sessionState" },
        onSuccess: ({ result }) => {
            const sessionState = result.value;
            return dispatch(updateAuthStatus(sessionState));
        },
        onFailure: (error) => {
            console.log("Reducers::auth:fetchAuthStatus: Error", error);
            const sessionState = "none";
            return dispatch(updateAuthStatus(sessionState));
        },
    });
};
