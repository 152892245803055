import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { settings } from "../../config";
// import AppSidebar from "./AppSidebar";
import AppSidebar from "../AppSidebar";
import Authorization from "../Authorization";
import Breadcrumbs from "../../containers/Breadcrumbs";

import "./styles.scss";

// not protected routes (default)
const Page404 = lazy(() => import("../../routes/Page404"));

const Registration = lazy(() => import("../../routes/Registration"));
const ForgotPassword = lazy(() => import("../../routes/ForgotPassword"));

const Main = lazy(() => import("../../routes/Main"));
const KnowledgeBase = lazy(() => import("../../routes/KnowledgeBase"));
const Catalog = lazy(() => import("../../routes/Catalog"));
const CatalogItem = lazy(() => import("../../routes/Catalog/CatalogItem"));

// protected routes (received on auth)
const Account = lazy(() => import("../../routes/Account"));

// Appeals
const Appeals = lazy(() => import("../../routes/Appeals/Appeals"));
const Appeal = lazy(() => import("../../routes/Appeals/Appeal"));

const UNPROTECTED_ROUTES = ["registration", "forgotPassword"];

const AppContent = (props) => {
    const { location, history, routes, authState, isAuthorized } = props;

    const [, route] = location.pathname.split("/");

    const isUnprotectedRoute = UNPROTECTED_ROUTES.includes(route);
    const isRouteEnabled = routes.find((item) => {
        if (item.path) {
            return item.path === route;
        }
        if (item.childRoutes) {
            return item.childRoutes.find((childRoute) => childRoute.path === route);
        }

        // for example user was logged in, but not yet clicked on "appeals" to load "child tabs"
        // in such case check if item.name includes route
        // route name for appeals => routes.appeals
        // route => appeals
        if (item.name.includes(route)) {
            return item.name;
        }
        return false;
        // item.path === route;
    });

    // if (authState) {
    if (!isUnprotectedRoute && !isRouteEnabled) {
        if (routes.length > 0) {
            history.push(`/${settings.routeRedirect}`);
        }
    }
    // }

    return (
        <main className="page-body">
            <AppSidebar />
            <div className="content-wrapper">
                {/* authorization block for tablets and mobiles, for pc it is inside of AppSidebar */}
                {!isAuthorized && !settings.authorizationWidgetHideRoutes.includes(location.pathname) && (
                    <Authorization key="mobile-auth" />
                )}
                <Breadcrumbs />
                <Suspense fallback="">
                    <Switch>
                        <Redirect exact from="/" to={settings.routeRedirect} />
                        <Route exact path="/main/" component={Main} />

                        <Route exact path="/knowledgeBase/" component={KnowledgeBase} />
                        <Route exact path="/knowledgeBase/:type?/:id?" component={KnowledgeBase} />

                        {/* <Route exact path="/knowledgeBase/:id?" component={KnowledgeBase} /> */}

                        <Route exact path="/catalog/" component={Catalog} />
                        <Route exact path="/catalog/:id?" component={CatalogItem} />

                        <Route exact path="/account/" component={Account} />

                        <Route exact path="/appeals/" component={Appeals} />
                        <Route exact path="/appeals/:id?" component={Appeal} />
                        <Route exact path="/newAppeal/" component={Appeal} />

                        <Route exact path="/registration/" component={Registration} />
                        <Route exact path="/forgotPassword/" component={ForgotPassword} />
                    </Switch>
                </Suspense>
            </div>
        </main>
    );
};

AppContent.propTypes = {
    location: PropTypes.object,
};

const mapStateToProps = ({ app, auth }) => ({
    routes: app.routes,
    authState: auth.state,
    isAuthorized: auth.state && auth.state === "auth",
});

export default withRouter(connect(mapStateToProps, null)(AppContent));
