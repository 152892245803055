import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { KEY_CODES } from '../../helpers';

const DateInput = props => {
    const {
        fieldKey,
        value,
        onChangeRaw,
        clearable,
        calendar,
        disabled,
        setBlurAction,
        inputFocuc,
        manual,
        ...restProps
    } = props;

    const [val, setVal] = useState(null);
    const inputRef = useRef(null);

    const handleClick = () => setBlurAction(false);

    const handleChange = ev => {
        const { value: targetValue } = ev.target;
        setVal(targetValue);
        setBlurAction(true);
        onChangeRaw(targetValue);
    };

    const handleResetValue = () => onChangeRaw('');

    const handleKeyUp = e => {
        calendar.setOpen(false);
        if (e.keyCode === KEY_CODES.ESC) {
            onChangeRaw(value);
        }
    };

    const handleOpenCalendar = e => {
        setBlurAction(false);
        e.target.className.indexOf('icon-close') === -1 ? calendar.setOpen(true) : handleResetValue();
    };

    const getClearBtn = () => {
        return clearable ? <i className={`icon-close ${fieldKey}`} tabIndex="0" /> : null;
    };

    const getIcons = () => {
        if (disabled) {
            return null;
        }
        return (
            <div className="icon-wrap">
                <i className={`icon-calendar2 ${fieldKey}`} tabIndex="0" />
                {getClearBtn()}
            </div>
        );
    };

    useEffect(() => {
        if (inputFocuc) {
            setBlurAction(true);
            inputRef.current.focus();
            calendar.setOpen(false);
        }
    }, [inputFocuc]);

    useEffect(() => {
        if (val !== value) setVal(value);
    }, [value]);

    return (
        <div className="datepicker-field-wrap" onClick={handleOpenCalendar}>
            <input
                disabled={!manual}
                value={val}
                type="text"
                {...restProps}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                onClick={handleClick}
                ref={inputRef}
            />
            {getIcons()}
        </div>
    );
};

export default DateInput;

DateInput.propTypes = {
    fieldKey: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeRaw: PropTypes.func,
    calendar: PropTypes.object,
    setBlurAction: PropTypes.func,
    isClearable: PropTypes.bool,
    inputFocuc: PropTypes.bool,
    manual: PropTypes.bool
};
