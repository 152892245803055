import { isArray } from 'lodash';

export const forEachTree = (item, callback, level = 1, parentKey = null) => {
    for (const key in item) {
        const { children, ...props } = item[key];
        callback(props, children, level, parentKey);
        if (Array.isArray(children)) forEachTree(children, callback, level + 1, props.key);
    }
};

export const findInTree = (data, sfind) => {
    let elTree;

    const findTree = arr => {
        if (isArray(arr)) {
            for (let i = 0; i < arr.length; i++) {
                if (sfind(arr[i])) {
                    elTree = arr[i];
                    break;
                } else {
                    findTree(arr[i].children);
                }
            }
        }
    };

    findTree(data);

    return elTree || null;
};
