import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

function InputLabel({ readOnly, label, onAddClick, isLoading = false, disabled, required }) {
    const addButtonCondition = !readOnly && onAddClick;
    const labelView = i18next.exists(`customerInfoLabel.${label}`)
        ? i18next.t(`customerInfoLabel.${label}`)
        : label;
    return (
        <header className="header">
            <label className="label">
                {labelView} {!readOnly && required && <span className="required-field">*</span>}
            </label>
            {addButtonCondition && !isLoading && !disabled && (
                <button className="add-button" type="button" onClick={onAddClick}>
                    + {i18next.t('add')}
                </button>
            )}
        </header>
    );
}

InputLabel.propTypes = {
    label: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    onAddClick: PropTypes.func,
};

export default InputLabel;
