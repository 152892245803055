import React, { useEffect, useRef } from 'react';

const Popup = ({children, point, size, style={}, place}) => {
    const pop = useRef(null);
    let beforeClass = '';

    if (point) {
        style.left = `${point.pageX}px`;
        style.top = `${point.pageY}px`;
    }
    if (size) {
        if (size.width) style.width = `${size.width}px`;
        if (size.maxWidth) style.maxWidth = `${size.maxWidth}px`;
        if (size.height) style.height = `${size.height}px`;
    }
    if (point && size) {
        const realLeft = point.pageX + size.width;
        const realTop = point.pageY + size.height;

        if (realLeft > document.documentElement.clientWidth) {
            style.left = `${point.pageX - size.width}px`;
            beforeClass = 'right';
        }
        if (realTop > document.documentElement.clientHeight) {
            style.top = `${point.pageY - size.height}px`;
        }
    }

    useEffect(() => {
        // Need to specify default size end points
        // console.log(pop, place);
    }, []);

    return (
        <div className={`popup ${beforeClass} ${place}`} style={style} ref={pop}>
            {children}
        </div>
    );
}

export default Popup;
