import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactSVG from 'react-svg';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import Portal from '../Portal';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

// import { NotificationTypes } from 'constants/index';

import './styles.scss';

const NotificationTypes = {
    NEW_APPEAL: 'NEW_APPEAL',
    APPEAL_CHANGE_SUCCESS: 'APPEAL_CHANGE_SUCCESS',
    APPEAL_CHANGE_FAILURE: 'APPEAL_CHANGE_FAILURE',
    CURRENT_APPEAL_CHANGE: 'CURRENT_APPEAL_CHANGE',
    NEW_COMMENT: 'NEW_COMMENT',
    HTTP_ERROR: 'HTTP_ERROR',
    SUCCESS: 'SUCCESS',
    SSE_NOTIFICATION: 'SSE_NOTIFICATION',
    SSE_ERROR: 'SSE_ERROR',
    SSE_WARNING: 'SSE_WARNING',
};


/*
   FieldController props:
        * value => current value of field
        * savedValue => saved value of field
        * change => function for changing field value by key (used for resetField action), currently from redux-form
        * handleLiveSubmit => function for handling save request, incoming args: 
            - key => field key
            - responseCallback => callBack for request response (handleResponse function)
        * loader => field loader component 
        * autoSave => boolean for saving field without confirming action on isDirty flag (blur, enter, button click)
        * enterDisabled => boolean for removing submit on enter key (textarea for example)
*/

const CONTROLER_HEIGHT = 32;
const CONTROLER_CHEVRON_HEIGHT = 12;
// const INPUT_HEIGHT = 30;
// const LABEL_HEIGHT = 17;

const CHEVRON_SIZE = 14;
// const CONTAINER_WIDTH = 96;
const CONTAINER_BUTTON_WIDTH = 30; // width + margin
const CONTAINER_PADDING = 6;

const SCROLL_TIMEOUT = 100;

const INPUT_CONTROLLER_DIFFERENCE = 2;

const COPY_DOM_INPUT_VALUE_TYPES = ['date', 'datetime'];
const DATES_WIDGETS = ['date', 'datetime'];

const FieldController = (props) => {
    const {
        t,
        widgetType,
        fieldKey,
        value,
        change,
        error,
        handleLiveSubmit,
        loader,
        autoSave,
        enterDisabled,
        clearable,
        disabled,
        portalPlacement = 'top',
        children,
        showNotification = () => {},
        selectOptions,
        isSaveOnlyValid,
        // handleBlur,
        // handleFocus,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [initialValue, setInitialValue] = useState(value);

    const [portalPosition, setPortalPosition] = useState(null);
    const [isVisited, setIsVisited] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [scrollTimeout, setScrollTimeout] = useState(null);
    // const showControlls =
    //     !disabled && !isScrolling && !isLoading && !autoSave && (isFocused || isDirty || error);
    const showControlls = !disabled && !isScrolling && !isLoading && !autoSave && isFocused;
    const isAnyControllActive = value || isDirty;

    const showSaveControl = isSaveOnlyValid ? !error : true;

    const [controllerParent, setControllerParent] = useState(null);
    const [controllerParentSticky, setControllerParentSticky] = useState(null);
    const [controllerLabel, setControllerLabel] = useState(null);
    const controllerRef = useRef(null);

    const isClearVisible = (Array.isArray(initialValue) ? value.length !== 0 : value) && !disabled;
    const isResetVisible = isDirty;
    const isCopyVisible = Array.isArray(initialValue) ? value.length !== 0 : value;
    const isSubmitVisible = isDirty && handleLiveSubmit && !disabled && showSaveControl;

    let calculatedContainerWidth = 0;
    if (isClearVisible) {
        calculatedContainerWidth += CONTAINER_BUTTON_WIDTH;
    }
    if (isResetVisible) {
        calculatedContainerWidth += CONTAINER_BUTTON_WIDTH;
    }
    if (isCopyVisible) {
        calculatedContainerWidth += CONTAINER_BUTTON_WIDTH;
    }
    if (isSubmitVisible) {
        calculatedContainerWidth += CONTAINER_BUTTON_WIDTH;
    }
    if (calculatedContainerWidth > CONTAINER_BUTTON_WIDTH) {
        calculatedContainerWidth += CONTAINER_PADDING;
    }

    const calculatedPosition = useMemo(() => {
        if (!portalPosition) {
            return {};
        }
        // const fieldLabel = controllerRef.current.querySelector('.input-label');
        let labelHeight = 0;
        if (controllerLabel) {
            labelHeight = controllerLabel.getBoundingClientRect().height;
            const styles = getComputedStyle(controllerLabel);
            const marginBottom = parseInt(styles.getPropertyValue('margin-bottom'));
            if (marginBottom) {
                labelHeight = labelHeight + marginBottom;
            }
        }
        if (portalPlacement === 'top') {
            // handle top
            return {
                // top: portalPosition.top - INPUT_HEIGHT,
                // top: portalPosition.top - labelHeight,
                top: portalPosition.top - CONTROLER_HEIGHT,
                left: portalPosition.right - calculatedContainerWidth,
            };
        }
        if (portalPlacement === 'right') {
            // handle right
            return {
                // top: portalPosition.bottom - INPUT_HEIGHT,
                // top: portalPosition.top - LABEL_HEIGHT,
                top: portalPosition.top - INPUT_CONTROLLER_DIFFERENCE + labelHeight,
                left: portalPosition.right + CHEVRON_SIZE,
            };
        }
        return portalPosition;
    }, [
        portalPosition,
        isClearVisible,
        isResetVisible,
        isCopyVisible,
        isSubmitVisible,
        controllerLabel,
    ]);

    const isVisible = (ele, container, stickyContainer) => {
        const { bottom: elementBottom, top: elementTop } = ele.getBoundingClientRect();
        // const { bottom: elementBottom, height, top: elementTop } = ele.getBoundingClientRect();
        const height = CONTROLER_HEIGHT + CONTROLER_CHEVRON_HEIGHT;
        const containerRect = container.getBoundingClientRect();
        let stickyContainerHeight = 0;
        if (stickyContainer) {
            stickyContainerHeight = stickyContainer.getBoundingClientRect().height;
            // console.log({ stickyContainerHeight });
        }
        const top = ['right', 'left'].includes(portalPlacement)
            ? elementTop - height / 2 - stickyContainerHeight - CONTROLER_CHEVRON_HEIGHT
            : elementTop - height - stickyContainerHeight;
        const bottom = elementBottom + CONTROLER_HEIGHT;

        return top <= containerRect.top
            ? containerRect.top - top <= height
            : bottom - containerRect.bottom <= height;
    };

    const isPortalVisible = useMemo(() => {
        if (controllerRef && controllerRef.current && controllerParent) {
            // console.log({ isVisible: isVisible(controllerRef.current, controllerParent) });
            return isVisible(controllerRef.current, controllerParent, controllerParentSticky);
        }
        // console.log({ calculatedPosition, portalTopLimit });
        // return (
        //     calculatedPosition.top > portalTopLimit && calculatedPosition.top < portalBottomLimit
        // );
    }, [calculatedPosition, controllerRef, controllerParent, controllerParentSticky]);

    // if (fieldKey === 'lastName' || fieldKey === 'firstName') {
    //     console.log({ isScrolling, showControlls, isAnyControllActive, fieldKey, isPortalVisible });
    //     // console.log({ fieldKey, isScrolling, isLoading, autoSave, isFocused, isDirty, error });
    // }

    // const [datePickerTarget, setDatePickerTarget] = useState(null);

    const handleResponse = (success) => {
        // update initial value if response succeeded
        if (success) {
            setInitialValue(value);
            setIsDirty(false);
            setIsFocused(false);
        }
        setIsLoading(false);
    };

    const submitField = (forceSubmit) => {
        if (isLoading) {
            return;
        }
        if (!handleLiveSubmit) {
            return;
        }
        if (isSaveOnlyValid && error) {
            return;
        }
        if (isDirty || forceSubmit) {
            setIsLoading(true);
            handleLiveSubmit(fieldKey, handleResponse);
        }
    };

    const resetField = () => change([fieldKey], initialValue);

    const clearField = () => change([fieldKey], Array.isArray(initialValue) ? [] : null);

    const getCopyValue = useCallback(() => {
        let copyValue = value;
        if (value && typeof value === 'object') {
            if (value && value.label) {
                copyValue = value.label;
            } else if (value.value) {
                copyValue = JSON.stringify(value);
            }
        }

        // copy value for select/combo etc
        if (selectOptions && selectOptions.length > 0) {
            const valueToCheck = value && typeof value === 'object' ? value.value : value;
            const option = selectOptions.find((option) => option.value === valueToCheck);
            copyValue = option ? option.label : value;
        }

        // copy value for date/datetime or whatever from document input element
        if (
            COPY_DOM_INPUT_VALUE_TYPES.includes(widgetType) &&
            controllerRef &&
            controllerRef.current
        ) {
            const input = controllerRef.current.querySelector('input[name="' + fieldKey + '"]');
            // const input = document.querySelector('input[name="' + fieldKey + '"]');
            if (input) {
                copyValue = input.value;
            }
        }

        // map array values with options labels
        if (Array.isArray(value) && selectOptions) {
            copyValue = value.map(
                (item) => selectOptions.find((option) => option.value === item).label,
            );
        }
        // console.log({value, copyVal: get(copyValue, 'label', copyValue)});
        return get(copyValue, 'label', copyValue);
    }, [isFocused, isDirty, fieldKey, selectOptions, value, widgetType, controllerRef.current]);

    const valueToCopy = useMemo(() => {
        // console.log({valueToCopy: getCopyValue()});
        return getCopyValue();
    }, [getCopyValue]);

    const handleCopyValue = () => {
        const copyValue = getCopyValue();
        // console.log({ props });
        // let copyValue = typeof value === 'object' ? JSON.stringify(value) : value;

        // if (clipboard) {
        navigator.clipboard.writeText(copyValue).then(
            () => {
                console.info(
                    'FieldController::handleCopyValue: Copying to clipboard was successful!',
                );
                showNotification({
                    type: NotificationTypes.SUCCESS,
                    options: { message: `${t('copyToClipboardSuccess')}` },
                });
            },
            (err) => {
                console.error(
                    'FieldController::handleCopyValue: Could not copy to clipboard: ',
                    err,
                );
                showNotification({
                    type: NotificationTypes.HTTP_ERROR,
                    options: { message: `${t('copyToClipboardFailure')}` },
                });
            },
        );
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 9 && isFocused) onClickOutside(); // TAB
        if (e.keyCode === 13 && !enterDisabled) submitField(); // ENTER
        if (e.keyCode === 27) resetField(); // ESCAPE
    };

    const updateRefPosition = () => {
        if (controllerRef && controllerRef.current) {
            const controllerRect = controllerRef.current.getBoundingClientRect();
            const { top, bottom, right, left } = controllerRect;
            setPortalPosition({ top, bottom, right, left });
        } else {
            console.error('FieldController::updateRefPosition: ref current is not yet available');
        }
    };

    const handleScroll = useCallback((event) => {
        setIsScrolling(true);
        setScrollTimeout(clearTimeout(scrollTimeout));
        setScrollTimeout(
            setTimeout(() => {
                setIsScrolling(false);
                updateRefPosition();
            }, SCROLL_TIMEOUT),
        );
    }, []);

    const findControllerParent = () => {
        if (controllerRef && controllerRef.current) {
            let offsetParent = controllerRef.current.offsetParent;
            while (offsetParent) {
                const styles = getComputedStyle(offsetParent);
                const overflow = styles.getPropertyValue('overflow');
                // console.log({ offsetParent });
                // console.log({ styles, overflow });
                if ((overflow && overflow.includes('auto')) || overflow.includes('scroll')) {
                    // console.log({ offsetParent });
                    // console.log({ styles, overflow });
                    break;
                }
                // console.log({offsetParent});
                offsetParent = offsetParent.offsetParent;
            }
            if (!offsetParent) {
                // offsetParent = document;
            }
            // console.log({ offsetParent, fieldKey });
            setControllerParent(offsetParent);
        }
    };

    const findControllerLabel = () => {
        if (controllerRef && controllerRef.current) {
            const fieldLabel = controllerRef.current.querySelector('.input-label');
            setControllerLabel(fieldLabel);
        }
    };

    const findControllerParentSticky = (element) => {
        if (element) {
            let children = Array.from(element.children);
            while (children.length) {
                const child = children.shift();
                const styles = getComputedStyle(child);
                const position = styles.getPropertyValue('position');
                if (position && position === 'sticky') {
                    setControllerParentSticky(child);
                    break;
                }
                if (child.children) {
                    children = [...children, ...child.children];
                }
            }
        }
    };

    const handleFocus = (e) => {
        if (isLoading) {
            return;
        }
        if (typeof props.handleFocus === 'function' && props.handleFocus) {
            props.handleFocus(e);
        }
        if (!controllerParent) {
            findControllerParent();
            findControllerLabel();
        }
        setIsFocused(true);
        document.addEventListener('scroll', handleScroll, true);
        setTimeout(updateRefPosition, 0);
    };

    const blurAll = () => {
        if (controllerRef.current) {
            const tmp = document.createElement('input');
            controllerRef.current.appendChild(tmp);
            tmp.focus();
            controllerRef.current.removeChild(tmp);
        }
    };

    const onClickOutside = (e) => {
        if (isFocused) {
            if (typeof props.handleBlur === 'function' && props.handleBlur) {
                props.handleBlur(e);
            }
            setIsVisited(true);
        }
        // if (error) {
        //     return;
        // }
        // if (isFocused) {
        //     console.log("*** REMOVE SCROLL ***");
        //     document.removeEventListener('scroll', handleScroll, true);
        // }
        // if (!e) {
        //     setIsFocused(false);
        //     submitField();
        //     return;
        // }
        let isFieldControllerParent = false;
        const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
        // if (isFocused) {
        //     console.log({ path, target: e.target });
        // }
        for (let i = 0; i < path.length; i++) {
            if (path[i].getAttribute && path[i].getAttribute('data-field-key')) {
                isFieldControllerParent = path[i].getAttribute('data-field-key') === fieldKey;
                break;
            }
        }
        if (isFieldControllerParent) {
            return;
        }
        if (isFocused) {
            // blurAll();
            document.removeEventListener('scroll', handleScroll, true);
        }
        setIsFocused(false);
        submitField();
    };

    useEffect(() => {
        if (Array.isArray(value) && Array.isArray(initialValue)) {
            const isSomeInitMissed = initialValue.some((item) => !value.includes(item));
            const isSomeAdded = value.some((item) => !initialValue.includes(item));
            if (isSomeInitMissed || isSomeAdded) {
                return setIsDirty(true);
            }
            return setIsDirty(false);
        }
        if ([null, '', undefined].includes(value) && [null, '', undefined].includes(initialValue)) {
            return setIsDirty(false);
        }
        if (DATES_WIDGETS.includes(widgetType)) {
            // extra check for dates values (compare parsed values)
            const parsedVal = +value;
            const parsedInitVal = +initialValue;
            // perhaps use in future not strict != comparison operator
            if (value !== initialValue && parsedVal !== parsedInitVal) {
                return setIsDirty(true);
            }
        } else {
            // default case
            if (value !== initialValue) {
                return setIsDirty(true);
            }
        }
        setIsDirty(false);
    }, [value]);

    useEffect(() => {
        // handle FileInput, Rank and Radio autosave without buttons
        if (isDirty && autoSave) {
            setIsDirty(false);
            submitField(true);
        }
        // if (isDirty) {
        //     console.log({ isDirty, fieldKey, initialValue, value });
        // }
        // console.log({ isDirty, fieldKey, initialValue, value });
    }, [isDirty, autoSave]);

    useEffect(() => {
        if (controllerParent) {
            findControllerParentSticky(controllerParent);
        }
    }, [controllerParent]);

    useEffect(() => {
        // console.log({props, clearable});
        return () => {
            // console.log('*** REMOVE SCROLL ***');
            document.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    // extend children with disabled via isLoading state;
    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled: child.props.disabled || isLoading });
        }
        return child;
    });

    return (
        <ClickOutsideHolder onClickOutside={onClickOutside}>
            <div
                className={cx('field-controller', {
                    'is-focused': isFocused,
                    disabled,
                    'is-loading': isLoading,
                    'with-label': controllerLabel,
                    'is-error': isVisited && error && !disabled,
                })}
                data-field-key={fieldKey}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                ref={controllerRef}
                // title={error || (!isFocused && valueToCopy)}
                title={!isFocused && ((isVisited && error) || valueToCopy)}
                key={`${fieldKey}_saver`}
            >
                {isLoading && loader}
                {/* temporarily disable controls panel so both ordering DynamicForm and DynamicFormHook will have similar look */}
                {/* {showControlls && isAnyControllActive && isPortalVisible && (
                    <Portal
                        className="field-controller-portal"
                        dataFieldKey={fieldKey}
                        position={calculatedPosition}
                    >
                        <div className={`field-controller-buttons ${portalPlacement}`}>
                            {isClearVisible && (
                                <div
                                    className="button-hover-circle with-right-border"
                                    onClick={clearField}
                                >
                                    <ReactSVG
                                        className="button-icon"
                                        src={`./images/icons/input_clear.svg`}
                                    />
                                </div>
                            )}
                            {isResetVisible && (
                                <div className="button-hover-circle" onClick={resetField}>
                                    <ReactSVG
                                        className="button-icon"
                                        src={`./images/icons/input_undo.svg`}
                                    />
                                </div>
                            )}
                            {isCopyVisible && (
                                <div className="button-hover-circle" onClick={handleCopyValue}>
                                    <ReactSVG
                                        className="button-icon"
                                        src={`./images/icons/input_copy.svg`}
                                    />
                                </div>
                            )}
                            {isSubmitVisible && (
                                <div className="button-hover-circle" onClick={submitField}>
                                    <ReactSVG
                                        className="button-icon"
                                        src={`./images/icons/input_save.svg`}
                                    />
                                </div>
                            )}
                        </div>
                    </Portal>
                )} */}
                {childrenWithProps}
                {/* {error && !disabled && (isVisited || isFocused) && ( */}
                {error && !disabled && isVisited && (
                    <div className="field-controller-error">{error}</div>
                )}
            </div>
        </ClickOutsideHolder>
    );
};

export default withTranslation()(FieldController);

// this.container = document.getElementById('modal-root');

// return ReactDOM.createPortal(
//     <div ref={this.modal} data-field-key={fieldKey}>
//         {this.props.children}
//     </div>,
//     this.container
// );
