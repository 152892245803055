/* constants */

const initialState = {
    translation: {},
    customBreadcrumbs: null,
};

const actions = {
    SET_BREADCRUMBS: 'SET_BREADCRUMBS'
};

/* reducer */

export default (state = initialState, { type, ...payload }) => {
    if (actions[type]) {
        return { ...state, ...payload };
    }
    return state;
};
/* action creators */

export const setBreadcrumbs = data => ({
    type: actions.SET_BREADCRUMBS,
    ...data
});

