import { isEqual } from 'lodash';
import { dataPropsInstances } from '../config';
import { prepareGrids, prepareFilters } from '../helpers';
import { storage } from '../services';

const getGridsStorageObject = () => storage.get(storage.KEYS.GRIDS);
const getFiltersStorageObject = () => storage.get(storage.KEYS.FILTERS);
const localStorageGridChange = (key, data) =>
    storage.set(storage.KEYS.GRIDS, {
        ...getGridsStorageObject(),
        [key]: data
    });
const localStorageFilterChange = filters => storage.set(storage.KEYS.FILTERS, filters);

/* initialState */
const initialState = {
    grids: prepareGrids(dataPropsInstances, getGridsStorageObject() || {}),
    filters: prepareFilters(getFiltersStorageObject())
};

/* selectors */

/* actions */

const actions = {
    SET_EXTENDED_FILTER: 'SET_EXTENDED_FILTER',
    UPDATE_GRID_FIELDS: 'UPDATE_GRID_FIELDS'
};

/* reducer */

export default (state = initialState, { type, gridKey, params, key, values, options }) => {
    if (actions[type]) {
        if (type === actions.UPDATE_GRID_FIELDS) {
            const grid = state.grids[gridKey] ? state.grids[gridKey] : {};
            const data = { ...grid, fields: params.fields };

            if (grid.version) localStorageGridChange(gridKey, data);
            return {
                ...state,
                grids: {
                    ...state.grids,
                    [gridKey]: data
                }
            };
        }
        if (type === actions.SET_EXTENDED_FILTER) {
            const data = values ? { init: values, options } : prepareFilters()[key];
            const filters = {
                ...state.filters,
                [key]: data,
                [`active_${key}`]: values ? !isEqual(data, prepareFilters()[key]) : false
            };
            if (values) localStorageFilterChange(filters);

            return { ...state, filters };
        }
    }
    return state;
};

/* action creators */

export const saveFilterStore = (key, values, options) => {
    return {
        type: actions.SET_EXTENDED_FILTER,
        key,
        values,
        options
    };
};
