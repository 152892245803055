import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tree, { TreeNode } from 'rc-tree';

class menuRenderer extends Component {
    onSelect = (value, event) => {
        const selected = event.selectedNodes.pop();
        const { valueKey, labelKey, selectOption, selectNode } = this.props;

        if (!selected || !event.selected || (!selectNode && !selected.props.isLeaf)) return null;
        return selectOption({ [valueKey]: +selected.key, [labelKey]: selected.props.title }, event);
    };

    getTreeChildren = options => {
        const nodes = [];

        options.forEach(option => {
            const { children, ...restProps } = option;
            const { valueKey, labelKey, value, multi } = this.props;

            let nodeChildren = null;
            if (children && children.length) {
                nodeChildren = this.getTreeChildren(children);
            }
            if (!multi || (multi && !value.includes(option.id))) {
                nodes.push(
                    React.createElement(
                        TreeNode,
                        {
                            ...restProps,
                            title: option[labelKey],
                            key: option[valueKey],
                            isLeaf: !children
                        },
                        nodeChildren
                    )
                );
            }
        });
        return nodes;
    };

    getDefaultSelectedKeys = () => {
        const { value } = this.props;
        if (!value) return [];
        if (Array.isArray(value)) {
            return value.map(item => {
                if (typeof item === 'number') {
                    return String(item);
                }
                return item;
            });
        }
        return [String(value)];
    };

    render() {
        const { options, multi } = this.props;
        const TreeProps = {
            showLine: true,
            defaultExpandAll: true,
            defaultSelectedKeys: this.getDefaultSelectedKeys(),
            // {...props}
            onSelect: this.onSelect
        };
        if (multi) TreeProps.multiple = true;
        return <Tree {...TreeProps}>{this.getTreeChildren(options)}</Tree>;
    }
}

menuRenderer.propTypes = {
    // from react-select
    focusOption: PropTypes.func,
    focusedOption: PropTypes.object,
    inputValue: PropTypes.string,
    instancePrefix: PropTypes.string,
    onFocus: PropTypes.func,
    onOptionRef: PropTypes.func,
    onSelect: PropTypes.func,
    optionClassName: PropTypes.string,
    optionComponent: PropTypes.func,
    optionRenderer: PropTypes.func,
    options: PropTypes.array,
    removeValue: PropTypes.func,
    selectValue: PropTypes.func,
    valueArray: PropTypes.array,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    // my
    rest: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array])
};

export default menuRenderer;
