/**
 * INPUT PARAMETERS
{
    classNames: string               // apply external class grid
    onChangeFields: external function that handles changes in columns.
    selectItem: external function  // Replace standard behaviour select row and prevent any events like                                                 (footer.submit, edit). Incoming {ITEM} (selected object from table)
    add: external function,        // will appear 'ADD' button
    edit: external function,       // will appear 'EDIT' button if row selected
    download: external function,   // will appear icon "Download"
    upload: external function,     // will appear icon 'Upload'
    lastColumn: external function to show component it last column of each table row (takes all row data as a param and returns component)
    // groups: true,
    filter: external function,     // will appear 'search' input
    // save: this.save,
    // limitFields: ['type', 'lastEditDate'],
    handleSort: external function,
    total: external data on the general records number in response,
    t: external function for translating
    fields: {
        attach_id: {
            name: t('ID')
        },
        attach_type_id: {
            name: 'type',               // required! Name of column.
            classWidth: widths.min,     // required! number indicating the column width type (0..2)
            format: 'date',             // indicates if the original value should be changed to a different format ('string', 'number', 'date', 'translate')
            link: '/documents'          // link to be redirected after a click
            html: true,                 // flag indicating that the content should be output as html
            noSorted: true,             // flag for banning column sorting
            noDnD: true                 // flag for banning drag and drop
            hidden: false,              // hiding flag
            permanent: true,            // flag for hiding columns
            value: data => data.id      // function that returns a value for a cell as a result of incoming data
            component: <p>{data}</p>    // component that will be inserted in the cell
        },
        ...
        tr: {
            dblClick: external function
        },
        footer: {                      // object, if footer exist render grid Footer Component
            submit: external function,
            cancel: external function
        },
        groups: {
            form: component with form: <Here />,,
            checkedItems: array of checked items: [],
            handleCheck: external function for setting checked items (apply automatically in GridWrapper ('setCheckedItems' ffm-ui)),
        }
    },
    options: {
        selected: <object> for display selected item by default,
        mountSelect: <bool> for calling "selectItem" function after mounting,
        multiSelect: <bool> gives the opportunity for multi row selection,
        withoutColumnsMenu: <bool> don't show columns menu,
        noResize: <bool> Don't resixe columns intable
    }
}
 **/

import React from 'react';
import PropTypes from 'prop-types';

import DataManager from './DataManager';
import GridTable from './GridTable';
import Header from './Header';
import Footer from './Footer';
import FormsWrapper from './FormsWrapper';
import { getIdKey } from './helpers';

class Grid extends React.Component {
    constructor(props) {
        super(props);
        const { params, data } = props;
        const { selected, mountSelect, multiSelect } = params.options;
        const initialState = {
            data,
            selected,
            multiSelected: selected ? [selected] : [],
            isAppealFormOpened: true,
            mountSelect,
            multiSelect,
            columnsHideMenuIsOpen: false,
            gridId: Math.floor(Math.random() * 1000)
        };
        if (!params.sort || !params.sort.onChange) {
            initialState.sort = {};
        }
        this.state = initialState;
    }

    openColumnsHideMenu = () => {
        this.setState({ columnsHideMenuIsOpen: !this.state.columnsHideMenuIsOpen });
    };

    closeColumnsHideMenu = () => {
        this.setState({ columnsHideMenuIsOpen: false });
    };

    toggleAppealForm = () => {
        this.setState(prevState => ({ isAppealFormOpened: !prevState.isAppealFormOpened }));
    };

    filter = (val, key) => {
        let add = false;

        for (let i in val) {
            if (!add && val.hasOwnProperty(i)) {
                add = add || (val[i] + '').includes(key);
            }
        }

        return add;
    };

    filterData = key => {
        const { data } = this.props;

        this.setState({ data: data.filter(val => this.filter(val, key)) });
    };

    // TODO functionality not used, refactor when needed
    filterFields = field => {
        console.error('function working not correct, need refactoring');
        // const { fields } = this.props.params;
        // let newFields = {};
        //
        // if (field !== 'all_fields') {
        //     for (let i in fields) {
        //         if (i === field) {
        //             newFields[i] = fields[i];
        //         }
        //     }
        // } else {
        //     newFields = this.props.params.fields;
        // }
        //
        // this.setState({ params: { ...this.state.params, fields: newFields } });
    };

    selectItem = (item, e = {}) => {
        const { selected: prevSelected, multiSelected, multiSelect } = this.state;
        const { params } = this.props;
        const selected = { ...item };
        const multi = [...multiSelected];

        if (params.defaultId) {
            selected.id = item[getIdKey(params)];
        }

        if (multiSelect && e.ctrlKey) {
            const selIndex = multi.findIndex(v => v[getIdKey(params)] === selected.id);
            if (selIndex === -1) {
                multi.push(selected);
            } else multi.splice(selIndex, 1);
        } else multi.splice(0, multi.length, selected);

        if (selected.id || selected.id === 0) {
            this.setState({ selected, multiSelected: multi });

            if (params.selectItem) {
                params.selectItem(selected, multi);
            }
        }
    };

    transformMultiSelected = () => {
        const { multiSelected } = this.state;
        const { params } = this.props;
        const ids = [];

        multiSelected.forEach(v => ids.push(v[getIdKey(params)]));
        return ids;
    };

    handleSort = ({ field, order }) => {
        const { data, params: { sort } } = this.props;

        // if it's server side sorting
        if (sort && sort.onChange) return sort.onChange({ field, order });

        // if it's simple sorting
        return this.setState({
            data: data.sort((a, b) => {
                const direct = order === 'desc' ? 1 : -1;
                return a[field] > b[field] ? direct : -direct;
            }),
            sort: { field, order }
        });
    };

    handleCheck = e => {
        const { value, checked } = e.target;
        const itemId = parseInt(value);
        const { checkedItems, handleCheck } = this.props.params.groups;
        const items = checked
            ? [...checkedItems, itemId]
            : checkedItems.filter(item => item !== itemId);

        handleCheck({ checkedItems: items });

        if (!checkedItems.length && items.length) {
            this.setState({ isAppealFormOpened: true });
        }
    };

    componentDidMount() {
        const { selected } = this.props.params.options;
        const { mountSelect, data } = this.state;

        if (mountSelect) {
            this.selectItem(selected || data[0], {});
            this.setState({ mountSelect: false });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { data, params } = nextProps;
        // const { selected } = params.options;
        // const { selected: oldSelected } = this.state;

        this.setState({ data });

        // if (selected && selected.id !== oldSelected.id) {
        //     this.selectItem(params.options.selected);
        // }
    }

    render = () => {
        const { data, selected, isAppealFormOpened, sort: stateSort, columnsHideMenuIsOpen, gridId } = this.state;
        const { params, toggleRowVisibility } = this.props;
        const classGrid = params.classNames ? `grid ${params.classNames}` : 'grid normal';

        return (
            <div className={classGrid} id={gridId}>
                <Header
                    params={params}
                    selected={selected}
                    filterData={this.filterData}
                    filterFields={this.filterFields}
                />
                <GridTable
                    gridId={gridId}
                    params={params}
                    data={data}
                    selected={selected}
                    multiSelected={this.transformMultiSelected()}
                    sort={{
                        ...stateSort,
                        ...params.sort,
                        handleSort: this.handleSort
                    }}
                    selectItem={this.selectItem}
                    toggleRowVisibility={toggleRowVisibility}
                    columnsMenu={{
                        isOpen: columnsHideMenuIsOpen,
                        open: this.openColumnsHideMenu,
                        close: this.closeColumnsHideMenu
                    }}
                    {...this.props.params.groups && {
                        groups: {
                            ...this.props.params.groups,
                            handleCheck: this.handleCheck
                        }
                    }}
                />
                <Footer params={params} selected={selected} />

                {this.props.params.groups && (
                    <FormsWrapper
                        {...this.props.params.groups}
                        filterOnlyCheckedItems={this.filterOnlyCheckedItems}
                        toggleAppealForm={this.toggleAppealForm}
                        isAppealFormOpened={isAppealFormOpened}
                    />
                )}

                <div className={columnsHideMenuIsOpen && `popup-window-overlay`} />
            </div>
        );
    };
}

Grid.propTypes = {
    data: PropTypes.array,
    params: PropTypes.object.isRequired,
    // from DataManager
    toggleRowVisibility: PropTypes.func
};

export default DataManager(Grid);
