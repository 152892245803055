import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClickOutSideHolder from '../ClickOutSideHolder';

class ComboBoxMultipleCheckbox extends Component {
    state = {
        showOptions: false
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.showOptions && this.state.showOptions) {
            const select = document.querySelector('.optionsWrapper');
            if (select && select.scrollIntoView) select.scrollIntoView(false);
        }
    };

    getLabel = value => {
        const { options } = this.props;
        const option = options.find(option => option.value === value);
        return option && option.label;
    };

    handleChange = (event, option) => {
        const { input } = this.props;
        const newValue = [...input.value];
        if (event.target.checked) {
            newValue.push(option);
        } else {
            newValue.splice(newValue.indexOf(option), 1);
        }
        this.setState({ value: newValue });
        return input.onChange(newValue);
    };

    toggleOptions = () => {
        const { disabled } = this.props;
        if (!disabled) {
            this.setState({ showOptions: !this.state.showOptions });
        }
    };

    renderCheckboxGroup = () => {
        const { options, input, disabled } = this.props;

        return (
            <div className="optionsWrapper">
                {options.map((option, index) => (
                    <div className="checkbox" key={index}>
                        <label htmlFor={option.value}>
                            <input
                                type="checkbox"
                                name={`${input.label}[${index}]`}
                                id={option.value}
                                checked={input.value.indexOf(option.value) !== -1}
                                onChange={event => this.handleChange(event, option.value)}
                                disabled={disabled}
                            />
                            <span className="checkbox-text">{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const { meta } = this.props;
        const wrapperClassName = cx('input-element comboBox-multi-checkbox', {
            isOpen: this.state.showOptions
        });
        const buttonClassName = cx('input-field', {
            'input-field__error': meta && meta.touched && meta.error
        });
        return (
            <ClickOutSideHolder
                className={wrapperClassName}
                onClickOutside={() => this.setState({ showOptions: false })}
            >
                <button className={buttonClassName} onClick={this.toggleOptions} type="button">
                    {this.props.input.value
                        ? this.props.input.value.map((item, index, arr) => (
                              <span key={index} className="input-value">
                                  {this.getLabel(item)}
                                  {arr.length > 1 && index !== arr.length - 1 && ', '}{' '}
                              </span>
                          ))
                        : this.props.placeholder}
                </button>
                {this.state.showOptions && this.renderCheckboxGroup()}
            </ClickOutSideHolder>
        );
    }
}

ComboBoxMultipleCheckbox.displayName = 'ComboBoxMultipleCheckbox';

ComboBoxMultipleCheckbox.propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    meta: PropTypes.shape({
        error: PropTypes.string,
        label: PropTypes.string,
        touched: PropTypes.bool
    }).isRequired
};

export default ComboBoxMultipleCheckbox;
