import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getTKey } from '../tKeys';

class Exports extends Component {
    state = {
        isOpen: false
    };

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClosePopup, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClosePopup, false);
    }

    getItems = () => {
        const { exports, t, tKeys } = this.props;
        const items = [];
        if (exports.csv) {
            items.push(
                <li key="export_csv">
                    <a onClick={exports.csv}>{`${t(getTKey(tKeys, 'export'))} ${t(getTKey(tKeys, 'csv'))}`}</a>
                </li>
            );
        }
        if (exports.excel) {
            items.push(
                <li key="export_excel">
                    <a onClick={exports.excel}>{`${t(getTKey(tKeys, 'export'))} ${t(getTKey(tKeys, 'excel'))}`}</a>
                </li>
            );
        }

        return items.length ? items : null;
    };

    handleClosePopup = ev => {
        if (!this.popup || this.popup.contains(ev.target)) return;
        this.setState({ isOpen: false });
    };

    handleOpenPopup = () => this.setState({ isOpen: true });

    render() {
        const { exportSize } = this.props;
        if (!exportSize) return null;
        if (!this.state.isOpen) {
            return <i onClick={this.handleOpenPopup} className="icon-kebab-vert" />;
        }
        return (
            <i onClick={this.handleOpenPopup} className="icon-kebab-vert">
                <div className="popup-wrapper">
                    <nav className="popup popup-nav" ref={node => this.popup = node}>
                        {this.getItems()}
                    </nav>
                </div>
            </i>
        );
    }
}

Exports.propTypes = {
    t: PropTypes.func,
    tKeys: PropTypes.object,
    exports: PropTypes.object,
    exportSize: PropTypes.number
};
Exports.defaultProps = {
    t: null,
    tKeys: {},
    exports: null,
    exportSize: 0
};

export default Exports;
