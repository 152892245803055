import React from "react";
// import PropTypes from 'prop-types';
import ReactSVG from "react-svg";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import Loader from "../Loader";

import "./styles.scss";

const AsideTabs = (props) => {
    const { t, routes, history, isLoading, onRouteWithChildClick } = props;

    const [, route, subRoute] = history.location.pathname.split("/");

    const getParentTabClassName = (tab) => {
        const { path, disabled, childRoutes, isOpen } = tab;
        let className = "aside-parent-tab";

        const isKnowledgeBase = path === "knowledgeBase";

        if (childRoutes) {
            const routeToCheck = subRoute || route;
            const isChildRouteActive = childRoutes.some((route) => route.path === routeToCheck);
            if (isChildRouteActive && !isOpen) {
                className += " active";
            }
        }

        if (path === route && !isKnowledgeBase) {
            className += " active";
        }

        if (path === route && isKnowledgeBase && !isOpen) {
            className += " active";
        }

        if (disabled) {
            className += " disabled";
        }

        if (isOpen) {
            className += " open";
        }

        return className;
    };

    const getChildTabClassName = (tab) => {
        const { path, disabled, icon, isSticky } = tab;
        let className = "aside-child-tab";

        const isKnowledgeBase = route === "knowledgeBase";

        if (isKnowledgeBase) {
            if (subRoute && path === subRoute) {
                className += " active";
            }
            if (!subRoute && path === route) {
                className += " active";
            }
        } else {
            if (subRoute && path === subRoute) {
                className += " active";
            } else if (path === route) {
                className += " active";
            }
        }

        if (disabled) {
            className += " disabled";
        }

        if (icon) {
            className += " with-icon";
        }

        if (isSticky) {
            className += " sticky";
        }

        return className;
    };

    const getChildTabWrapperClassName = (tab) => {
        const { isSticky } = tab;
        let className = "aside-child-tab-wrapper";

        if (isSticky) {
            className += " sticky";
        }

        return className;
    };

    const handleParentTabClick = (tab) => {
        const { path, hasChild } = tab;
        // const isKnowledgeBase = path === "knowledgeBase";
        // if ((isKnowledgeBase || hasChild) && onRouteWithChildClick && typeof onRouteWithChildClick === "function") {
        //     onRouteWithChildClick(tab);
        // }
        if (hasChild && onRouteWithChildClick && typeof onRouteWithChildClick === "function") {
            onRouteWithChildClick(tab);
        }
        if (path) {
            history.push(`/${path}`);
        }
    };

    const handleChildTabClick = (tab) => {
        const { path, parentPath, children } = tab;

        if (parentPath === "knowledgeBase") {
            // if (!children) {
            return history.push({ pathname: `/${parentPath}/${path}`, state: tab });
            // return;
            // } else {
            // onKnowledgeBaseFolderClick(tab);
            // }
            // return;
        }

        if (path) {
            history.push({ pathname: `/${path}`, state: tab });
            // if (parentPath) {
            //     history.push({ pathname: `/${parentPath}/${path}`, state: tab });
            // } else {
            //     history.push({ pathname: `/${path}`, state: tab });
            // }
        }
    };

    const renderChildTab = (tab) => {
        const { id, name, icon, children, isOpen } = tab;
        return (
            <div className={getChildTabWrapperClassName(tab)}>
                <div className={getChildTabClassName(tab)} onClick={() => handleChildTabClick(tab)} key={id}>
                    <div className="tab-info">
                        {icon && <ReactSVG className="tab-logo-svg" src={`./images/icons/${icon}.svg`} />}
                        <span className="tab-name">{t(name)}</span>
                    </div>
                </div>
                <div className="aside-child-tab-children">
                    {isOpen && children.map(renderChildTab)}
                    {isOpen && children.length === 0 && <div className="aside-child-tab" />}
                </div>
            </div>
        );
    };

    const renderParentTabs = (tab) => {
        const { id, name, hasChild, icon, childRoutes, isLoading, isOpen, path } = tab;
        const parsedHasChild = hasChild === 0 ? false : true;
        // const isKnowledgeBase = path === "knowledgeBase";
        // const hasArrow = isKnowledgeBase || parsedHasChild;
        const hasArrow = parsedHasChild;
        return (
            <div className="aside-tab-wrapper" key={id}>
                <div className={getParentTabClassName(tab)} onClick={() => handleParentTabClick(tab)}>
                    <div className="tab-info">
                        {icon && <ReactSVG className="tab-logo-svg" src={`./images/icons/routes/${icon}.svg`} />}
                        <span className="tab-name">{t(name)}</span>
                    </div>
                    {hasArrow && <ReactSVG className="tab-arrow-svg" src={`./images/icons/chevron-right.svg`} />}
                </div>
                {isOpen && (
                    <div className="aside-children-tabs">
                        {(!childRoutes || isLoading) && <Loader />}
                        {childRoutes && childRoutes.map(renderChildTab)}
                    </div>
                )}
            </div>
        );
    };

    return (
        <aside className="aside-tabs">
            {isLoading && <Loader />}
            {routes && <div className="aside-tabs__main-routes">{routes.map(renderParentTabs)}</div>}
        </aside>
    );
};

export default withTranslation()(withRouter(AsideTabs));

// AsideTabs.propTypes = {
//     mainRoutes: PropTypes.array,
//     subRoutes: PropTypes.array,
//     activeTab: PropTypes.string,
//     setActiveTab: PropTypes.func
// };
