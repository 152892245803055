import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input } from 'ui-core-dashboard';
import { useDebounce } from '../../custom-hooks';

import './styles.scss';

const Search = props => {
    const { setQuery, t, debounce, className, customIcon, defaultValue, autoFocus = true, label = '' } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const debouncedQuery = useDebounce(searchQuery, debounce);

    const handleSearch = value => setSearchQuery(value);

    useEffect(() => {
        setQuery(debouncedQuery);
    }, [debouncedQuery]);

    return (
        <div className="search-wrapper">
            {label && <div className="search-label">{label}</div>}
            <div className={className || 'search-input-wrapper'}>
                <i className={`icon ${customIcon || 'icon-search2'}`} />
                <Input
                    placeholder={t('Search')}
                    onChange={handleSearch}
                    defaultValue={defaultValue}
                    clearable
                    autoFocus={autoFocus}
                />
            </div>
        </div>
    );
};

Search.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setQuery: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    debounce: PropTypes.number,
    className: PropTypes.string,
    customIcon: PropTypes.string,
    autoFocus: PropTypes.bool,
    label: PropTypes.string
};

export default withTranslation()(Search);
