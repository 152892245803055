import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import './styles.scss';

const getUrlWithoutActiveTab = (url, activeTab) => url.slice(0, url.lastIndexOf(activeTab));

const Tabs = props => {
    const {
        local,
        t,
        config,
        history,
        tabKey,
        match,
        match: { url, params }
    } = props;

    const [activeTab, setActiveTab] = useState(false);

    // const activeTab = local ? config[0].tab : match.params[tabKey];

    const handleClick = tab => {
        if (local) return setActiveTab(tab);
        // set new tab to url if there is no active
        if (!activeTab) {
            return history.push(`${url}/${tab}`);
        }
        // get url without active tab and attach new tab to urlf
        if (activeTab !== tab) {
            setActiveTab(tab);
            const urlWithoutTab = getUrlWithoutActiveTab(url, activeTab);
            return history.push(`${urlWithoutTab}${tab}`);
        }
    };

    const renderTabs = conf => {
        const { tab, permission, translateName, metricKey, updated } = conf;
        if (!permission) return;
        const isActive = tab.toLowerCase() === activeTab && activeTab.toLowerCase();
        return (
            <div key={tab} className={cx('tab tab-item', { active: isActive })} onClick={() => handleClick(tab)}>
                {t(translateName)} {metricKey && props[metricKey] > 0 && `(${props[metricKey]})`}
                {updated && props[updated] && <i className="icon-info-circle" />}
            </div>
        );
    };

    const TabContent = activeTab && config.find(tab => tab.tab === activeTab).component;

    const tabProps = (activeTab && config.find(tab => tab.tab === activeTab).props) || {};

    useEffect(() => {
        if (!local) setActiveTab(params[tabKey]);
    }, [params]);

    useEffect(() => {
        setActiveTab(local ? config[0].tab : params[tabKey]);
    }, []);

    return (
        // removed id from tab key, not every tab has id in params, there could be tabs like /url/tab without id
        // <div className="tabs-container" key={`${match.params.id}_${activeTab}`}>
        <div className="tabs-container" key={activeTab}>
            <div className="tabs-navigation">{config.map(renderTabs)}</div>
            <div className="tabs-content">{TabContent && <TabContent {...props} {...tabProps} />}</div>
        </div>
    );
};

Tabs.propTypes = {
    local: PropTypes.bool,
    t: PropTypes.func,
    config: PropTypes.array,
    history: PropTypes.object,
    tabKey: PropTypes.string,
    absolute: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.object,
        url: PropTypes.string
    })
};

export default withTranslation()(withRouter(Tabs));
