import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import ErrorBlock from '../ErrorBlock';
// import Loader from '../../../../components/Loader';
import Loader from '../../Loader';
import FieldController from '../FieldController';

class ContactInput extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    onRemoveClick(e, fieldOnly) {
        const { input } = this.props;
        this.props.onRemove(input.value, input.name, fieldOnly);
    }

    handleChange(event) {
        const { input } = this.props;
        input.onChange({ ...input.value, value: event.target.value });
    }

    submit() {
        const { meta, input, handleChangeValue } = this.props;
        // console.log({input, props: this.props});
        const inputValue = typeof input.value === 'object' ? input.value.value : input.value;
        if (!inputValue) {
            return this.onRemoveClick();
        }
        const initialValue = get(meta.initial, 'value');
        const reduxFormValue = input.value.value;
        if (initialValue !== reduxFormValue && reduxFormValue) {
            handleChangeValue(input.value, input.name);
        }
    }

    handleBlur = () => {
        const { meta, input, isSaveOnlyValid } = this.props;
        const error = meta.error;
        const initialValue = get(meta.initial, 'value');
        if (error && isSaveOnlyValid) {
            input.onChange({ ...input.value, value: initialValue });
        }
    };

    handleFocus = () => {
        const { input } = this.props;
        if (this.props.handleFocus) {
            this.props.handleFocus(input);
        }
    };

    getFieldControllerProps = () => {
        const { meta, input, showNotification, disabled, isSaveOnlyValid } = this.props;

        const initialValue = get(meta.initial, 'value');
        const value = typeof input.value === 'object' ? input.value.value : input.value;
        const error = meta.error;

        const controllerProps = {
            fieldKey: input.name,
            initValue: initialValue,
            value,
            error,
            change: (key, value) => {
                if (!value) {
                    input.onChange({ ...input.value, value: '' });
                } else {
                    input.onChange({ ...input.value, value });
                }
            },
            handleLiveSubmit: this.submit,
            portalPlacement: 'right',
            loader: <Loader loaderContainerClass="container-loader" loaderClass="input-loader" />,
            autoSave: false,
            enterDisabled: false,
            disabled,
            showNotification,
            isSaveOnlyValid,
            handleBlur: this.handleBlur,
            handleFocus: this.handleFocus,
        };
        return controllerProps;
    };

    render() {
        const { disabled, input, meta, placeholder, maxLength, fields } = this.props;
        const value = get(input.value, 'value', '');
        const showErrorBlock = meta && meta.touched && !meta.active && meta.error && value !== '';
        const error = meta.error && value !== '';
        const containerClassName = cx('input-element', 'contactInputContainer', {
            'input-element__error': error,
        });
        const inputClassName = cx('input-field', showErrorBlock && 'input-field__error', {
            'point-even-none': fields.get(0).added && !input.value.added,
            disabled,
        });

        const controllerProps = this.getFieldControllerProps();
        return (
            <FieldController {...controllerProps}>
                <div className={containerClassName}>
                    <div className="input-field-wrap">
                        <input
                            className="input-field"
                            name={input.name}
                            maxLength={maxLength}
                            value={value}
                            disabled={disabled}
                            className={inputClassName}
                            // onFocus={this.focus}
                            onChange={this.handleChange}
                            autoComplete={false}
                            placeholder={placeholder}
                        />
                    </div>
                    {error && <ErrorBlock error={meta.error} />}
                </div>
            </FieldController>
        );
    }
}

function defaultFunc() {}

ContactInput.defaultProps = {
    onFocus: defaultFunc,
    onBlur: defaultFunc,
    onRemove: defaultFunc,
    placeholder: '-',
};

ContactInput.propTypes = {
    label: PropTypes.string,
    input: PropTypes.any,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
};

export default ContactInput;
