import { settings } from "../config";
import { fetch } from "../decorators";
import { storage } from "../services";
// import { i18n } from "../services";
// import { keysToCamelCase, getCookie } from "../helpers";

// const DEFAULT_ROUTES = [
//     { id: "main", path: "main", name: "Головна", description: null, hasChild: false },
//     { id: "catalog", path: "catalog", name: "Каталог послуг", description: null, hasChild: false },
//     { id: "knowledgeBase", path: "knowledgeBase", name: "База знань", description: null, hasChild: false },
// ];

const lsRoutesKey = storage.KEYS.ROUTES;
const langKey = storage.KEYS.LANGUAGE;
const DEFAULT_LANGUAGE = 'uk';

// const processKnowledgeBaseRoutes = (item) => ({
//     id: item.leaf ? `${item.item.id}` : `${item.id}`,
//     path: item.leaf ? `${item.item.id}` : `${item.id}`,
//     name: item.text,
//     parentPath: "knowledgeBase",
//     description: null,
//     hasChild: false,
//     icon: item.leaf ? "document" : "folder",
//     children: item.leaf ? null : item.result.map(processKnowledgeBaseRoutes),
//     isOpen: false,
// });

// const toggleKnowledgeBaseFolderState = (item, id, state) => {
//     if (state) {
//         return { ...item, isOpen: state };
//     }
//     if (+item.path === +id) {
//         return { ...item, isOpen: !item.isOpen };
//     }
//     if (item.children && item.children.length > 0) {
//         return { ...item, children: item.children.map((item) => toggleKnowledgeBaseFolderState(item, id)) };
//     }
//     return item;
// };

const LANGUAGES_OPTIONS = [
    { label: "English", value: "en" },
    { label: "Українська", value: "uk" },
    { label: "Polski", value: "pl" },
    { label: "Русский", value: "ru" },
];

/* constants */
const initialState = {
    notifications: [],
    settings: {
        languages: LANGUAGES_OPTIONS,
    },
    // registrationData: null,
    routes: storage.get(lsRoutesKey) || [],
    language: storage.get(langKey) || DEFAULT_LANGUAGE,
    translations: {},
    isRoutesLoading: false,
};

const actions = {
    UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
    // SAVE_SETTINGS: "SAVE_SETTINGS",
    // SAVE_REGISTRATION_DATA: "SAVE_REGISTRATION_DATA",
    SAVE_APP_ROUTES: "SAVE_APP_ROUTES",
    SET_IS_ROUTES_LOADING: "SET_IS_ROUTES_LOADING",
    SET_LANGUAGE: "SET_LANGUAGE",
    SET_TRANSLATIONS: "SET_TRANSLATIONS"
    // SET_IS_KNOWLEDGEBASE_LOADING: "SET_IS_KNOWLEDGEBASE_LOADING",
};

/* reducer */

export default (state = initialState, { type, ...payload }) => {
    // if (type === actions.SET_IS_KNOWLEDGEBASE_LOADING) {
    //     const { isLoading } = payload;
    //     const routes = state.routes.map((route) => {
    //         if (route.path === "knowledgeBase") {
    //             return {
    //                 ...route,
    //                 isLoading,
    //             };
    //         }
    //         return route;
    //     });
    //     return {
    //         ...state,
    //         routes,
    //     };
    // }
    if (actions[type]) {
        return { ...state, ...payload };
    }
    return state;
};

/* selectors */

/* action creators */

export const updateNotifications = (notifications) => ({
    type: actions.UPDATE_NOTIFICATIONS,
    notifications,
});

/* thunks */

export const addNotification = (text, detail, className = "error") => (dispatch, getState) => {
    const id = +Date.now();
    // let message = String(text);
    let message = text;
    // message = message.length < 200 ? message : `${message.slice(0, 197)}...`;
    const notifications = getState().app.notifications.concat({ text: message, id, detail, className });
    dispatch(updateNotifications(notifications));
    setTimeout(() => {
        const oldNotifications = getState().app.notifications;
        const newNotifications = oldNotifications.filter((notification) => notification.id !== id);
        if (oldNotifications.length !== newNotifications.length) dispatch(updateNotifications(newNotifications));
    }, settings.displayNotificationTimeout);
};

export const removeNotification = (id) => (dispatch, getState) => {
    const oldNotifications = getState().app.notifications;
    const notifications = oldNotifications.filter((notification) => notification.id !== id);
    if (oldNotifications.length !== notifications.length) dispatch(updateNotifications(notifications));
};

// export const setAppDefaultRoutes = (routes) => ({
//     type: actions.SAVE_APP_ROUTES,
//     routes: DEFAULT_ROUTES,
// });

export const saveAppRoutes = (routes) => ({
    type: actions.SAVE_APP_ROUTES,
    routes,
});

export const setIsRoutesLoading = (isRoutesLoading) => ({
    type: actions.SET_IS_ROUTES_LOADING,
    isRoutesLoading,
});

export const setLanguage = (language) => ({
    type: actions.SET_LANGUAGE,
    language,
});

export const setTranslations = (translations) => ({
    type: actions.SET_TRANSLATIONS,
    translations,
});

// export const setKnowledgeBaseIsLoading = (isLoading) => ({
//     type: actions.SET_IS_KNOWLEDGEBASE_LOADING,
//     isLoading,
// });
// export const saveSettings = (response) => (dispatch) => {
//     const lang = getCookie("lang");
//     if (lang && lang !== i18n.language) {
//         i18n.changeLanguage(lang);
//     }
//     dispatch({
//         type: actions.SAVE_SETTINGS,
//         settings: keysToCamelCase(response),
//     });
// };

// export const saveRegistrationData = (registrationData) => ({
//     type: actions.SAVE_REGISTRATION_DATA,
//     registrationData,
// });

// export const toggleKnowledgeBaseFolder = (folder) => (dispatch, getState) => {
//     const currentRoutes = getState().app.routes;
//     const { id } = folder;
//     let isIdFound = false;
//     const knowledgeBaseRoute = currentRoutes.find((route) => route.path === "knowledgeBase");
//     const updatedKnowledgeBaseRoutes = {
//         ...knowledgeBaseRoute,
//         childRoutes: knowledgeBaseRoute.childRoutes.map((item) => toggleKnowledgeBaseFolderState(item, id)),
//     };
//     const updatedRoutes = currentRoutes.map((route) => {
//         if (route.path === "knowledgeBase") {
//             return updatedKnowledgeBaseRoutes;
//         }
//         return route;
//     });
//     dispatch(saveAppRoutes(updatedRoutes));
//     storage.set(lsRoutesKey, updatedRoutes);
// };

// thunks
export const fetchRoutes = (routeCode) => (dispatch, getState) => {
    if (routeCode === 1) {
        dispatch(setIsRoutesLoading(true));
    }
    fetch().send({
        api: { key: "routes" },
        urlVars: { routeCode },
        onSuccess: ({ result }) => {
            // set knowledgeBase to hasChild: 0
            // remove once back return hasChild: 0
            let routes = result.map((item) => (item.path === "knowledgeBase" ? { ...item, hasChild: 0 } : item));
            const currentRoutes = getState().app.routes;
            if (routeCode === 1 && currentRoutes.length > 0) {
                routes = result.map((item) => {
                    const cached = currentRoutes.find((route) => route.id === item.id);
                    if (cached) {
                        return cached;
                    }
                    return item;
                });
            }
            if (routeCode !== 1) {
                routes = currentRoutes.map((item) => {
                    if (item.id === routeCode) {
                        return { ...item, childRoutes: result };
                    }
                    return item;
                });
            }
            if (routeCode === 1) {
                dispatch(setIsRoutesLoading(false));
                // const knowledgeBaseRoute = routes.find((route) => route.path === "knowledgeBase");
                // const isKnowledgeBaseOpen = knowledgeBaseRoute && knowledgeBaseRoute.isOpen;
                // if (isKnowledgeBaseOpen) {
                //     // fetch knowledgeBaseRoutes in case they are opened
                //     dispatch(fetchKnowledgeBaseRoutes());
                // }
            }
            dispatch(saveAppRoutes(routes));
            storage.set(lsRoutesKey, routes);
        },
        onFailure: (error) => {
            console.log("Reducers::app:fetchRoutes: Error", error);
            if (routeCode === 1) {
                dispatch(setIsRoutesLoading(false));
            }
        },
    });
};

// export const fetchKnowledgeBaseRoutes = () => (dispatch, getState) => {
//     dispatch(setKnowledgeBaseIsLoading(true));
//     fetch().send({
//         api: { key: "infoTree", query: "" },
//         onSuccess: ({ result }) => {
//             dispatch(setKnowledgeBaseIsLoading(false));
//             // const childRoutesroutes = result.map(processKnowledgeBaseRoutes);
//             const childRoutesroutes = result.map((item) => ({
//                 id: item.id,
//                 path: item.leaf ? `${item.item.id}` : `${item.id}`,
//                 name: item.text,
//                 parentPath: "knowledgeBase",
//                 description: null,
//                 hasChild: false,
//                 icon: item.leaf ? "document" : "folder",
//                 children: item.leaf ? null : item.result,
//             }));

//             const searchRoute = {
//                 id: "search",
//                 path: "knowledgeBase",
//                 name: "Пошук",
//                 parentPath: "",
//                 description: null,
//                 hasChild: false,
//                 icon: "search",
//                 children: null,
//                 isSticky: true,
//             };
//             // const currentRoutes = getState().app.routes;
//             const routes = getState().app.routes.map((item) => {
//                 if (item.path === "knowledgeBase") {
//                     return { ...item, childRoutes: [searchRoute, ...childRoutesroutes] };
//                 }
//                 return item;
//             });
//             dispatch(saveAppRoutes(routes));
//             storage.set(lsRoutesKey, routes);
//         },
//         onFailure: (error) => {
//             console.log("Reducers::app:fetchRoutes: Error", error);
//             dispatch(setKnowledgeBaseIsLoading(false));
//             if (routeCode === 1) {
//                 dispatch(setIsRoutesLoading(false));
//             }
//         },
//     });
// };

export const toggleRouteChildren = (route) => (dispatch, getState) => {
    const { childRoutes, id, path, isOpen } = route;

    // const isKnowledgeBase = path === "knowledgeBase";

    // if (isKnowledgeBase && !isOpen) {
    //     dispatch(fetchKnowledgeBaseRoutes());
    // }

    // if (!childRoutes && !isKnowledgeBase) {
    //     dispatch(fetchRoutes(id));
    // }

    if (!childRoutes) {
        dispatch(fetchRoutes(id));
    }

    const currentRoutes = getState().app.routes;
    const updatedRoutes = currentRoutes.map((item) => {
        if (item.id === route.id) {
            return { ...item, isOpen: !item.isOpen };
        }
        return item;
    });
    dispatch(saveAppRoutes(updatedRoutes));
    storage.set(lsRoutesKey, updatedRoutes);
};
