import { api } from '../../services';
import store from '../../store';
import { addNotification, 
    // setAccessSocket 
} from '../../reducers/app';
// import { updateAuthStatus } from '../../reducers/auth';
// import { saveUserData } from '../../reducers/user';
import { requests } from '../../config';

const errorTranslate = {
    'Request failed with status code': 'statusCodeError'
};

const getErrorTranslation = error => {
    // console.log({error});
    // const statusCode = error.substr(error.length - 3, error.length);
    // const withoutStatusCode = error.substr(0, error.length - 4);
    // if (errorTranslate[withoutStatusCode]) {
    //     return [errorTranslate[withoutStatusCode], statusCode];
    // }
    // return null;
    return error;
};

const fetch = () => {
    const fetchSource = api.CancelToken.source();

    return {
        send: initSettings => {
            const settings = Array.isArray(initSettings) ? initSettings : [initSettings];
            settings.forEach(async setting => {
                const { api: requestApi, urlVars, file, files, formData, download, timeout, authorization, ...restSetting } = setting;
                // Preliminary operations if onStart function provided
                restSetting.onStart && restSetting.onStart();

                if (!requestApi.key) return;

                try {
                    // Еxecution single request
                    const { data, status, headers } = await api.request(
                        requestApi,
                        urlVars,
                        {
                            cancelToken: fetchSource.token
                        },
                        file,
                        files,
                        formData,
                        download,
                        timeout,
                        authorization
                    );

                    const { key: apiKey, method } = requestApi;
                    const { successNotification, accessSocket } = requests[apiKey];
                    // if (accessSocket && method !== 'head') {
                    //     store.dispatch(setAccessSocket(accessSocket));
                    // }
                    if (successNotification && successNotification[method]) {
                        const message = successNotification[method](data);
                        store.dispatch(addNotification(message, '', 'success'));
                    }


                    if (data && data.errorMessage || data.errorCode) {
                        store.dispatch(addNotification(data.errorMessage || data.errorCode));
                    }

                    // Success completion if data or status or headers are here and onSuccess function provided
                    // data, status, headers === undefined when req is canceled
                    (data || status || headers) &&
                        restSetting.onSuccess &&
                        restSetting.onSuccess(data, status, headers);
                } catch (error) {
                    const response = (error && error.response && error.response.data) || {};

                    const translatedError = getErrorTranslation(error && error.message);

                    // Error completion if onFailure function provided
                    restSetting.onFailure && restSetting.onFailure(error);

                    // Show error notification if not disabled
                    if (!restSetting.disableNotification) {
                        const errorDetail = response.error_message || response.detail || response.message;

                        if (response.status !== 401) {
                            store.dispatch(addNotification(response.error || response.errorMessage || translatedError || error.message, errorDetail));
                        }
                    }
                }
            });
        },
        cancel: fetchSource.cancel
    };
};

export default fetch;
