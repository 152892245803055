import { reducers } from 'ui-core-dashboard';

const { componator, grid } = reducers;

export { componator, grid };

export { default as app } from './app';
export { default as auth } from './auth';
export { default as user } from './user';
export { default as store } from './store';
export { default as form } from './form';
export { default as breadcrumbs } from './breadcrumbs';
export { default as staticBlocks } from './staticBlocks';
