import React from 'react';
import { FieldArray } from 'redux-form';

function FormFieldArray (props) {
	const isReadOnly = props.readOnly;
	const classes = isReadOnly ? 'fieldBlock' + ' ' + 'readOnly' : 'fieldBlock';
	return (
		<div className={classes}>
			<FieldArray {...props} />
		</div>
	);
}

export default FormFieldArray;
