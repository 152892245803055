import React from 'react';
import PropTypes from 'prop-types';

export default function Loader({ hidden, loading, children }) {
    return hidden || !loading ? (
        children
    ) : (
        <div className="loader-container">
            <div className="loader" />
        </div>
    );
}

Loader.defaultProps = {
    loading: true,
    children: null
};

Loader.propTypes = {
    hidden: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node
};
