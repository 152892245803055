import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { get } from "lodash";

export default class CalendarContainer extends Component {
	constructor (props) {
		super(props);
		this.modal = React.createRef();
		this.container = document.getElementById('modal-root');
		this.event = this.event.bind(this);
	}
	
	componentDidMount () {
		this.modal.current.addEventListener('DOMNodeInserted', this.event);
	}
	
	componentWillUnmount () {
		this.modal.current.removeEventListener('DOMNodeInserted', this.event);
	}
	
	event () {
		const target = this.modal.current.querySelector('.--selected_year');
		if (target) {
			target.scrollIntoView({ block: 'center', behavior: 'auto' });
		}
	}
	
	render () {
		const fieldKey = get(this.props, 'fieldKey');
		return ReactDOM.createPortal(
			<div ref={this.modal} data-field-key={fieldKey}>
				{this.props.children}
			</div>,
			this.container
		);
	}
}
