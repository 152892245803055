import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getWidthStyles } from './helpers';

export default class GridCell extends React.PureComponent {
    parseHtmlContent = html => String(html).replace(/<\/?[^>]+>/g, '');

    displayCell = (value, item, data) => {
        const { t, firstField, name, elementIconClass, folderIconClass } = this.props;
        const showTreeIcon = data.tree && name === firstField;
        if (item.link && typeof item.link === 'function') {
            return (
                <a className="data-cell" href={item.link(data)}>
                    {value}
                </a>
            );
        } else if (item.html) {
            return (
                <div
                    className="data-cell"
                    title={this.parseHtmlContent(value)}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            );
        } else if (item.value) {
            const itemValue = item.value(data, value);
            const itemValueType = typeof itemValue;
            if (!['number', 'string', 'object'].includes(itemValueType)) {
                console.error(`
                    Error in column ${name}: "Wrong column type: ${itemValueType}, expected string or number as type".
                    Use render props as advanced functionality for such case
                `);
                return null;
            }
            return (
                <div className="data-cell" title={itemValue}>
                    {itemValue}
                </div>
            );
        } else if (item.render) {
            return item.render(data, value);
        } else if (item.component) {
            return <item.component {...item.props} data={data} value={value} t={t} />;
        }
        return (
            <div className="data-cell" title={value}>
                {showTreeIcon && <i className={`${data.children ? folderIconClass : elementIconClass} ${data.opened? 'opened': ''}`} />}
                {value}
            </div>
        );
    };

    getDelimiter = data => {
        const spans = [];
        if (data.level) {
            for (let i = 0; i < data.level - 1; i++) {
                const delimiter = data.delimiter[i + 1] && (
                    <span key={i} className="tree-delimiter" style={{ left: `${(i + 2) * 20}px` }} />
                );
                if (delimiter) spans.push(delimiter);
            }
        }
        return spans;
    };

    handleToggleRowVisibility = e => {
        const { toggleRowVisibility } = this.props;
        if (toggleRowVisibility && typeof toggleRowVisibility === 'function') {
            toggleRowVisibility(e, this.props.data);
        }
    };

    render = () => {
        const { data, item, value, forceWidth, name, lastField, resizing, firstField, fixedWidth } = this.props;
        const { hidden, classWidth, width } = item;
        if (hidden) return null;

        // make td className
        const mainClass = name !== lastField && classWidth ? classWidth : '';
        const firstTreeCell = data.tree && name === firstField;
        const tdClassName = cx(`grid-cell-${mainClass}`, { resizing, 'tree-cell' : firstTreeCell });

        // make td styles
        const forcedWidthStyle = getWidthStyles(forceWidth || fixedWidth || width);
        const levelStyle = firstTreeCell ? { paddingLeft: `${(data.level + 1) * 20}px` } : null;
        const styles = forcedWidthStyle || levelStyle ? { ...forcedWidthStyle, ...levelStyle } : undefined;

        // make div className
        const firstParentCell = data.children && name === firstField;
        const divClassName = cx('item-cell', {
            parent: firstParentCell,
            first: data.firstChild,
            last: data.lastChild,
            level: data.level
        });

        return (
            <td className={tdClassName} style={styles} data-name={name}>
                {firstTreeCell && data.level > 1 && this.getDelimiter(data).map(el => el)}
                <div className={divClassName}>
                    {firstParentCell && (
                        <div className="plus-cell-checkbox" onClick={this.handleToggleRowVisibility}>
                            {data.children && <div className={cx('plus-label', { opened: data.opened })} />}
                        </div>
                    )}
                    {this.displayCell(value, item, data)}
                </div>
            </td>
        );
    };
}

GridCell.defaultProps = {
    folderIconClass: 'icon-folder',
    elementIconClass: 'icon-tasks'
};

GridCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
    data: PropTypes.object,
    item: PropTypes.object,
    name: PropTypes.string,
    lastField: PropTypes.string,
    firstField: PropTypes.string,
    forceWidth: PropTypes.number,
    toggleRowVisibility: PropTypes.func,
    resizing: PropTypes.bool,
    folderIconClass: PropTypes.string,
    elementIconClass: PropTypes.string
};
