import React from "react";
import ReactSVG from "react-svg";
import Loader from "../Loader";

const File = (props) => {
    const { name, disabled = false, id, onRemove, loading } = props;
    return (
        <div className={loading ? "file loading" : "file"} key={name}>
            <div className="file-preview" title={name}>
                {loading && <Loader />}
                <div className="file-name">
                    {id ? (
                        <a target="_blank" href={`../sdsapi/portal/api/file?fileId=${id}&download=0`} title={name}>
                            {name}
                        </a>
                    ) : (
                        name
                    )}
                </div>
                {!loading && !disabled && (
                    <ReactSVG className="svg-remove" src="./images/icons/close.svg" onClick={onRemove} />
                )}
            </div>
        </div>
    );
};

export default File;
