import { isArray } from 'lodash';
import moment from 'moment';
import { settings } from '../config';

export const getMenuItemInfo = key => {
    const { link, icon } = settings.menu[key] || {};
    return {
        icon: icon || key,
        link: link || `/${key}`
    };
};

export const getMenuActiveSection = ({ sections, route }) => {
    let active;
    Object.keys(sections).forEach((key, index) => {
        if (!index) {
            active = key;
            return;
        }
        sections[key].forEach(({ name: sectionName }) => {
            const { link } = getMenuItemInfo(sectionName);
            if (route.startsWith(link)) {
                active = key;
            }
        });
    });
    return active;
};

export const setResourceMarkers = data => {
    const res = [];

    data.forEach(({ entity: { id, name, type, geo_position } }) => {
        const { sname: type_sname } = type;
        const { latitude, longitude } = geo_position || {};

        if (geo_position && latitude && longitude) {
            res.push({
                id,
                name,
                type_sname,
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            });
        }
    });

    return res;
};

export const mapSpecAttrs = attrs => {
    return attrs.map(val => ({
        name: val.name,
        key: val.sname,
        label: val.name
    }));
};

export const getTimeStartOfDay = () => {
    const start = new Date();
    start.setHours(0,0,0,0);
    return start.getTime() / 1000;
};

export const mixArrays = (arr1, arr2, cb) => {
    arr2.forEach(v => (!arr1.find(l => l.id === v.id) ? arr1.push(v) : null));
    cb(arr1);
};

export const CustomStyles = () => {
    const linked = {};
    let idx = 0;

    return {
        next: id => {
            if (idx < 10 && !linked[id]) {
                linked[id] = settings.workTeamStyles[idx];
                idx += 1;
            }

            return linked[id];
        },
        multi: () => settings.workTeamStyles[10]
    };
};

export const mapLiveRequest = values => {
    const key = Object.keys(values)[0];
    return { [key]: values[key] ? values[key].value || values[key] : null };
};

export const mapForRequest = values => {
    const params = {};
    Object.keys(values).forEach(k => {
        if (values[k]) params[k] = isArray(values[k]) ? values[k].map(v => v.value) : values[k].value || values[k];
    });
    return params;
};

export const isValidDate = value => moment(value, typeof value === 'string' ? 'DD/MM/YYYY HH:mm' : 'X', true).isValid();

export const trimValues = values => {
    const data = {};

    Object.keys(values).forEach(k => {
        data[k] = typeof values[k] === 'string' ? values[k].trim() : values[k];
    });

    return data;
};
