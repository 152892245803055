import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Confirm from '../Confirm';

const KEY_ESC = 27;

class ModalWindow extends Component {
    state = {
        isOpen: true,
        confirm: {
            isOpen: false,
            coordinates: null
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isOpen !== prevState.isOpen) {
            return { isOpen: nextProps.isOpen };
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp, false);
    }

    componentWillUnmount() {
        if (this.modalContainer) {
            document.body.removeChild(this.modalContainer);
        }
        document.removeEventListener('keyup', this.handleKeyUp, false);
    }

    handleCloseModal = ev => {
        if (ev && ev.stopPropagation) ev.stopPropagation();
        if (this.props.onClose) this.props.onClose();
        this.setState({ isOpen: false });
    };

    closeModalViaConfirm = ev => {
        const confirm = { isOpen: true };
        if (ev && ev.pageX && ev.pageY) {
            confirm.coordinates = [ev.pageX, ev.pageY];
        }
        return this.setState({ confirm });
    };

    hideConfirm = () => this.setState({ confirm: { isOpen: false, coordinates: null } });

    handleModalClick = ev => ev.stopPropagation();

    handleKeyUp = ev => {
        const { isDirty } = this.props;
        if (KEY_ESC === ev.keyCode) {
            if (isDirty) return this.closeModalViaConfirm();
            return this.handleCloseModal();
        }
    };

    render() {
        const {
            isOpen: isOpenModal,
            confirm: { isOpen: isOpenConfirm, coordinates }
        } = this.state;
        if (!isOpenModal) return null;

        if (!this.modalContainer) {
            this.modalContainer = document.createElement('div');
            this.modalContainer.classList.add('modal-popup-wrapper');
            document.body.appendChild(this.modalContainer);
        }

        const { children, className, modalStyles, withGrid, safetyMode, isDirty, withForm, confirmProps } = this.props;
        const overlayProps = { className: cx('modal-window-overlay', { [className]: Boolean(className) }) };
        const handleCloseModal = isDirty ? this.closeModalViaConfirm : this.handleCloseModal;
        if (!safetyMode) overlayProps.onClick = handleCloseModal;

        const renderer = (
            <div {...overlayProps} key="modal">
                <div
                    className={cx('modal-window', { 'modal-grid': withGrid })}
                    style={modalStyles}
                    onClick={this.handleModalClick}
                >
                    <i className="modal-window-close icon-close" onClick={handleCloseModal} />
                    {withForm ? children({ handleCloseModal }) : children}
                </div>
            </div>
        );
        const Portal = ReactDOM.createPortal(renderer, this.modalContainer);

        if (isOpenConfirm) {
            return [
                Portal,
                <Confirm
                    key="confirm"
                    action={this.handleCloseModal}
                    cancel={this.hideConfirm}
                    modalCors={coordinates}
                    {...confirmProps}
                />
            ];
        }

        return Portal;
    }
}

ModalWindow.propTypes = {
    // ownProps
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    modalStyles: PropTypes.object,
    withGrid: PropTypes.bool,
    safetyMode: PropTypes.bool,
    isDirty: PropTypes.bool,
    withForm: PropTypes.bool,
    confirmProps: PropTypes.object
};

ModalWindow.defaultProps = {
    isOpen: true,
    className: null,
    onClose: null,
    modalStyles: null,
    withGrid: false,
    safetyMode: true,
    isDirty: false,
    withForm: false,
    confirmProps: null
};

export default ModalWindow;
