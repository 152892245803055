import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxToggle from '../FormControl/CheckBoxToggle';
import ComboBox from '../FormControl/ComboBox';
import Button from '../Button';

/*

    1. input => inputFields (left block)
    2. output => outputFields (right block)
    3. onConfirm => func that returning (fields, format, isWithKeys),
    4. onCancel => optional func
    5. formatOptions => options for format ComboBox select (default selected first one)

*/

const Componator = props => {
    const {
        t,
        input,
        output,
        updateComponator,
        onConfirm,
        onCancel,
        indexedField,
        displayedField,
        formatOptions
    } = props;

    const [isWithKeys, setIsWithKeys] = useState(false);
    const [selected, setSelected] = useState({
        input: [],
        output: []
    });

    const [format, setFormat] = useState((formatOptions && formatOptions[0].value) || null);

    const getBtnUpStatus = () => {
        if (selected.output.length > 1) return true;
        if (selected.output.length !== 0) {
            const selectedIndex = findArrIndex(output, selected.output[0]);
            return [-1, 0].includes(selectedIndex);
        }
        return true;
    };

    const getBtnDownStatus = () => {
        if (selected.output.length > 1) return true;
        if (selected.output.length !== 0) {
            const selectedIndex = findArrIndex(output, selected.output[0]);
            return [-1, output.length - 1].includes(selectedIndex);
        }
        return true;
    };

    const handleChange = () => {
        setIsWithKeys(!isWithKeys);
    };

    const getField = (field, key) => {
        const selectField = () => {
            const selectedIndex = findArrIndex(selected[key], field);
            if (selectedIndex === -1) {
                setSelected(prevSelected => ({ ...prevSelected, [key]: [...prevSelected[key], field] }));
            } else {
                setSelected(prevSelected => ({
                    ...prevSelected,
                    [key]: prevSelected[key].filter((_, index) => index !== selectedIndex)
                }));
            }
        };

        const moveDbl = () => {
            const selectedIndex = findArrIndex(props[key], field);
            if (key === 'input') {
                updateComponator({
                    input: input.filter((_, index) => index !== selectedIndex),
                    output: [...output, input[selectedIndex]]
                });
            }
            if (key === 'output') {
                updateComponator({
                    input: [...input, output[selectedIndex]],
                    output: output.filter((_, index) => index !== selectedIndex)
                });
            }
        };

        return (
            <div
                key={field[displayedField]}
                className={`field ${
                    selected[key].find(v => v[indexedField] === field[indexedField]) ? 'selected' : ''
                }`}
                onClick={selectField}
                onDoubleClick={moveDbl}
            >
                {t(field[displayedField])}
            </div>
        );
    };

    const findArrIndex = (arr, val) => {
        return arr.findIndex(v => v[indexedField] === val[indexedField]);
    };

    const moveAllIn = () => {
        updateComponator({
            input: [],
            output: [...output, ...input]
        });
    };

    const moveAllOut = () => {
        updateComponator({
            input: [...input, ...output],
            output: []
        });
    };

    const moveIn = () => {
        const keys = selected.input.map(v => v[indexedField]);

        updateComponator({
            input: input.filter(v => !keys.includes(v[indexedField])),
            output: output.concat(selected.input)
        });
    };

    const moveOut = () => {
        const keys = selected.output.map(v => v[indexedField]);

        updateComponator({
            input: input.concat(selected.output),
            output: output.filter(v => !keys.includes(v[indexedField]))
        });
    };

    const moveUp = () => {
        const index = findArrIndex(output, selected.output[0]);

        output.splice(index, 1);
        output.splice(index - 1, 0, selected.output[0]);
        updateComponator({ output });
    };

    const moveDown = () => {
        const index = findArrIndex(output, selected.output[0]);

        output.splice(index, 1);
        output.splice(index + 1, 0, selected.output[0]);
        updateComponator({ output });
    };

    const handleConfirm = () => {
        onConfirm(output, format.value || format, isWithKeys);
    };

    useEffect(() => {
        setSelected({ input: [], output: [] });
    }, [input, output]);

    return (
        <div className="componator">
            {formatOptions && (
                <ComboBox
                    className="container-combo-box"
                    classNamePrefix="select"
                    key="format-select"
                    name="format-select"
                    label={t('exportFormat')}
                    placeholder={t('placeholder')}
                    options={formatOptions}
                    input={{ onChange: setFormat, value: format }}
                    clearable={false}
                />
            )}
            <div className="componator-title">{t('fieldsForExport')}</div>
            <div className="componator-content">
                <div className="componator-input">
                    <div className="componator-title">{t('availableFields')}</div>
                    <div className="componator-fields">{input.map(v => getField(v, 'input'))}</div>
                </div>
                <div className="componator-handlers">
                    <button type="button" className="btn" disabled={!input.length} onClick={moveAllIn}>
                        <i className="icon-chevron-double-right" />
                    </button>
                    <button type="button" className="btn" disabled={!selected.input.length} onClick={moveIn}>
                        <i className="icon-arrow-right" />
                    </button>
                    <button type="button" className="btn" disabled={!selected.output.length} onClick={moveOut}>
                        <i className="icon-arrow-left" />
                    </button>
                    <button type="button" className="btn" disabled={!output.length} onClick={moveAllOut}>
                        <i className="icon-chevron-double-left" />
                    </button>
                </div>
                <div className="componator-output">
                    <div className="componator-title">{t('selectedFields')}</div>
                    <div className="componator-fields">{output.map(v => getField(v, 'output'))}</div>
                </div>
                <div className="componator-handlers">
                    <button type="button" className="btn" disabled={getBtnUpStatus()} onClick={moveUp}>
                        <i className="icon-up" />
                    </button>
                    <button type="button" className="btn" disabled={getBtnDownStatus()} onClick={moveDown}>
                        <i className="icon-down" />
                    </button>
                </div>
            </div>
            <footer className="componator-footer">
                <CheckBoxToggle
                    key="add-field-keys"
                    name="add-field-keys"
                    label={t('addFieldKeys')}
                    checked={isWithKeys}
                    onChange={handleChange}
                />
                <div className="footer-buttons">
                    {onCancel && <Button className="btn-cancel-circle" onClick={onCancel} text={t('Cancel')} />}
                    {onConfirm && (
                        <Button
                            className="btn-save-circle"
                            disabled={output.length === 0}
                            text={t('download')}
                            onClick={handleConfirm}
                        />
                    )}
                </div>
            </footer>
        </div>
    );
};

Componator.propTypes = {
    t: PropTypes.func,
    input: PropTypes.array,
    output: PropTypes.array,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    updateComponator: PropTypes.func,
    indexedField: PropTypes.string,
    displayedField: PropTypes.string,
    formatOptions: PropTypes.array
};

export default Componator;
