import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from '../../FormControl/Input';
import ComboBox from '../../FormControl/ComboBox';

import { reactDebounce } from '../helpers';
import { ADD_FILTER_DATA_TIMEOUT, FILTER_INPUT_ID } from '../gridConfig';

import Exports from './Exports';
import { getTKey } from '../tKeys';

export default class ControlHeader extends Component {
    addFilterData = () => {
        const {
            t,
            tKeys,
            filter: { onChange, ...rest }
        } = this.props.params;
        const filterProps = {
            name: 'filter',
            placeholder: t(getTKey(tKeys, 'searchPlaceholder')),
            id: FILTER_INPUT_ID,
            clearable: true,
            autoFocus: true,
            ...rest
        };

        if (onChange) {
            filterProps.onChange = reactDebounce(query => onChange({ query }), ADD_FILTER_DATA_TIMEOUT);
        }

        return <Input {...filterProps} />;
    };

    addFilterFields = () => {
        const { t, tKeys, limitFields } = this.props.params;
        if (!limitFields) return null;
        const data = ['all_fields'].concat(limitFields);

        return (
            <ComboBox
                name="dfh"
                disabled={false}
                defaultValue="all_fields"
                data={data}
                onChange={this.props.filterFields}
                placeholder={t(getTKey(tKeys, 'filterFieldsPlaceholder'))}
            />
        );
    };

    download = () => this.props.params.download(this.props.selected);

    addDownload = () => {
        const {
            selected,
            params: { t, tKeys }
        } = this.props;
        return (
            <div className={cx('button', { disabled: !selected })} onClick={this.download}>
                <i className="grid-icon icon-download" />
                {t(getTKey(tKeys, 'btnHeaderDownload'))}
            </div>
        );
    };

    addUpload = () => {
        const { t, tKeys } = this.props.params;
        return (
            <div className="button">
                <i className="grid-icon icon-upload" />
                {t(getTKey(tKeys, 'btnHeaderUpload'))}
                <input type="file" key={+new Date()} onChange={this.props.params.upload} />
            </div>
        );
    };

    edit = () => {
        const {
            params: { edit },
            selected
        } = this.props;
        typeof edit === 'function' ? edit(selected) : undefined;
    };

    editButton = () => {
        const {
            selected,
            params: { t, tKeys }
        } = this.props;
        return (
            <div className={cx('button', { disabled: !selected || selected.notEditable })} onClick={this.edit}>
                <i className="grid-icon icon-edit" />
                {t(getTKey(tKeys, 'btnHeaderEdit'))}
            </div>
        );
    };

    addItem = () => {
        const {
            params: { add },
            selected
        } = this.props;
        typeof add === 'function' ? add(selected) : undefined;
    };

    addButton = () => {
        const { t, tKeys } = this.props.params;

        return (
            <div className="button" onClick={this.addItem}>
                <i className="grid-icon icon-plus" />
                {t(getTKey(tKeys, 'btnHeaderAdd'))}
            </div>
        );
    };

    groupRemove = () => (
        <div className="group-remove">
            <i className="grid-icon icon-delete" />
        </div>
    );

    getGridTitle = () => {
        const { title } = this.props.params;
        return (
            <h6 title={title} className="grid-title">
                {title}
            </h6>
        );
    };

    render = () => {
        const { selected, params } = this.props;
        const {
            title,
            filter,
            limitFields,
            groupsRemove,
            edit,
            upload,
            download,
            add,
            exports,
            t,
            tKeys,
            FilterExtensionBar,
            GridHeader: CustomHeader
        } = params;

        if (CustomHeader) return CustomHeader(selected);

        const separator = !!groupsRemove || !!edit || !!upload || !!download || !!add;
        const exportSize = exports && Object.keys(exports).length;
        const head = !filter && !limitFields && !edit && !upload && !add && !exportSize;

        return (
            <Fragment>
                {title && head && <div className="left-settings">{this.getGridTitle()}</div>}
                {!head && (
                    <div className="grid-header settings-container">
                        <div className="left-settings">
                            {this.getGridTitle()}
                            {filter && this.addFilterData()}
                            {this.addFilterFields()}
                        </div>
                        <div className="right-settings">
                            <Exports exports={exports} t={t} tKeys={tKeys} exportSize={exportSize} />
                            {separator && <div className="line-separator" />}
                            {groupsRemove && this.groupRemove()}
                            {edit && this.editButton()}
                            {download && this.addDownload()}
                            {upload && this.addUpload()}
                            {add && this.addButton()}
                        </div>
                        {FilterExtensionBar && (
                            <div className="filter-extension-bar">
                                <FilterExtensionBar />
                            </div>
                        )}
                    </div>
                )}
            </Fragment>
        );
    };
}

ControlHeader.propTypes = {
    params: PropTypes.object.isRequired,
    selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};
