import axios from "axios";
import store from "../store";
import { logoutUser } from "../reducers/user";
import { updateAuthStatus } from "../reducers/auth";
import { requests, axios as axiosConfig } from "../config";
import { buildUrl } from "../helpers";

const DEFAULT_API_TIMEOUT = 120000; // 120s

const redirect = (key, params = {}, target) => {
    const api = requests[key];
    if (!api) return;

    // const url = `${window.location.origin}${settings.requests.redirectURL}${api.url}`;
    // const queryString = stringifyQueryString(params);
    // target === '_blank' ? window.open(url + queryString) : window.location.replace(url + queryString);
    const url = `${window.location.origin}${window.location.pathname}${api.url}`;
    console.log("window.location.origin = ", window.location.origin);
    console.log("window.location.pathname = ", window.location.pathname);
    target === "_blank" ? window.open(url) : window.location.replace(url);
};

const request = (
    api,
    urlVars = {},
    extraRequestProps = {},
    file,
    files,
    formData,
    download,
    timeout = DEFAULT_API_TIMEOUT,
    authorization
) => {
    const { key, method = "get", isFormData, isQueryStringParameters, ...rest } = api;
    if (!requests[key]) {
        throw new Error(`no API found for key { ${key} }`);
    }
    const url = buildUrl(requests[key].url, urlVars);

    const req = { ...api, ...extraRequestProps, url };

    let axiosQueryParamsKey = "params";

    if (!isQueryStringParameters && method !== "get") {
        axiosQueryParamsKey = "data";
        delete req.params;
    }

    req.headers = isFormData ? { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' } : { 'content-type': 'application/json; charset=UTF-8' }

    // plain data is used to put in data some plain data, for example request with payload of array
    if (api.plainData) {
        req[axiosQueryParamsKey] = api.plainData;
    } else {
        req[axiosQueryParamsKey] = rest;
    }

    // attach file to request body if provided
    if (file) {
        const formData = new FormData();
        formData.append("file", file);
        req.data = formData;
    }

    if (files) {
        const formData = new FormData();
        files.forEach((file) => formData.append("file", file));
        req.data = formData;
    }

    if (formData) {
        const data = new FormData();
        const dataKeys = Object.keys(formData);
        dataKeys.forEach((key) => {
            if (Array.isArray(formData[key])) {
                formData[key].forEach((item) => data.append(key, item));
            } else {
                data.append(key, formData[key]);
            }
        });
        req.data = data;
    }

    if (download) {
        req.responseType = "blob";
    }

    if (timeout) {
        req.timeout = timeout;
    }

    window.requestCounts = window.requestCounts || 0;
    window.freeze = window.freeze || 0;
    window.requestCounts += 1;
    window.freeze += 1;

    const axiosParams = { ...axiosConfig };
    if (authorization) {
        axiosParams.headers["Authorization"] = authorization;
    }

    const axiosCreate = axios.create({
        ...axiosParams,
        validateStatus: (status) => status >= 200 && status < 300,
    });

    return (
        axiosCreate
            .request(req)
            // Check response error.
            .then((response) => {
                window.freeze -= 1;
                return response;
            })
            .catch((error) => {
                window.freeze -= 1;
                // If not authorized.
                if (error && error.response && error.response.status === 401) {
                    store.dispatch(logoutUser());
                    store.dispatch(updateAuthStatus("none"));
                    redirect("notAuth", { next: window.location.href });
                }
                // If request canceled.
                if (axios.isCancel(error)) {
                    return error;
                }

                throw error;
            })
    );
};

export default Object.freeze({
    request,
    redirect,
    CancelToken: axios.CancelToken,
});
