import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cx from 'classnames';

const container = document.getElementById('popup-root');

const Portal = props => {
    const { children, className, coors, parent } = props;
    const nextProps = { className: cx('portal', className) };

    if (coors) {
        nextProps.style = { left: coors[0], top: coors[1] };
    }

    return ReactDOM.createPortal(<div {...nextProps}>{children}</div>, parent || container);
};

Portal.propTypes = {
    // ownProps
    className: PropTypes.string,
    children: PropTypes.node,
    parent: PropTypes.node
};

export default Portal;
