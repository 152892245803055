export default {
    // Auth
    // auth: {
    //     url: '/../sdsapi/portal/api/auth'
    // },

    // userInfo: {
    //     url: '../sdsapi/portal/api/menu/routes/1'
    // },

    // person: {
    //     url: '../sdsapi/portal/api/my/person'
    // },

    accountsDomain: {
        url: "../sdsapi/portal/api/domain",
    },

    accountsUsers: {
        url: "../sdsapi/portal/api/users",
    },

    providers: {
        url: "../sdsapi/portal/api/service/provider",
    },

    specProvider: {
        url: "../sdsapi/portal/api/service/provider/spec",
    },

    deleteSpec: {
        url: "../sdsapi/portal/api/service/provider/spec?spec=:spec",
    },

    getUserParams: {
        url: "../sdsapi/portal/api/user/:id",
    },

    saveUserParams: {
        url: "../sdsapi/portal/api/user",
    },

    catalog: {
        url: "../sdsapi/portal/api/service/consumer/spec",
    },

    customer: {
        url: "../sdsapi/portal/api/my/customer/info",
    },

    customerResourceTypes: {
        url: "../sdsapi/portal/api/my/customer/resource/:type",
    },

    contactsType: {
        url: "../sdsapi/portal/api/contact/type",
    },

    getUserPersonContacts: {
        url: "../sdsapi/portal/api/user/:id/person/contacts",
    },

    getUserPerson: {
        url: "../sdsapi/portal/api/user/:id/person",
    },

    saveUserPerson: {
        url: "../sdsapi/portal/api/user/:id/personContacts",
    },

    userRoles: {
        url: "../sdsapi/portal/api/user/:id/role",
    },

    userHistory: {
        url: "../sdsapi/portal/api/user/:id/history",
    },

    filterTypes: {
        url: "../sdsapi/portal/api/filters/:type",
    },

    searchTask: {
        url: "../sdsapi/portal/api/search/task",
    },

    notifyProvider: {
        url: "../sdsapi/portal/api/problem/customer",
    },

    notifyConsumer: {
        url: "../sdsapi/portal/api/problem/provider",
    },

    problemType: {
        url: "../sdsapi/portal/api/problem/type",
    },

    infoTree: {
        url: "../sdsapi/portal/api/kb/list",
    },

    infoLeaf: {
        url: "../sdsapi/portal/api/kb/detail",
    },

    catalogItem: {
        url: "../sdsapi/portal/api/service/spec/:id",
    },

    saveSpec: {
        url: "../sdsapi/portal/api/service/provider/spec/",
    },

    signSpec: {
        url: "../sdsapi/portal/api/sign/validate/spec/:id",
    },

    getSpecYaml: {
        url: "../sdsapi/portal/api/doc/spec/:id",
    },

    getSpec: {
        url: "../sdsapi/portal/api/service/spec/:id",
    },

    getSpecInst: {
        url: "../sdsapi/portal/api/service/spec/inst",
    },

    getIpAddresses: {
        url: "../sdsapi/portal/api/my/customer/resource/ipaddress",
    },

    validateIpAddress: {
        url: "../sdsapi/portal/api/my/customer/validate/ipaddress",
    },

    userNetwork: {
        url: "../sdsapi/portal/api/my/net",
    },

    catalogConsumerServiceResource: {
        url: "../sdsapi/portal/api/service/child",
    },

    orderAction: {
        url: "../sdsapi/portal/api/order/action",
    },

    order: {
        url: "../sdsapi/portal/api/order",
    },

    updateOrder: {
        url: "../sdsapi/portal/api/order/comment/:id",
    },

    register: {
        url: "../sdsapi/portal/api/self/register",
    },

    selfCheck: {
        url: "../sdsapi/portal/api/self/register/check",
    },

    protocols: {
        url: "../sdsapi/portal/api/service/protocols/",
    },

    opts: {
        url: "../sdsapi/portal/api/service/opts/",
    },

    myService: {
        url: "../sdsapi/portal/api/service/specinst",
    },

    appeal: {
        url: "../sdsapi/portal/api/appeal",
    },

    appealId: {
        url: "../sdsapi/portal/api/appeal/:id",
    },

    appealTypes: {
        url: "../sdsapi/portal/api/appeal/types",
    },

    appealPriority: {
        url: "../sdsapi/portal/api/appeal/priority",
    },

    problem: {
        url: "../sdsapi/portal/api/problem/object",
    },

    consSpecInst: {
        url: "../sdsapi/portal/api/service/consumer/specinst",
    },

    getTask: {
        url: "../sdsapi/portal/api/order/:id",
    },

    getTaskActions: {
        url: "../sdsapi/portal/api/order/action/",
    },

    taskAction: {
        url: "../sdsapi/portal/api/order/action/",
    },

    problemId: {
        url: "../sdsapi/portal/api/problem",
    },

    // requestOperationId: {
    //     url: '/order/request/:id/operation/:operation_pk',
    //     successNotification: {
    //         put: ({ name }) => ['workTypes', name, 'workTypesAddedToRequest'],
    //         delete: () => ['workTypes', 'workTypesDeletedFromRequest']
    //     }
    // },

    signInst: {
        url: "../sdsapi/portal/api/sign/validate/inst/:id",
    },

    getInstYaml: {
        url: "../sdsapi/portal/api/doc/inst/:id",
    },

    signTask: {
        url: "../sdsapi/portal/api/sign/validate/task/:id",
    },

    getTaskYaml: {
        url: "../sdsapi/portal/api/doc/task/:id",
    },

    // UZ
    domainValidation: {
        url: "../sdsapi/portal/api/scheme/:domain/",
    },
    passwordValidation: {
        url: "../sdsapi/portal/api/login/passwd",
    },
    register: {
        url: "../sdsapi/portal/api/login/register",
    },
    sessionNext: {
        url: "../sdsapi/portal/api/session/next",
    },
    person: {
        url: "../sdsapi/portal/api/user/my/person",
    },
    personDynParams: {
        url: "../sdsapi/portal/api/user/my/person/params",
    },
    dyn_form_ajax: {
        url: "../sdsapi/portal/api/form/values/search",
    },
    updatePassword: {
        url: "../sdsapi/portal/api/password",
    },

    // AUTH
    auth: {
        url: "/../sdsapi/portal/api/auth",
    },
    notAuth: {
        url: "#/main",
    },
    logout: {
        url: "../sdsapi/portal/api/logout",
    },

    sessionState: {
        url: "../sdsapi/portal/api/session/type",
    },

    approveChannels: {
        url: "../sdsapi/portal/api/user/my/approve/channels",
    },

    sendApproveCode: {
        url: "../sdsapi/portal/api/user/my/approve/message",
    },

    checkApproveCode: {
        url: "../sdsapi/portal/api/user/my/approve/code",
    },

    approveMessage: {
        url: "../sdsapi/portal/api/user/my/approve/message",
    },

    linkedObject: {
        url: "../sdsapi/portal/api/user/my/linked/object",
    },

    sessionCommit: {
        url: "../sdsapi/portal/api/session/commit",
    },

    comments: {
        url: "../sdsapi/portal/api/comments/:objectType/:id",
    },

    appealFileUpload: {
        url: "../sdsapi/portal/api/appeal/file/:id",
    },

    appealFileDelete: {
        url: "../sdsapi/portal/api/appeal/file/:id/:fileId",
    },

    // ROUTES
    routes: {
        url: "../sdsapi/portal/api/menu/routes/:routeCode",
    },

    // MAIN
    mainNotifications: {
        url: "../sdsapi/portal/api/problem/customer",
    },

    // GRIDS
    searchAppeal: {
        url: "../sdsapi/portal/api/search/appeal",
    },

    translations: {
        url: "../sdsapi/portal/api/translations",
    },

    // STATIC BLOCKS
    staticBlock: {
        url: "../sdsapi/portal/api/:objectType/form/static/block",
    },

    save_file: {
        url: "../sdsapi/portal/api/file/upload",
    },
    
    delete_file: {
        url: "../sdsapi/portal/api/file/delete"
    }
};
