export default {
    ffm: 'ffm-logo',
    unknown: 'search',
    requests: 'orders',
    request: 'request',
    planning: 'planning',
    workers: 'workers',
    resources: 'workers',
    locations: 'location',
    map: 'map',
    reports: 'reports',
    public: 'object-group',
    settings: 'settings2',
    about: 'info-circle',
    status: 'dash',
    flags: {
        uk: 'flag-uk',
        en: 'flag-en',
        ru: 'flag-ru'
    },
    actions: {
        create: 'icon-notify-ok',
        modify: 'icon-modify',
        delete: 'icon-notify-remove',
        create_link: 'icon-notify-ok',
        modify_link: 'icon-link-change',
        delete_link: 'icon-link-remove'
    }
};
