import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ComboBox } from '../../core';

const IpList = ({ t, data, ipList, fieldIp = 'value', getIPs }) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    const renderBlock = val => {
        if (val.stateCode === 'deleted') {
            return null;
        }

        const handleClick = () => {
            if (val.stateCode === 'active') {
                const list = [...data];
                getIPs(list.filter(v => v.id !== val.id));
            }
        };

        return (
            <div className="row-buttom" key={val.id}>
                <div className="form-wrapper input-element">
                    <input
                        title={t(val.stateName)}
                        className="input-field-wrap input-field select__control"
                        value={val[fieldIp]}
                        disabled
                    />
                </div>
                <button type="button" className={`btn btn-delete-square ${val.stateCode}`} onClick={handleClick}>
                    {t(val.stateCode === 'active' ? 'Видалити' : 'В обробці...')}
                </button>
            </div>
        );
    };

    const handleAdd = () => {
        if (selected) {
            const list = [...data];
            list.unshift(ipList.find(v => v.id === selected.value));
            getIPs(list);
            setSelected(null);
        }
    };

    useEffect(() => {
        const ops = [];
        ipList.forEach(ip => {
            if (!data.find(v => v.id === ip.id)) {
                ops.push({ value: ip.id, label: ip.value });
            }
        });

        setOptions(ops);
    }, [data, ipList]);

    return (
        <div className="list-block">
            {t('IPList')}:
            <div className="row-buttom add">
                <div className="form-wrapper input-element">
                    <ComboBox
                        className="container-combo-box"
                        classNamePrefix="select"
                        key="ip-select"
                        name="ip-select"
                        options={options}
                        placeholder={t('typeNewIP')}
                        input={{ onChange: setSelected, value: data[0] }}
                    />
                </div>
                <button type="button" className="btn btn-save-square" disabled={!selected} onClick={handleAdd}>
                    {t('add')}
                </button>
            </div>
            {data.map(renderBlock)}
        </div>
    );
};

IpList.propTypes = {
    t: PropTypes.func,
    data: PropTypes.array,
    list: PropTypes.array,
    getIPs: PropTypes.func
};

export default IpList;
