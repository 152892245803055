import React from 'react';
import PropTypes from 'prop-types';

import FormElementWrapper from './FormElementWrapper';

const CheckBox = props => {
    const { label, input, meta, liveSaver, tooltip, ...rest } = props;

    const handleCheck = e => {
        input.onChange && input.onChange(e);
    };

    return (
        <FormElementWrapper {...rest} tooltip={tooltip} meta={meta} label={label} liveSaver={liveSaver}>
            <label className='container-checkbox'>
                <input
                    checked={Boolean(input ? input.value : props.value)}
                    {...input}
                    {...rest}
                    type='checkbox'
                    onChange={handleCheck}
                />
                <span className='checkmark' />
            </label>
        </FormElementWrapper>
    );
};

CheckBox.displayName = 'CheckBox';

CheckBox.propTypes = {
    label: PropTypes.any
};

CheckBox.defaultProps = {
    label: ''
};

export default CheckBox;
